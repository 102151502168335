import {action, observable} from "mobx"
import Location from "./model/Location"

export default class LocationStore {

  @observable locations: Array<Location>
  @observable locationById: Map<string, Location>
  @observable locationsByMeeting: Map<string, Array<Location>>
  @observable locationsByPerson: Map<string, Array<Location>>

  store: any

  constructor(store){
    this.store = store
    this.locations = []
    this.locationById = new Map()
    this.locationsByMeeting = new Map()
    this.locationsByPerson = new Map()
    this.load()
  }

  @action
  load() {
    this.addLocation(new Location({
      id: "location-0",
      name: "Simplias GmbH",
      street: "Lütznerstraße 11",
      postcode: "24582",
      city: "Leipzig",
      country: "DE",
      usage: 10,
      person: "Jan Höppner",
      meetings: ["Designmeeting", "Vertriebsgespräch (Kickoff)"],
      isFavorite: true
    }))
    this.addLocation(new Location({
      id: "location-1",
      name: "Fritz Ritter GmbH",
      street: "Rababarastr. 11",
      postcode: "24582",
      city: "Leipzig",
      country: "DE",
      usage: 3,
      person: "Gudrun Lauf",
      meetings: ["Geschäftsessen", "Vertriebsgespräch (Kickoff)", "Fortbildung"],
      isFavorite: false
    }))
  }

  addLocation(location: Location) {
    this.locations.push(location)
    this.locationById.set(location.id, location)
    if (location.meetings) {
      this.addMeetingLocation(location)
    }
    if (location.person) {
      this.addMeetingPerson(location)
    }
    // console.log(`added location ${location.id}`)
  }

  addMeetingLocation(location) {
    location.meetings.forEach(meeting => {
      let group = this.locationsByMeeting.get(meeting) || []
      group.push(location)
      this.locationsByMeeting.set(meeting, group)
    })
  }

  addMeetingPerson(location) {
      let group = this.locationsByMeeting.get(location.person) || []
      group.push(location)
      this.locationsByPerson.set(location.person, group)
  }

}
