import * as React from "react"
import { Button } from "semantic-ui-react"
import moment from "moment"
import "moment/locale/de"
import { Link, withRouter } from "react-router-dom"
import { SingleDatePicker, DateRangePicker } from "react-dates"
import "./TimeNavigation.css"
import * as queryString from "querystring"
import parseQuery from "util/parseQuery"
import Icon from "component/Icon"
import { faChevronLeft, faChevronRight, faTimes } from "@fortawesome/pro-light-svg-icons"

moment.locale( "de" )


const currentYear = moment.utc().year()
const getMonthByIndex = ( day = 0, month = 0, year = currentYear ) => moment.utc().day( day ).year( year ).month( month ).startOf( "month" ).format( "MM" )

const getMonthList = ( year ) => {
	let monthOptions: any = []
	let countMonths = 12
	const today = moment.utc().endOf( "month" )
	while ( countMonths-- ) {
		const d = moment.utc().month( countMonths ).year( year )
		if ( d.isBefore( today ) ) {
			monthOptions.push(
				{
					text : d.format( "MMMM" ),
					value: d.month(),
				}
			)
		}
	}
	return monthOptions.reverse()
}


// @ts-ignore
@withRouter
class TimeNavigation extends React.Component<any, any> {

	static defaultProps = {
		showDay  : true,
		showWeek : true,
		showMonth: true,
		showYear : false,
	}

	state = {
		timeFrame   : "month",
		year        : `${moment().year()}`,
		month       : moment().month(),
		scale       : this.props.scale || "day",
		startDate   : this.props.match.params.day ? moment( this.props.match.params.day, "YYYY-MM-DD" ) : moment(),
		endDate     : this.props.endDate ? moment( this.props.endDate, "YYYY-MM-DD" ) : moment().add( 0, "day" ),
		focusedInput: null,
		focused     : false
	}


	selectYear = ( event, {value} ) => {
		// console.log( "SET YEAR", value )
		const startDate = moment().year( value ).startOf( "year" )
		this.setState( {startDate}, this.triggerChange )
	}

	selectMonth = ( event, {value} ) => {
		// console.log( "SET MONTH", value )
		const startDate = moment().month( value ).startOf( "month" )
		this.setState( {startDate}, this.triggerChange )
	}

	selectWeek = ( event, {value} ) => {
		// console.log( "SET WEEK", value )
		const startDate = moment().week( value ).startOf( "week" )
		this.setState( {startDate}, this.triggerChange )
	}

	triggerChange = () => {
		let query = parseQuery( this.props )
		const {scale, startDate, endDate} = this.state
		// console.log( {scale, startDate, endDate} )
		const {history} = this.props
		this.props.onChange && this.props.onChange( {startDate, endDate, scale, history} )

		history.replace( {
			pathname: this.props.location.pathname,
			search  : `${queryString.stringify( {
				...query,
				scale,
				startDate: moment( startDate ).format( "YYYY-MM-DD" ),
				endDate  : moment( endDate ).format( "YYYY-MM-DD" )
			} )}`
		} )

	}

	componentDidMount() {
		//this.onChange()
	}

	setDate = ( {startDate} ) => {
		const {scale} = this.state
		const endDate = this.makeEndDate( startDate, scale )
		this.setState( {startDate, endDate}, this.triggerChange )
	}

	setScale = ( scale ) => {
		const {startDate} = this.state
		switch ( scale ) {
			case "week":
				startDate.startOf( "week" )
				break;
			case "month":
				startDate.startOf( "month" )
				break;
			case "year":
				startDate.startOf( "year" )
				break;
		}

		const endDate = this.makeEndDate( startDate, scale )
		this.setState( {startDate, endDate, scale}, this.triggerChange )
	}

	makeEndDate( startDate, scale = this.state.scale ) {
		const endDate = moment( startDate ).add( 1, getUnitFromString( scale ) )
		if ( scale === "week" || scale === "month" ) {
			endDate.subtract( 1, "day" )
		}
		if ( scale === "year" ) {
			return moment( startDate ).endOf( "year" )
		}
		return endDate
	}

	// today = () => {
	//     const {scale} = this.state
	//     const startDate = moment()
	//     const endDate = this.makeEndDate(startDate)
	//     this.setState({startDate, endDate}, this.triggerChange)
	//
	// }
	//
	// prev = () => {
	//     const {startDate, scale} = this.state
	//     startDate.subtract(1, getUnitFromString(scale))
	//     const endDate = this.makeEndDate(startDate)
	//     this.setState({startDate, endDate}, this.triggerChange)
	//
	// }
	//
	// next = () => {
	//     const {startDate, scale} = this.props
	//     startDate.add(1, getUnitFromString(scale))
	//     const endDate = this.makeEndDate(startDate)
	//     this.setState({startDate, endDate}, this.triggerChange)
	// }

	render() {

		const {focused} = this.state
		const {maxDate = moment()} = this.props
		let query = parseQuery( this.props )
		const {startDate = moment().format("YYYY-MM-DD"), scale} = query
		let DateRepresentation = <span>{moment( startDate, "YYYY-MM-DD" ).format( "DD. MMM YYYY" )} </span>
		if ( scale === "year" ) {
			DateRepresentation = <span>{moment( startDate, "YYYY-MM-DD" ).format( "DD. MMM YYYY" )} </span>
		}
		const nextDay = moment().startOf( "day" )

		let canNext = true
		if ( this.props.maxDate ) {
			canNext = nextDay.isBefore( maxDate )
		}

		const isDayBlocked = ( date ) => {
			return nextDay.isBefore( date )
		}

		const nextDaySearch = `${queryString.stringify( {
			...query,
			startDate: moment( startDate ).add( 1, "day" ).format( "YYYY-MM-DD" )
		} )}`
		const prevDaySearch = `${queryString.stringify( {
			...query,
			startDate: moment( startDate ).subtract( 1, "day" ).format( "YYYY-MM-DD" )
		} )}`

		return (
			<div className={"TimeNavigation no-print z-9"}>
				<div className="time-nav ">

					<Button.Group
						basic
						size={"tiny"}
						className="group-time-navigation">
						{!focused && <Button icon={<Icon icon={faChevronLeft} />} disabled={!canNext} as={Link} to={{
							pathname: this.props.location.pathname,
							search: prevDaySearch
						}} />
						}
						{!focused && <Button
							onClick={() => this.setState({ focused: !focused })} // PropTypes.func.isRequired
						>
							{startDate && DateRepresentation}
						</Button>}
						{focused && <div>
							<SingleDatePicker
								isDayBlocked={isDayBlocked}
								numberOfMonths={1}
								hideKeyboardShortcutsPanel
								noBorder={true}
								date={moment( startDate, "YYYY-MM-DD" )} // momentPropTypes.momentObj or null
								onDateChange={startDate => this.setDate( {startDate} )} // PropTypes.func.isRequired
								focused={focused} // PropTypes.bool
								onFocusChange={( {focused} ) => this.setState( {focused} )} // PropTypes.func.isRequired
								id="your_unique_id" // PropTypes.string.isRequired,
								isOutsideRange={() => false}
								renderDayContents={( day ) => {
									return (<React.Fragment> <span
										className="CalendarDayWeekNumber">{day.format( 'W' )}</span> {day.format( 'D' )}
									</React.Fragment>)
								}}
							/>

						</div>}

						{!focused && <Button icon={<Icon icon={faChevronRight}/>} disabled={!canNext} as={Link} to={{
							pathname: this.props.location.pathname,
							search  : nextDaySearch
						}} />
						}

						{focused && <Button
							icon={<Icon icon={faTimes}/>}
							onClick={() => this.setState( {focused: !focused} )} // PropTypes.func.isRequired
						/>}
					</Button.Group>

				</div>
			</div>
		)
	}
}
export default TimeNavigation


const getUnitFromString = ( value ) => {
	let unit: moment.unitOfTime.DurationConstructor
	// TypeScript. Ha. :D
	switch ( value ) {
		case "day":
			unit = "day"
			break;
		case "week":
			unit = "week"
			break;
		case "month":
			unit = "month"
			break;
		case "year":
			unit = "year"
			break;
		default:
			unit = "day"
	}
	return unit
}