import * as React from "react"
import "./Icon.css"
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faShoppingCart} from 'assets/fortawesome-pro-light-svg-icons-5.14.0/package'
import {faChevronCircleLeft, faChevronCircleRight, faCube, faInfoCircle, faMapMarkedAlt, faStroopwafel, faTruck} from '@fortawesome/free-solid-svg-icons'

library.add( {
  faShoppingCart,
  faTruck,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCube,
  faInfoCircle,
  faStroopwafel,
  faMapMarkedAlt,
} )

const Icon = ( props ) => {
  if ( !props.icon ) return null
  return <FontAwesomeIcon  {...props} />
}

export default Icon