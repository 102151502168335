import * as React from "react"
import {Button, Form, TextArea} from "semantic-ui-react"
import store from "../store/store";
import {observer} from "mobx-react";
import parseQuery from "util/parseQuery"
import {toast} from "react-toastify"
import moment from "moment"
import "./NotesPage.css"
import {PageHeaderSimple} from "component/PageHeader"
import {SidebarContext} from "component/Vehicle/VehicleSidebar"
import Icon from "component/Icon"
import {faTimes} from "@fortawesome/free-solid-svg-icons"

@observer
class Notes extends React.Component<any, any> {

	load = ( props = this.props ) => {
		const {vehicle} = props
		if ( !vehicle ) return
		if ( vehicle ) {
			this.setState( {loading: true} )
			vehicle.fetchNotes()
				.then( notes => this.setState( {loading: false} ) )
				.catch( err => {
					this.setState( {loading: false} )
					toast.error( err )
				} )
		}
	}

	componentDidUpdate( prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any ) {
		if ( this.props.vehicle !== prevProps.vehicle ) {
			this.load()
		}
	}

	render() {
		const {vehicle} = this.props
		if ( !vehicle ) return null
		const {notes} = vehicle
		return <div className="pt3">
			{notes.map( note => <Note key={note.noteId} note={note} vehicle={vehicle} /> )}
			<AddNote onSuccess={this.props.onUpdate} vehicle={vehicle} />
		</div>
	}
}


class AddNote extends React.Component<any, any> {

	state = {
		loading: false
	}

	handleKeyPress = ( e ) => {
		if ( e.key === 'Enter' && e.shiftKey ) {
			this.handleSubmit()
			e.preventDefault()
			return false
		}
		return true
	}

	handleSubmit = () => {
		const {vehicle} = this.props
		// console.log( "SUBMIT", {vehicle} )
		let $textArea: any
		$textArea = document.querySelector( "textarea#note" )
		if ( $textArea && vehicle ) {
			const content = $textArea.value
			// console.log( {content} )
			vehicle.postNote( content )
				.then( res => {
					this.props.onSuccess()
					$textArea.value = ""
				} )
		} else {
			console.error( "Device or Textarea not available", {vehicle, $textArea} )
		}
	}

	render() {
		if ( !store.account.user ) return null

		return <div className="Note pb3 bg-white">
			<div className="note-header pa3 bg-silver">
				<span className="f5 ttu">{store.account.user.name} </span>
			</div>

			<div className="note-content pa3">
				<Form className="overflow-hidden" onSubmit={this.handleSubmit}>
					<TextArea rows={5} id={"note"} placeholder={"Schreibe eine Notiz."}
							  onKeyDown={this.handleKeyPress} />
					<div className="pt2">
						<div className="fl f6 black-40">SHIFT + ENTER zum Senden.</div>
						<Button type="submit" primary floated={"right"}>Notiz hinzufügen</Button>
					</div>
				</Form>
			</div>
		</div>
	}
}

class Note extends React.Component<any, any> {

	state = {
		loading: false,
		removed: false
	}

	handleDelete = () => {
		this.setState( {loading: true, removed: true} )
		const {vehicle, note} = this.props
		vehicle.deleteNote( note.noteId )
			.then( () => {
				this.setState( {loading: false} )
			} )
	}

	undoDelete = () => {
		const {vehicle, note} = this.props

		this.setState( {
			removed: false,
			loading: true
		} )

		vehicle.putNote( {
				noteId : note.noteId,
				removed: false
			} )
			.then( res => {
				this.setState( {loading: false} )
			} )
	}

	render() {
		const {loading, removed} = this.state
		const {note} = this.props
		const {userName, content, created} = note
		const date = moment( created )
		return <div className="Note  bg-white">
			<div className="note-header bg-silver">
				<div>
					{removed &&
					<div className={"bg-white bb b--black-10 flex flex-row justify-between w-100 items-center pv2 ph3 "}>
						<div>
							<span className="black-40 animate fadeIn ">Notiz entfernt</span>
						</div>
						<Button basic onClick={this.undoDelete}>
							<span className="animate fadeInLeft"> Wiederherstellen?</span>
						</Button>
					</div>
					}
					{!removed && <div className={"bg-white bb b--black-10 flex flex-row justify-between w-100 items-center pv2 ph3 "}>
						<div>
							<span className="f5 black-70 ttu">{userName}&nbsp;</span>
							<span className="f6 black-40"
								  title={date.format( "HH:mm [Uhr], DD.MMM YYYY" )}>{date.format( "DD. MMM " )}</span>
						</div>
						<Button basic onClick={this.handleDelete} className="mr0" position="right" icon={<Icon icon={faTimes}/>} />
					</div>
					}
				</div>
			</div>
			{!removed && <div className="note-content pa3">
				{content}
			</div>
			}
		</div>
	}
}


@observer class NotesPage extends React.Component<any, any> {

	fetching = false

	state = {
		loading: false
	}

	load = ( props = this.props ) => {
		if ( this.fetching ) return
		this.fetching = true
		const {vehicleId} = parseQuery( props )
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		if ( !vehicle ) return
		if ( vehicle ) {
			this.setState( {loading: true} )
			vehicle.fetchNotes()
				.then( notes => {
					this.setState( {loading: false} )
					this.fetching = false
				} )
				.catch( err => {
					this.setState( {loading: false} )
					toast.error( err )
					this.fetching = false
				} )
		}
	}

	componentWillReceiveProps( props ) {
		this.load( props )
	}

	componentDidMount() {
	}

	render() {
		const {vehicleId} = parseQuery( this.props )
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		if ( !vehicle ) return <div>kein Fahrzeug</div>
		return (
			<div className="NotesPage Page w-100 ">
				<PageHeaderSimple title={`Notizen – ${vehicle.vehiclePlate}`} />
				<SidebarContext optional />

				<div className="pb5 pa4 mw8 center ">
					<Notes vehicle={vehicle} onUpdate={this.load} />
				</div>

			</div>
		)
	}
}

export default NotesPage
