import * as React from "react"
import {Container, Menu, Image, Popup} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCars, faFile, faMap, faUsers} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package";

import "./SmallSidebarLayout.css"
import {Link} from "react-router-dom";

export default class SmallSidebarLayout extends React.Component {

    render() {
        const {children} = this.props
        return <div className="SmallSidebarLayout ContentPage">

            <div className="sidebar-container">
                <DomainSidebar/>
                <VehicleSelectionMenu/>
            </div>

            {/*TODO: Container in Child elements, Mobile Layout (DomainSidebar as BurgerMenu offscreen*/}
            <Container>
                {children}
            </Container>
        </div>
    }
}


class VehicleSelectionMenu extends React.Component {

    render() {
        return <div className="VehicleSelectionMenu">
            {/*SELECTION :)*/}
        </div>
    }
}


const DomainSidebar = () => {
    return <div className="DomainSidebar no-print">
        <div className="bg-dark">
            <Menu vertical fluid inverted className={"tc"}>
                <Menu.Item as={PopupLink} to={"/map/"} title={"Home"}
                           icon={<Image src={"/brand/flottr-logo-softmango.png"}/>}/>
                <Menu.Item as={PopupLink} to={"/map/"} title={"Karte"} icon={<FontAwesomeIcon icon={faMap}/>}/>
                <Menu.Item as={PopupLink} to={"/vehicles/"} title={"Fahrzeuge"}
                           icon={<FontAwesomeIcon icon={faCars}/>}/>
                <Menu.Item as={PopupLink} to={"/users/"} title={"Fahrer"} icon={<FontAwesomeIcon icon={faUsers}/>}/>
                {/*<Menu.Item as={PopupLink} to={"/export/"} title={"Berichte & Downloads"}*/}
                {/*           icon={<FontAwesomeIcon icon={faFile}/>}/>*/}
            </Menu>
        </div>
    </div>
}


const PopupLink = ({children, title, to}) => (
    <Popup content={title} position="right center" trigger={<Link className={"item icon"} to={to}>{children}</Link>}/>
)
