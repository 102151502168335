import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown"
import { Link } from "react-router-dom"
import * as queryString from "querystring"
import Icon from "component/Icon"
import * as React from "react"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

export default function DropdownYear({ query }) {
	const currentYear = moment().year()
	const lastYears = [ 3, 2, 1, 0 ].map(sub=>{
		return currentYear - sub
	})

	return <Dropdown size={ "small" } text={ query.year } floating>

		<Dropdown.Menu className="left">
			{ lastYears.map(year=>{
					return <Dropdown.Item key={ year } as={ Link } to={ { search: queryString.stringify({ ...query, year }) } }>
						<Icon icon={ faChevronDown } />
						<span className="text">{ year }</span>
					</Dropdown.Item>
				}
			) }

		</Dropdown.Menu>
	</Dropdown>
}