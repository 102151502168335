import {observable, action} from "mobx"
import http from "service/http"
import Trip from "store/Trip"

class Bag {
    @observable all: Array<Trip>
    @observable byVehicle: Map<string, TripStore>
    @observable byDay: Map<string, Array<Trip>>
    @observable byId: Map<string, Trip>
    @observable byDayHash: object
    @observable sortedDays: Array<any>
    @observable openTrips: Array<Trip>
    @observable loading: boolean

    constructor() {
        this.byId = new Map()
        this.byDay = new Map()
        this.all = []
    }



    @action
    tripSetup(all, byId) {
        all.forEach(trip => {
            trip.setup(byId)
        })
        return all
    }

    @action
    sortByDays() {
        let days = [...this.byDay.entries()]
        days = days.sort((a, b) => {
            let dateA = a[0]
            let dateB = b[0]
            if (dateA < dateB) {
                return 1
            }
            else {
                return -1
            }
        })
        this.sortedDays = days
        return this.sortedDays
    }

    @action
    get({vehicleId, start = "2018-01-01", end = "2019-01-01"}) {

        this.loading = true
        this.all = []
        this.byDay = new Map()
        this.byId = new Map()
        this.byDayHash = {}

        // TODO: SET TRIP STARTING KM
        let km = 0

        console.info("fetching", {vehicleId, start, end})
        return http.get(`/api/vehicle/${vehicleId}/trips`, {start, end})
            .then(response => response.data.trips)
            .then(data => {

                let all = data
                    .map(data => {
                        return new Trip(data)
                    })
                let byDay = new Map()
                let byId = new Map()

                let driving: Array<Trip> = []

                all.forEach(trip => {
                    byId.set(trip.id, trip)
                    trip.km = km
                    km = km + trip.distance
                    if (trip.type === "eDriving") {
                        driving.push(trip)
                        let group = this.byDay.get(trip.day) || []
                        group.push(trip)
                        byDay.set(trip.day, group)
                    }
                })

                this.loading = false
                let trips = this.tripSetup(all, byId)
                trips.forEach(trip => {
                    this.byId.set(trip.id, trip)
                })
                this.byDay = byDay
                this.byId = byId
                this.all = all

                this.openTrips = this.all.filter(trip => {
                    return !trip.trip_type && trip.distance > 0.5
                })

                const sortedDays = this.sortByDays()

                return  {
                    openTrips: this.openTrips,
                    trips,
                    byDay,
                    byId,
                    all,
                    driving,
                    sortedDays
                }
            })
    }

}

export default class TripStore {

    @observable byVehicle: Map<string, Bag>
    @observable loading: boolean

    store: any

    constructor(store) {
        this.store = store
        this.reset()
    }

    @action
    async findByVehicleId ({vehicleId, start = "2018-01-01", end = "2019-01-01"}) {
        let bag = this.byVehicle.get(vehicleId) || new Bag()
        await bag.get({vehicleId, start, end})
        this.byVehicle.set(vehicleId, bag)
    }

    @action
    reset() {

        this.loading = false
    }


}
