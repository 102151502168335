import * as React from "react"
import { Button, Card, Checkbox, Container, Divider, Dropdown, Form, Grid, Table } from "semantic-ui-react"
import http from "../../service/http"
import { toast } from "react-toastify"
import { Redirect, Route, Switch } from "react-router"
import { observer } from "mobx-react"

import CheckoutStore from "store/CheckoutStore"
import "../BuyPage.css"
import FooterLegal from "../../component/Footer/FooterLegal"
import store from "store/store"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Icon from "component/Icon"
import { Link, useHistory } from "react-router-dom"
import OrderPage from "../OrderPage"

const checkoutStore = new CheckoutStore()
const countOptions: Array<any> = []

let n = 0

while (n++ < 101) {
    countOptions.push({key: n, value: n, text: n})
}

@observer
class BuyForm extends React.Component<any, any> {

    state = {
        showWarnings: false,
        termsOk: false,
        success: false,
        useBillingAddress: true,
        useDeliveryAddress: true,
        order: {
            orderId: false
        },
        shipping: {},
        customer: {},
        billing: {},
        data: {
            email: checkoutStore.email,
            count: 1
        }
    }

    componentDidMount() {
        checkoutStore.fixPricePerUnit = 0
        checkoutStore.products.singleBook.count = 1
        checkoutStore.products.easyDrive.count = 0
        checkoutStore.email =  store.account.user?.email
        checkoutStore.calculate()
    }

    handleChange = (key, value) => {
        let data = this.state.data
        data[key] = value
        this.setState({data})
    }

    handleDataChange = (key, value) => {
        let customer = this.state.customer
        customer[key] = value
        this.setState({customer})
    }

    handleShippingChange = (key, value) => {
        let shipping = this.state.shipping
        shipping[key] = value
        this.setState({shipping})
    }

    handleInvoiceChange = (key, value) => {
        let billing = this.state.billing
        billing[key] = value
        this.setState({billing})
    }

    toggleBillingAddress = () => {
        this.setState({useBillingAddress: !this.state.useBillingAddress})
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({loading: true, showWarnings: true})
        const {termsOk, useBillingAddress, customer, billing, shipping, data} = this.state
        if (!termsOk) return toast.warn("Bitte bestätigen sie unsere AGB und Datenschutzbestimmungen")

        const body = {
            email: data.email || checkoutStore.email,
            products: {
                easyDrive: 0,
                book: checkoutStore.products.book.count,
                map: checkoutStore.products.map.count,
                singleBook: checkoutStore.products.singleBook.count,
            },
            termsOk,
            customer,
            billing,
            shipping: useBillingAddress ? billing : shipping
        }

        return http.post("/api/order", body)
            .then(res => {
                return res.data
            })
            .then(res => {
                this.setState({order: res.order, success: true})
                // this.props.onClose()
            })
            .catch(err => {
                console.error(err)
            })
    }

    render() {

        const {order, success, termsOk, useBillingAddress, useDeliveryAddress, billing, shipping, data} = this.state
        if (!order) return
        if (success) return <Redirect to={`/fahrtenbuch/success/${order.orderId}`}/>
        return (
            <Container className="">
                <Form onSubmit={this.handleSubmit}>

                    <Grid columns={2} doubling>
                        <Grid.Column>
                            <div className="pt4">
                                <Card style={{width: "100%"}}>
                                    <Card.Content>
                                        <h3 className={"fw2"}>E-Mail</h3>

                                        <FormFieldStore setValue={this.handleChange} label={"E-Mail"} name={"email"}
                                                        defaultValue={checkoutStore.email }
                                                        placeholder={"E-Mail"} required/>
                                    </Card.Content>
                                </Card>

                                {/*STAMMDATEN*/}
                                <Card style={{width: "100%"}}>
                                    <Card.Content>
                                        <h3 className={"fw2 "}>Stammdaten</h3>

                                        <FormFieldStore setValue={this.handleDataChange} label={"Name"} name={"name"}
                                                        placeholder={"Name"}
                                                        required/>

                                        <FormFieldStore setValue={this.handleDataChange}
                                                        label={"Firma"} name={"company"}
                                                        placeholder={"Firma"} required/>

                                        {/*<Form.Field>*/}
                                        {/*    <label htmlFor="vatId">UST-ID*</label>*/}
                                        {/*    <input id="vatId" required name="vatId" placeholder='UST-ID'/>*/}
                                        {/*</Form.Field>*/}

                                        <Divider/>

                                        <FormFieldStore setValue={this.handleDataChange}
                                                        autoComplete={"billing street-address"}
                                                        label={"Straße"} name={"street"}
                                                        placeholder={"Straße"} required/>

                                        {/*<FormFieldStore setValue={this.handleDataChange} label={"Adresszusatz"}*/}
                                        {/*                name={"addition"}*/}
                                        {/*                placeholder={"Adresszusatz"}/>*/}


                                        <Form.Group widths='equal'>

                                            <FormFieldStore
                                                autoComplete={"billing postal-code"}
                                                setValue={this.handleDataChange} label={"PLZ"} name={"postalCode"}
                                                placeholder={"PLZ"} required/>

                                            <FormFieldStore
                                                autoComplete={"billing locality"}
                                                setValue={this.handleDataChange} label={"Ort"} name={"city"}
                                                placeholder={"Ort"} required/>

                                        </Form.Group>

                                        <Form.Field>
                                            <input readOnly id="country" name="country" value={"Deutschland"}/>
                                        </Form.Field>

                                        <FormFieldStore setValue={this.handleDataChange} label={"Telefon"} name={"phone"}
                                                        placeholder={"Telefon"} required/>

                                    </Card.Content>
                                </Card>


                                {/*RECHNUNGSADRESSE*/}
                                <Card style={{width: "100%"}}>
                                    <Card.Content>
                                        <h3 className={"fw2 "}>Rechnungsadresse</h3>

                                        <div className="pb2">
                                            <Checkbox onChange={this.toggleBillingAddress}
                                                      checked={useBillingAddress}
                                                      label={"wie Stammdaten"}/>
                                        </div>

                                        {!useBillingAddress &&
                                        <React.Fragment>

                                          <FormFieldStore setValue={this.handleInvoiceChange} label={"Name"} name={"name"}
                                                          placeholder={"Name"} required/>

                                          <FormFieldStore setValue={this.handleInvoiceChange} label={"Firma"}
                                                          name={"company"}
                                                          placeholder={"Firma"} required/>

                                            {/*<Form.Field>*/}
                                            {/*    <label htmlFor="vatId">UST-ID*</label>*/}
                                            {/*    <input id="vatId" required name="vatId" placeholder='UST-ID'/>*/}
                                            {/*</Form.Field>*/}

                                          <Divider/>

                                          <FormFieldStore setValue={this.handleInvoiceChange}
                                                          autoComplete={"billing street-address"}
                                                          label={"Straße"} name={"street"}
                                                          placeholder={"Straße"} required/>

                                          <FormFieldStore setValue={this.handleInvoiceChange} label={"Adresszusatz"}
                                                          name={"addition"}
                                                          placeholder={"Adresszusatz"}/>

                                          <Form.Group widths='equal'>

                                            <FormFieldStore
                                              autoComplete={"billing postal-code"}
                                              setValue={this.handleInvoiceChange} label={"PLZ"} name={"postalCode"}
                                              placeholder={"PLZ"} required/>

                                            <FormFieldStore
                                              autoComplete={"billing locality"}
                                              setValue={this.handleInvoiceChange} label={"Ort"} name={"city"}
                                              placeholder={"Ort"} required/>

                                          </Form.Group>

                                          <Form.Field>
                                            <input readOnly id="country" name="country" value={"Deutschland"}/>
                                          </Form.Field>


                                          <FormFieldStore setValue={this.handleInvoiceChange} label={"Telefon"}
                                                          name={"phone"}
                                                          placeholder={"Telefon"} required/>
                                        </React.Fragment>

                                        }

                                    </Card.Content>
                                </Card>

                                {/*LIEFERDATEN*/}
                                <Card style={{width: "100%"}}>
                                    <Card.Content>
                                        <h3 className={"fw2 "}>Lieferdaten</h3>

                                        <div className="pb2">
                                            <Checkbox onChange={() => {
                                                this.setState({useDeliveryAddress: !useDeliveryAddress})
                                            }} checked={useDeliveryAddress}
                                                      label={"wie Stammdaten"}/>
                                        </div>

                                        {!useDeliveryAddress &&
                                        <React.Fragment>

                                          <FormFieldStore setValue={this.handleShippingChange} label={"Name"} name={"name"}
                                                          placeholder={"Name"} required/>

                                          <FormFieldStore setValue={this.handleShippingChange} label={"Firma"}
                                                          name={"company"}
                                                          placeholder={"Firma"} required/>

                                            {/*<Form.Field>*/}
                                            {/*    <label htmlFor="vatId">UST-ID*</label>*/}
                                            {/*    <input id="vatId" required name="vatId" placeholder='UST-ID'/>*/}
                                            {/*</Form.Field>*/}

                                          <Divider/>

                                          <FormFieldStore setValue={this.handleShippingChange}
                                                          autoComplete={"billing street-address"}
                                                          label={"Straße"} name={"street"}
                                                          placeholder={"Straße"} required/>

                                          <FormFieldStore setValue={this.handleShippingChange} label={"Adresszusatz"}
                                                          name={"addition"}
                                                          placeholder={"Adresszusatz"}/>

                                          <Form.Group widths='equal'>

                                            <FormFieldStore
                                              autoComplete={"billing postal-code"}
                                              setValue={this.handleShippingChange} label={"PLZ"} name={"postalCode"}
                                              placeholder={"PLZ"} required/>

                                            <FormFieldStore
                                              autoComplete={"billing locality"}
                                              setValue={this.handleShippingChange} label={"Ort"} name={"city"}
                                              placeholder={"Ort"} required/>

                                          </Form.Group>

                                          <Form.Field>
                                            <input readOnly id="country" name="country" value={"Deutschland"}/>
                                          </Form.Field>

                                          <FormFieldStore setValue={this.handleShippingChange} label={"Telefon"}
                                                          name={"phone"}
                                                          placeholder={"Telefon"} required/>
                                        </React.Fragment>

                                        }

                                    </Card.Content>
                                </Card>


                                <div className={"black-70 f6"}>Hilfe benötigt? <a target={"_blank"}
                                                                                  href="mailto:info@flottr.com"><span
                                    className={"black"}>Kontaktieren Sie uns</span></a></div>

                            </div>
                            {/*<div className="pv4"><Image width={150} src={"/brand/flottr-brand-dark@600w.png"}/></div>*/}


                        </Grid.Column>
                        <Grid.Column className={"sidebar"}>


                            <div className="ph4-ns ph0-m pt4">
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Artikel</Table.HeaderCell>
                                            <Table.HeaderCell>Menge</Table.HeaderCell>
                                            <Table.HeaderCell>Einzelpreis</Table.HeaderCell>
                                            <Table.HeaderCell>Zwischensumme</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className={"f5 b"}>flottr Fahrtenbuch Jahreslizenz</div>
                                                <div className={"f6 black-70"}></div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div style={{width: "75px"}}>
                                                    <Dropdown size={"tiny"} value={checkoutStore.products.singleBook.count} fluid
                                                              onChange={(e, data) => {
                                                                  const count = Number(data.value)
                                                                  // console.log({data})
                                                                  this.handleChange("count", data.value)

                                                                  if (count <= checkoutStore.products.map.count) {
                                                                      checkoutStore.products.map.count = count
                                                                  }

                                                                  if (count <= checkoutStore.products.book.count) {
                                                                      checkoutStore.products.book.count = count
                                                                  }

                                                                  checkoutStore.products.singleBook.count = count
                                                                  checkoutStore.calculate()

                                                              }} selection options={countOptions}/>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.singleBook.price * 12)}</div>
                                                <div className="tr f6 black-70">12 x {currency(checkoutStore.products.singleBook.price, 2)}</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.singleBook.price * checkoutStore.products.singleBook.count * 12)}</div>
                                            </Table.Cell>
                                        </Table.Row>


                                    </Table.Body>
                                </Table>

                                <Table>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 black-70 pb2">Versandkosten</div>
                                                <div className="f5 black-70 pb2">Zwischensumme</div>
                                                <div className="f5 black-70 pb2">Hardware</div>
                                            </Table.Cell>

                                            <Table.Cell>
                                                <div className="black-70 tr pb2">kostenlos</div>
                                                <div className="b tr pb2">{currency(checkoutStore.sumPerYear)}</div>
                                                <div className="black-70 tr pb2">{currency(checkoutStore.setupPrice, 2, true)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 black-70 ">zzgl. USt. (19%)</div>

                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="black-70 tr ">{currency(checkoutStore.sumTax)}</div>

                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 b">Summe</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="f4 b tr">{currency(checkoutStore.total)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                        {/*<Table.Row>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        <div className="f5 black-70">Im Folgejahr (Netto)</div>*/}
                                        {/*    </Table.Cell>*/}
                                        {/*    <Table.Cell>*/}
                                        {/*        <div className="f5 black-70 tr">{currency(checkoutStore.sumPerYear)}</div>*/}
                                        {/*    </Table.Cell>*/}
                                        {/*</Table.Row>*/}


                                    </Table.Body>
                                </Table>


                                <div className={"pv3 pb4"}>
                                    <div className="fl pr2"><Checkbox onChange={e => {
                                        this.setState({termsOk: !this.state.termsOk})
                                    }}/></div>
                                    <div className={"f6 black-70"}>Ich stimme den <a target={"_blank"}
                                                                                     href="https://flottr.com/agb.html">AGB</a> der
                                        Simplias GmbH zu. Die <a
                                            target={"_blank"}
                                            href="https://flottr.com/datenschutz">Datenschutzerklärung</a> habe ich
                                        gelesen
                                    </div>
                                </div>

                                <Button size={"large"} color={"yellow"} fluid>Jetzt
                                    Bestellen</Button>
                            </div>

                        </Grid.Column>
                    </Grid>
                </Form>

                <FooterLegal/>

            </Container>
        )
    }
}

const currency = (value, n = 2, allowFree = false) => {
    if (allowFree && value === 0) return "kostenlos"
    return `${Number(value).toFixed(n).replace(".", ",")} €`
}

export default class FirstWelcomePage extends React.Component<any, any> {


    state = {
        success: false,
        loading: false,
    }


    render() {
        const {success} = this.state
        if (success) return <Redirect to={"/map/"}/>
        return (
            <div className="FirstWelcomePage bg-silver min-vh-100 pb6 pb5-m pb4-ns">

                <Switch>
                    <Route exact path={"/fahrtenbuch"} component={Step1}/>
                    <Route exact path={"/fahrtenbuch/checkout"} component={StepCheckout}/>
                    <Route path={"/fahrtenbuch/success/:orderId"} component={StepSuccess}/>
                </Switch>

                <FooterLegal/>
            </div>
        )
    }
}


const Step1 = observer(() => {
    const {email} = checkoutStore
    const history = useHistory()
    return <Container className="pt3">
        <div className="flex flex-row items-center justify-around">
            <div className="flex flex-row">
                <GuideStep title={"Anmeldung"} number={"1"} state={"active"}/>
                <GuideDivider/>
                <GuideStep title={"Persönliche Daten"} number={"2"} state={"open"}/>
                <GuideDivider/>
                <GuideStep title={"Bestätigung"} number={"3"} state={"open"}/>
            </div>

        </div>


        <div className="tc pt5 lh-title">
            <div className="b f4 pb2 ttu">1. Anmeldung</div>
            <div className="primary f2">Ihre <b> E-Mail Adresse</b> eingeben</div>

            <div className="pt4 f4 mw7 center lh-copy">
                Bitte erstellen Sie im ersten Schritt Ihr Flottr-Konto mit einer gültigen E-Mail Adresse oder loggen Sie sich mit Ihrer existierenden Flottr E-Mail Adresse ein.
            </div>


            <div className="tl pa3 pa5-ns pt5-ns pb4-ns mw6 center mt5 bg-white br3 box-shadow b--black-10">
                <Form
                    onSubmit={() => {
                        history.push("/fahrtenbuch/checkout/")
                    }
                    }
                >
                    <Form.Input type="email" name="email" label={"Geben Sie Ihre E-Mail Adresse ein"} placeholder="tim@muster-firma.de"
                                value={email}
                                onChange={(event) => {
                                    checkoutStore.setEmail(event.currentTarget.value)
                                }
                                }/>
                    <Button as={Link} to={"/fahrtenbuch/checkout"} fluid size="large" color="yellow"><span className="ttu dib pv1">Weiter</span></Button>
                </Form>

                <div className="tc pt3 black-70">
                    Vereinfachen Sie Ihr Fahrtenbuch. Sparen Sie jede Woche Zeit und Geld.
                </div>
            </div>

        </div>

    </Container>
})

const GuideDivider = () => {
    return <div className="GuideDivider ph2">
        <div className=" br-100 w3-ns h3-ns w2 h2 tc flex flex-row items-center justify-around">
            <div className="w-100">
                <hr/>
            </div>
        </div>
        <div className={"pt2"}>&nbsp;</div>
    </div>
}

const StepCheckout = () => {


    return <Container className="pt3">
        <div className="flex flex-row items-center justify-around">
            <div className="flex flex-row">
                <GuideStep title={"Anmeldung"} number={"1"} state={"done"}/>
                <GuideDivider/>
                <GuideStep title={"Persönliche Daten"} number={"2"} state={"active"}/>
                <GuideDivider/>
                <GuideStep title={"Bestätigung"} number={"3"} state={"open"}/>
            </div>

        </div>

        <div className="tc pt5 lh-title">
            <div className="b f4 pb2 ttu">2. Persönliche Daten</div>
            <div className="primary f2">Ihre Stammdaten, Rechnungsadresse & Lieferdaten</div>

            <div className="pt4 f4 mw7 center lh-copy">
                Bitte tragen Sie hier Ihre Stammdaten ein. Abweichende Rechnungs- und Lieferanschriften können Sie hier angeben.
            </div>
        </div>

        <BuyForm/>

    </Container>
}

const StepSuccess = (props) => {
    return <Container className="pt3">
        <div className="flex flex-row items-center justify-around">
            <div className="flex flex-row">
                <GuideStep title={"Anmeldung"} number={"1"} state={"done"}/>
                <GuideDivider/>
                <GuideStep title={"Persönliche Daten"} number={"2"} state={"done"}/>
                <GuideDivider/>
                <GuideStep title={"Bestätigung"} number={"3"} state={"active"}/>
            </div>

        </div>

        <div className="tc pt5 lh-title">
            <div className="b f4 pb2 ttu">3. Bestätigung</div>
            <div className="primary f2">Ihre Bestellung</div>

            <div className="pt4 f4 mw7 center lh-copy">
                Sie erhalten Ihre Rechnung innerhalb der nächsten Wochen per Post.
            </div>
        </div>

        <div className="pt4-ns pt3">
            <OrderPage {...props}/>
        </div>

    </Container>
}

const GuideStep = ({number = "1", title = "", state = "active"}) => {
    const className = `${state === "active" && "bg-black white"} ${state === "done" && "bg-black white"} ${state === "open" && "bg-transparent black bb-black-20"}`
    return <div className="GuideStep flex flex-column items-center">
        <div className={`ba br-100 w2 h2 w3-ns h3-ns tc flex flex-row items-center justify-around ${className}`}>
            <div className="f4 b">{state === "done" ? <Icon icon={faCheck}/> : number}</div>
        </div>
        <div className={"pt2 f5-ns f7"}>{title}</div>
    </div>
}


const FormFieldStore = ({name, setValue, label, autoComplete = "", ...rest}) => <Form.Field>
    <label htmlFor="name">{label}</label>
    <input onChange={(e) => {
        setValue(name, e.target.value)
    }} id={name} name={name} autoComplete={autoComplete || name} {...rest} />
</Form.Field>