import * as React from "react"
import {observer} from "mobx-react"
import BaseLayout from "layout/BaseLayout.js"
import MapLayout from "layout/MapLayout.js"

import RouteWithLayout from "layout/RouteWithLayout.js"
import RouteWithLogin from "layout/RouteWithLogin.js"

import asyncComponent from "util/asyncComponent.js"
import DriveBookPage from "page/DriveBookPage"
import OnboardingPage from "page/OnboardingPage.js"
import LoginPage from "page/LoginPage"
import BuyPage from "page/BuyPage"
import RegistrationPage from "page/RegistrationPage"

import LoadingOverlay from "component/LoadingOverlay"
import RegisterDemoModal from "component/RegisterDemoModal"
import {ToastContainer} from "react-toastify";
import 'style/toast.css';

import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom"
import store from "store/store"
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment"
import "moment/locale/de"
import Rollbar from "rollbar";

import "component/Mobile/RoutePage.css"
import "./App.css"
import EmptyLayout from "./layout/EmptyLayout";
import ActivationPage from "./page/ActivationPage";
import OrderPage from "./page/OrderPage";
import SmallSidebarLayout from "layout/SmallSidebarLayout.js";
import LoginOncePage from "page/LoginOncePage"
import {Button, Modal} from "semantic-ui-react"
import {Onboarding} from "./Onboarding";
import {VehicleConnectionPage} from "./VehicleConnectionPage";
import FirstWelcomePage from "./page/FirstWelcomePage/FirstWelcomePage";

const DashboardPage = asyncComponent(() => import("page/DashboardPage"))
const PluginPage = asyncComponent(() => import("plugin/PluginPage.js"))

moment.locale("de")
window["store"] = store
window["REF_URL"] = document && document.location && `${document.location.pathname}${document.location.search}`

@observer
class App extends React.Component {

    constructor(props) {
        super(props);
        let rollbar = new Rollbar({
            enabled: (process.env.NODE_ENV === 'production'),
            accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true,
        })
        window["rollbar"] = rollbar
    }

    render() {
        const account = store.account
        const {ready, inActive, preparing, user, vehicle} = store.account
        const {vehicles} = vehicle

        if (inActive && !store.account.user.keycard.companyManager) {
            return <div className="tc min-h-100 flex flex-row items-center h-100 justify-center">
                <div>
                    <h1>Account nicht freigeschaltet</h1>
                    <div>Wenden Sie sich an Ihren Account Administrator.</div>
                    <div className="pt3">
                        <Button onClick={() => {
                            store.account.logout()
                        }}>Abmelden</Button>
                    </div>
                </div>
            </div>
        }
        return (
            <div className="App">

                <ToastContainer
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover
                />
                <LoadingOverlay/>

                <Router>
                    <div className="app-wrapper">
                        <Onboarding/>
                        <Route path={`/demo-login`} component={DemoLogin}/>
                        {ready && <React.Fragment>
                          <Route path="/:url*" exact strict render={props => <Redirect to={{
                              pathname: props.location.pathname + "/",
                              search: props.location.search,
                              state: {preventScrollToTop: true}
                          }}/>}/>


                          <RouteWithLayout layout={MapLayout} path="/demo" component={RegisterDemoModal}/>
                          <Route path={"/map/vehicle/:vehicleId/connection"} component={VehicleConnectionPage}/>
                          <Switch>
                            <Route path={"/plugin"} component={PluginPage}/>
                            <RouteWithLayout layout={BaseLayout} path="/registration" component={RegistrationPage}/>
                            <RouteWithLayout layout={BaseLayout} path="/login" component={LoginPage}/>
                            <RouteWithLayout layout={BaseLayout} path="/mfr" component={props => <LoginPage mfr={true} {...props} />}/>
                            <RouteWithLogin layout={BaseLayout} path="/onboarding" exact component={OnboardingPage}/>
                            <Route layout={EmptyLayout} path="/activate-user/:activationToken" exact component={ActivationPage}/>
                            <Route layout={EmptyLayout} path="/once/:hash" exact component={LoginOncePage}/>

                            <RouteWithLayout layout={EmptyLayout} path="/buy" component={BuyPage}/>
                            <RouteWithLayout layout={EmptyLayout} path="/fahrtenbuch" component={FirstWelcomePage}/>
                            <RouteWithLayout layout={EmptyLayout} path="/order/:orderId?" component={OrderPage}/>

                              {/*TODO: Duplication with Route in DashboardPage*/}
                            <RouteWithLogin layout={SmallSidebarLayout} path="/fahrtenbuch/:vehicleId?"
                                            component={DriveBookPage}/>

                            <RouteWithLogin layout={BaseLayout} path="/" component={DashboardPage}/>
                          </Switch>
                        </React.Fragment>}


                    </div>
                </Router>
            </div>
        )
    }
}


function DemoLogin() {
    const history = useHistory()
    React.useEffect(() => {

        async function login() {
            console.log("DEMO LOGIN")
            store.account.logout(false)
            sessionStorage.clear()
            localStorage.clear()
            const username = "demo@flottr.com"
            const password = "tolle demo"
            const permanent = true
            window["REF_URL"] = "/demo"
            let session
            try {
                await store.account.login({username, password, permanent})
                history.replace("/demo")
            } catch (err) {
                // console.log.warn( err );
            }
            // console.log.log( session );
        }

        login()

    })

    return <Modal open>
        <Modal.Header>Demo-Anmeldung</Modal.Header>
        <Modal.Content>Sie werden zur FLOTTR Demo weitergeleitet.</Modal.Content>
    </Modal>
}


export default App
