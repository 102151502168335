import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Button, Form, Image, Modal, Popup } from "semantic-ui-react"
import { Link, withRouter } from "react-router-dom"
import store from "store/store"
import { observer } from "mobx-react"
import { faMapMarked, faPencil, faRoute, faShare, faStickyNote, faTimes, faUserSecret } from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./VehicleSidebar.css"
import Icon from "component/Icon"
import { faSignature } from "@fortawesome/pro-regular-svg-icons"
import SimpleMap from "component/Map/SimpleMap.js"
import { Tracker } from "plugin/Share/Share.js"
import parseQuery from "util/parseQuery"
import moment from "moment"
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons"
import create from "zustand"
import { GPSLabel } from "page/VehiclesPage"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { faPhoneAlt, faPhoneSquare, faSatellite, faWifi } from "@fortawesome/free-solid-svg-icons"

const [useSidebarContext, sidebarApi] = create(set => ({
	title: false,
	children: false,
	setContext: ({ title = null, children = null }) => set(state => ({ title, children }))
}))

export function SidebarContext({ optional = false, forceHide = false }: any) {
	useEffect(() => {
		sidebarApi.setState({ optional, forceHide })
		return () => {
			// TODO: THIS SETS ALL CONTEXT TO FALSE -> Needs STACK
			sidebarApi.setState({ optional: false, forceHide: false })
		}
	}, [optional, forceHide])
	return null
}

function VehicleSidebarContent({ vehicle }) {
	const { optional, forceHide } = useSidebarContext(state => state)
	return <div
		key={"SIDEBAR"}
		className={`SidebarLeft sidebar overflow-y-scroll  mw6 w-100 bg-white flex-shrink-0 ${optional ? "optional" : ""} ${forceHide ? "force-hide" : ""}`}
	>
		<div className="DeviceSidebar VehicleSidebar relative ">
			<VehicleDetails vehicle={vehicle} />
		</div>
	</div>
}

// @ts-ignore
@withRouter @observer
class VehicleSidebar extends React.Component<any, any> {

	render() {
		const query = parseQuery(this.props) // context/query from router
		const selectedVehicleId = query.vehicleId
		const vehicle = store.account.vehicle.byId.get(`${selectedVehicleId}`)
		if (!vehicle) return null
		return <VehicleSidebarContent vehicle={vehicle} />

	}
}

export default VehicleSidebar

function VehicleDetails({ vehicle }) {

	useEffect(() => {
		vehicle?.debouncedFetchNotes()
	}, [vehicle])

	if (!vehicle) return null
	if (!vehicle) {
		console.error("NO VEHICLE", { vehicle })
		return null
	}
	const device = store.account.device.byId.get(vehicle.deviceId)

	return <div className="VehicleDetails">
		<div className=" dn dn-m db-ns pb7 relative ">
			<div key={`${vehicle._id}+img`} className={"bg-dark-gray absolute top-0 left-0 right-0 bottom-0 cover bg-center w-100 h-100"} style={{
				backgroundImage: `url("${vehicle.vehicleImage || "none"}")`,
				filter: vehicle.vehicleImage ? "brightness(0.4)" : ""
			}}>
			</div>

			<div className="absolute top-1 right-1">
				{/*<Link className="f6 white-80"*/}
				{/*	  to={`/map/${vehicle._id}/vehicles/`}> bearbeiten</Link>*/}
				<Link className={"pa3 white"} to={"/map/"}><FontAwesomeIcon icon={faTimes} /></Link>
			</div>

			{device && <div className="absolute top-1 left-1">
				<GPSLabel device={device} />
			</div>}

			<div className="absolute left-1 bottom-1 right-1 w-auto white">
				<div className={"b f4"}>{vehicle.title}</div>
				<div>{vehicle.vehiclePlate}</div>
			</div>

			<div className="tr absolute bottom-1 right-1">
				<div className="DriverImage">
					{vehicle.driverImage && <Popup content={vehicle.driverName} trigger={<Image title={vehicle.driverName} width={110} circular src={vehicle.driverImage} />} />}
					{!vehicle.driverImage && <Link to={`${vehicle.basePath}/edit/`}
																				 title={vehicle.driverName ? vehicle.driverInitials : "Fahrer hinzufügen"}
																				 className={"bg-white br-pill flex flex-column items-center justify-center"}
																				 style={{ width: "75px", height: "75px" }}>
						<div className={"b f5 tc black-70 "} title={vehicle.driverName}>{vehicle.driverName ? <span className="f2 lh-solid">{vehicle.driverInitials}</span> : <Icon icon={faUserPlus} />}</div>
					</Link>}
				</div>
			</div>
		</div>

		{device && <div className="db db-m dn-ns">
			<SimpleMap
				style={{ height: "220px", maxHeight: "30vh" }}
				disableAutoBounds
				zoom={14}
				center={{
					lat: device.lat,
					lng: device.lng
				}}
			>
				<Tracker
					lat={device.lat}
					lng={device.lng}
					vehicle={vehicle}
					ping={device}
					text={<div>
						{vehicle.vehiclePlate}
					</div>}
				/>
			</SimpleMap>
		</div>}


		<div className="pa3 ph4-ns ">
			<div className="flex flex-row justify-between">
				{vehicle.driverName && <div className="flex flex-column pt2">
					<div>
						<div className="f6-ns f7  silver">Fahrer</div>
					</div>
					<div>
						<div className="f5-ns f6">{`${vehicle.driverName}`.split("(")[0]}</div>
					</div>
				</div>}

				{vehicle.deviceId && <div className="flex flex-column pt2">
					<div>
						<div className="f6-ns f7  silver">Status</div>
					</div>
					<div>
						<div className="f5-ns f6"><GPSLabel text device={device} /></div>
					</div>
				</div>}
			</div>

			<div className="flex flex-row justify-between pb3">


			</div>


			{/*<Table unstackable basic={"very"} compact singleLine>*/}

			{/*	<Table.Body>*/}
			{/*		{vehicle.vehicleGroup && <Table.Row>*/}
			{/*			<Table.HeaderCell>Gruppe</Table.HeaderCell>*/}
			{/*			<Table.Cell>{vehicle.vehicleGroup}</Table.Cell>*/}
			{/*		</Table.Row>}*/}
			{/*		{vehicle.vehicleDriver ? <Table.Row>*/}
			{/*			<Table.HeaderCell>Fahrer</Table.HeaderCell>*/}
			{/*			<Table.Cell>{vehicle.vehicleDriver}</Table.Cell>*/}
			{/*		</Table.Row> : null}*/}
			{/*		{vehicle.vehicleManufacturer ? <Table.Row>*/}
			{/*			<Table.HeaderCell>Marke</Table.HeaderCell>*/}
			{/*			<Table.Cell>{vehicle.vehicleManufacturer}</Table.Cell>*/}
			{/*		</Table.Row> : null}*/}
			{/*		<Table.Row>*/}
			{/*			<Table.HeaderCell>Kennzeichen</Table.HeaderCell>*/}
			{/*			<Table.Cell>{vehicle.vehiclePlate}</Table.Cell>*/}
			{/*		</Table.Row>*/}
			{/*	</Table.Body>*/}
			{/*</Table>*/}

			<LastNote vehicle={vehicle} />


			<React.Fragment>
				<div className="">
					<div className="mb1">
						<Button basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/route/`}>
							<div className="tl"><Icon className={"fa-fw mr1"} icon={faRoute} /> Tagesbericht</div>
						</Button>
					</div>
					<div className="mb1">
						{/*/export/?deviceId=358480085065359&startDate=2024-04-15&endDate=2024-04-21&scale=week*/}
						<Button basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/export/?startDate=${moment().subtract(1, "day").format("YYYY-MM-DD")}&endDate=${moment().subtract(0, "day").format("YYYY-MM-DD")}&scale=day`}>
							<div className="tl"><Icon className={"fa-fw mr1"} icon={faRoute} /> Berichte (PDF)</div>
						</Button>
					</div>
				</div>

				<div className="pb3">
					<div className="mb1"><Button basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/fahrtenbuch/edit`}>
						<div className="tl"><Icon className={"fa-fw mr1"} icon={faMapMarked} /> Fahrtenbuch</div>
					</Button></div>

					{/*<div className="mb1"><Button  basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/fahrtenbuch/edit`}>*/}
					{/*	<div className="tl"><Icon className={"fa-fw mr1"} icon={faMapMarked} /> Fahrtenbuch bearbeiten </div>*/}
					{/*</Button></div>*/}
				</div>

			</React.Fragment>


			<div className="">
				{/*<div className="mb1"><Button  basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/documents/contract/`}>*/}
				{/*	<div className="tl"><Icon className={"fa-fw mr1"} icon={faSignature} /> Verträge</div>*/}
				{/*</Button></div>*/}
				{/*<div className="mb1"><Button  basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/documents/fuel/`}>*/}
				{/*	<div className="tl"><Icon className={"fa-fw mr1"} icon={faGasPump} /> Tankbelege</div>*/}
				{/*</Button></div>*/}
				{/*<div className="mb1"><Button  basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/documents/invoice/`}>*/}
				{/*	<div className="tl"><Icon className={"fa-fw mr1"} icon={faFileInvoice} /> Rechnungen</div>*/}
				{/*</Button></div>*/}
				{/*<div className="mb1"><Button  basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/documents/damage/`}>*/}
				{/*	<div className="tl"><Icon className={"fa-fw mr1"} icon={faCarCrash} /> Schadensberichte</div>*/}
				{/*</Button></div>*/}
			</div>

			<div className="pt3">
				{store.account.user.keycard.eventManager && <div className="mb1"><Button basic className={"MenuButton"} fluid as={Link} to={`/events/?page=1&vehicleIds=${vehicle._id}`}>
					<div className="tl"><Icon className={"fa-fw mr1"} icon={faExclamationCircle} /> Ereignisse</div>
				</Button></div>}
				<div className="mb1"><Button basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/documents/`}>
					<div className="tl"><Icon className={"fa-fw mr1"} icon={faSignature} /> Dokumente</div>
				</Button></div>
				<div className="mb1"><Button basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/notes/`}>
					<div className="tl"><Icon className={"fa-fw mr1"} icon={faStickyNote} /> Notizen</div>
				</Button></div>

				{vehicle.deviceId && <div className="mb1">
					<ShareVehicle vehicle={vehicle} />
				</div>}

				{(!vehicle.deviceId || `${vehicle.deviceId}`.startsWith("app")) && <div className="mb1">
					<Button basic className={"MenuButton"} fluid as={Link} to={`/map/vehicle/${vehicle._id}/connection`}>
						<div className="tl"><Icon className={"fa-fw mr1"} icon={faPhoneSquare} /> Smartphone verbinden</div>
					</Button>
				</div>}
			</div>


			<div className="pt3">
				<div className="mb1">
					<Button basic className={"MenuButton"} fluid as={Link} to={`/map/${vehicle._id}/edit/`}>
						<div className="tl"><Icon className={"fa-fw mr1"} icon={faPencil} /> Bearbeiten</div>
					</Button>
				</div>

				{/*<div className="mb1">*/}
				{/*	<Button  basic className={"MenuButton"} fluid as={"a"} href={`http://localhost:8080/web/privacy/${vehicle.deviceId}/`}>*/}
				{/*		<div className="tl"><Icon className={"fa-fw mr1"} icon={faUserSecret} /> Privacy Mode</div>*/}
				{/*	</Button>*/}
				{/*</div>*/}

				{device && <div className="mb1">
					<PrivacyButton vehicle={vehicle} device={device} />
				</div>}


				{/*{!vehicle.deviceId && <div className="mb1">*/}
				{/*  <Button primary basic className={"MenuButton"} fluid as={Link} to={`/buy/?vehicleId=${vehicle._id}`}>*/}
				{/*    <div className="tl"><Icon className={"fa-fw mr1"} icon={faWifi}/> Tracker kaufen</div>*/}
				{/*  </Button>*/}
				{/*</div>}*/}

				{!vehicle.featureByKey["FEATURE_BASE"] && <Button primary basic className={"MenuButton"} fluid as={Link} to={`/buy/?vehicleId=${vehicle._id}`}>
					<div className="tl"><Icon className={"fa-fw mr1"} icon={faWifi} /> Tracker kaufen</div>
				</Button>}

				{(vehicle.featureByKey["FEATURE_BASE"] && !vehicle.featureByKey["FEATURE_DRIVEBOOK"]) && <Button primary basic className={"MenuButton"} fluid as={Link} to={`/buy/?vehicleId=${vehicle._id}`}>
					<div className="tl"><Icon className={"fa-fw mr1"} icon={faWifi} /> Upgrade Tracker</div>
				</Button>}

				{(vehicle.featureByKey["FEATURE_BASE"] && !vehicle.featureByKey["FEATURE_REALTIME"]) && <Button primary basic className={"MenuButton"} fluid as={Link} to={`/buy/?vehicleId=${vehicle._id}`}>
					<div className="tl"><Icon className={"fa-fw mr1"} icon={faWifi} /> Upgrade Tracker</div>
				</Button>}


			</div>

		</div>
	</div>
}

function ShareVehicle({ vehicle }) {
	const [isOpen, setModal] = useState(false)

	return <React.Fragment>
		<Button onClick={() => {
			setModal(!isOpen)
		}} basic className={"MenuButton"} fluid>
			<div className="tl"><Icon className={"fa-fw mr1"} icon={faShare} /> Position Teilen</div>
		</Button>
		<PositionShareModal key={vehicle._id} open={isOpen} onClose={() => setModal(false)} vehicle={vehicle} />
	</React.Fragment>
}


const PrivacyButton = observer(({ device, vehicle }) => {
	return <Modal
		onClose={() => {
			device && device.fetch()
		}
		}
		closeIcon={<div className=" absolute top-1 right-1">
			<Button circular className={"icon"}>
				<Icon icon={faTimes} />
			</Button>
		</div>}
		trigger={<Button color={device.privacyMode === "eTracking" ? undefined : "blue"} basic className={"MenuButton"} fluid>
			<div className="tl"><Icon className={"fa-fw mr1"} icon={faUserSecret} /> Privacy Mode</div>
		</Button>
		}>
		<div className="">
			<iframe width={"100%"} height={420} src={`${process.env.REACT_APP_API_URL}/web/privacy/${vehicle.deviceId}/`} frameBorder="0" />
		</div>
	</Modal>

})

@observer
class LastNote extends React.Component<{ vehicle: any }> {
	render() {
		let { vehicle } = this.props
		const { lastNote } = vehicle
		if (!lastNote) return null
		const { size } = vehicle.noteById
		return <div className={"pb3"}>
			<Link to={`/map/${vehicle._id}/notes/`} className="db bg-silver pa3" style={{ borderRadius: "9px 9px 0 9px", border: "1px solid #9b9bd3" }}>
				<div className="black-80 pb2">
					{`${lastNote.content}`.substring(0, 66)}
					{`${lastNote.content}`.length > 67 && "..."}
				</div>
				<div title={moment(lastNote.created).format("HH:mm [Uhr] DD.MM.YYYY")} className={" f6 black-70 flex flex-row justify-between"}>
					<div>{lastNote.userName}</div>
					<div>{moment(lastNote.created).format("DD.MM.YYYY")}    </div>
				</div>
			</Link>
		</div>
	}
}

function PositionShareModal({ open, vehicle, onClose }) {
	const [hours, setHours] = React.useState(1)
	const [share, setShare]: [any, Function] = React.useState(false)
	return <Modal open={open}>
		<Modal.Header>
			<div className="flex flex-row justify-between">
				Positionsfreigabe
				<Button onClick={onClose} icon={faTimes} />
			</div>
		</Modal.Header>
		<Modal.Content>

			{share && <div>
				<Button as={"a"} href={share.url} target="_blank">Freigabe anzeigen</Button>
				<CopyInput value={share.url} />
				{/*<pre>{JSON.stringify( share, null, 2 )}</pre>*/}
			</div>}

			{/*<Header>{vehicle.vehiclePlate}</Header>*/}


			{!share &&
				<React.Fragment>
					<p>
						Teilen Sie die Position von Fahrzeug <b className="f3">{vehicle.title}</b> {vehicle.vehiclePlate} für <b className="f3">{hours} Stunden</b>
					</p>
					<div className="center">
						<Button.Group>
							<Button active={hours === 1} onClick={() => setHours(1)}>1 h</Button>
							<Button active={hours === 2} onClick={() => setHours(2)}>2 h</Button>
							<Button active={hours === 4} onClick={() => setHours(4)}>4 h</Button>
							<Button active={hours === 24} onClick={() => setHours(24)}>24 h</Button>
							{/*<Button onClick={() => setHours( Infinity )}>Auf Widerruf</Button>*/}
						</Button.Group>
					</div>
				</React.Fragment>
			}


		</Modal.Content>

		<Modal.Actions>
			<Button
				onClick={async () => {
					const res = await vehicle.createShareURL({ hours })
					const share = res.data
					// console.log( "SHARE", share )
					setShare(share)
				}
				}
				primary> Freigeben </Button>

		</Modal.Actions>

	</Modal>
}


function CopyInput({ value }) {

	const [copySuccess, setCopySuccess] = useState("")
	const textAreaRef = useRef<HTMLInputElement>(null)

	function copyToClipboard(e) {
		textAreaRef?.current?.select()
		document.execCommand("copy")
		e.target.focus()
		setCopySuccess("Kopiert!")
	}

	return (
		<Form>
			<div className="flex flex-row items-center">
				<input className={"ui input"} type="text" value={value} ref={textAreaRef} />
				{
					document.queryCommandSupported("copy") &&
					<div>
						<Button onClick={copyToClipboard}>{copySuccess ? "Kopiert" : "Kopieren"}</Button>
					</div>
				}
			</div>
		</Form>
	)
}


const MenuButton = ({ children, className = "", ...props }) => <div className="mb1"><Button className={""} fluid {...props}>
	<div className="tl">{children}</div>
</Button></div>