import * as React from "react"
import { observer } from "mobx-react"
import { Button, Divider, Form, Message, Responsive } from "semantic-ui-react"
import { Link, useHistory, useParams } from "react-router-dom"
import store from "store/store"
import moment from "moment"
import "moment/locale/de"
import * as queryString from "querystring"
import parseQuery from "util/parseQuery"
import { PageHeaderContext, PageHeaderSimple } from "component/PageHeader"
import Trip from "../store/Trip"
import "./DriveBookPage.css"
import { SidebarContext } from "component/Vehicle/VehicleSidebar"
import { faArrowRight, faBusinessTime, faCheckCircle, faDownload, faHouseLeave, faQuestionCircle, faTimes, faUmbrellaBeach } from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import Icon from "component/Icon"
import { ITrips } from "store/model/Vehicle"
import { Spinner } from "component/Spinner/Spinner.js"
import OpenNewDrivelog from "page/OpenNewDrivelog"
import DropdownYear from "component/DropdownYear"
import { uniqBy } from "lodash"
import { action, observable } from "mobx"
import SimpleMap from "component/Map/SimpleMap.js"
import { toast } from "react-toastify"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { orderBy, isEqual } from "lodash"

window[ "moment" ] = moment

function Trips({ trips, vehicle, activeTripId }: { activeTripId: string, trips: any, vehicle: any }) {

	React.useEffect(()=>{
		let lastTrip = false;
		[ ...trips.byDay.entries() ]
			.reverse()
			.forEach(([ day, values ])=>{
				values.slice()
					.reverse()
					.forEach(trip=>{
						if ( lastTrip ) trip.prev = lastTrip
						lastTrip = trip
					})
			})
	}, [ trips ])

	return <React.Fragment>
		{ [ ...trips.byDay.entries() ]
			.map(([ day, value ])=>{
				return <div className={ "pv3" } key={ day }>
					<div className={ "black-70 pb2" }>{ moment(day).format("dddd, DD.MM.YYYY") }</div>
					<div>{ value.map(trip=>{
						return <TripEntry active={ activeTripId === trip.id } trip={ trip } key={ trip.id } vehicle={ vehicle } />
					}) }</div>
				</div>
			}) }
	</React.Fragment>
}


interface IDropdownOption {
	key: string,
	value: any,
	text: string
}

class DriveBookOptions {

	@observable contactOptions: Array<IDropdownOption> = []
	@observable causeOptions: Array<IDropdownOption> = []
	@observable companyOptions: Array<IDropdownOption> = []

	constructor(businessTrips) {
		this.contactOptions = uniqBy(businessTrips
			.filter(trip=>trip.trip_contact)
			.map(trip=>{
				return { key: trip.trip_contact, value: trip.trip_contact, text: trip.trip_contact }
			}), ( k=>k.key ))

		this.causeOptions = uniqBy(businessTrips
			.filter(trip=>trip.trip_cause)
			.map(trip=>{
				return { key: trip.trip_cause, value: trip.trip_cause, text: trip.trip_cause }
			}), k=>k.key)

		this.companyOptions = uniqBy(businessTrips
			.filter(trip=>trip.trip_company)
			.map(trip=>{
				return { key: trip.trip_company, value: trip.trip_company, text: trip.trip_company }
			}), k=>k.key)
		global[ "bookOptions" ] = this

	}

	@action
	setContactOptions(value) {
		this.contactOptions = value
	}

	@action
	setCompanyOptions(value) {
		this.companyOptions = value
	}

	@action
	setCauseOptions(value) {
		this.causeOptions = value
	}

}

@observer
class DriveBookEditorPage extends React.Component<any, {
	loading: boolean,
	trips?: ITrips,
	bookOptions?: DriveBookOptions,
}> {

	state = {
		trips: undefined,
		loading: false,
		bookOptions: undefined
	}

	private month: string
	private year: string

	componentDidMount() {
		this.reTest()
	}

	componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{ trips?: ITrips }>, snapshot?: any) {
		this.reTest()
	}


	reTest = ()=>{
		const query = parseQuery(this.props)
		let { month, year, vehicleId } = query
		if ( !month ) {
			month = "OPEN"
			this.props.history.replace(this.props.location.pathname + "/?" + queryString.stringify({ ...query, month }))
			return
		}
		if ( this.month === `${ month }` && this.year === `${ year }` ) return

		this.setState({ loading: true })
		this.month = `${ month }`
		this.year = `${ year }`


		const vehicle = store.account.vehicle.byId.get(`${ vehicleId }`)
		if ( vehicle ) {
			vehicle.trips.fetchDrivebook({ month: `${ month }`, year: `${ year }` })
				.then(res=>{
					this.setState({ trips: res, loading: false })

					vehicle.trips.fetchDrivebook({ month: "ALL", year: `${ year }` })
						.then(res=>{
							const trips = res.all.filter(trip=>trip.trip_type)
							const businessTrips = trips.filter(trip=>trip.trip_type === "eBusiness")
							const workTrips = trips.filter(trip=>trip.trip_type === "eWorkplace")

							let bookOptions = new DriveBookOptions(businessTrips)

							this.setState({ bookOptions })

							// PARSE PRE-FILL
							// console.log( "ALL", res )
						})

				})
		} else {
			window.setTimeout(this.reTest, 250)
		}
	}

	render() {
		const { trips, loading } = this.state
		const query = parseQuery(this.props)
		const { startDate, year, vehicleId } = query
		const vehicle = store.account.vehicle.byId.get(`${ vehicleId }`)

		if ( !vehicle ) return null

		if ( !vehicle.mileage || !vehicle.mileageDate ) return <OpenNewDrivelog vehicle={ vehicle } />

		let trip: Trip | false = false
		const activeTripId = this.props.match.params.tripId

		let length = 0
		if ( trips ) {
			// @ts-ignore
			trip = trips.byId.get(activeTripId)
			// @ts-ignore
			length = trips.all.length
		}
		const noEntries = length === 0 && !loading

		const { bookOptions } = this.state

		// @ts-ignore
		return <div className={ "DriveBookEditorPage w-100 overflow-y-auto" }>
			<SidebarContext forceHide optional />
			<PageHeaderSimple title={ `Fahrtenbuch` }>
				<div className="pr2"><DropdownYear query={ query } /></div>
				{/*<Button as={Link} to={`${vehicle.basePath}/fahrtenbuch/?${queryString.stringify( query )}`}>2020</Button>*/ }
				<Button as={ Link } to={ `${ vehicle.basePath }/fahrtenbuch/?${ queryString.stringify(query) }` }>
					<span className="dib dn-ns"><Icon icon={ faDownload } /></span>
					<span className="dn dib-ns">Exportieren</span>
				</Button>
			</PageHeaderSimple>


			<div className="w-100 pa3-l bg-silver">

				{ !trip && <div className={ "center mw9 ph3 ph0-lg" }>
					<TimeStream trips={ trips } month={ query.month } year={ query.year } />
				</div> }

				{ loading ? <div className="pv5 animate fadeInUpSmall">
						<Spinner />
					</div> :
					<React.Fragment>
						<div className="center mw9">

							<Responsive maxWidth={ 1279 }>
								{/*	/!*ROUTES *!/*/ }
								{ !trip && <div className="flex w-100 flex-column order-1 order-0-l ph3 pa0-lg">
									{ trips && <Trips activeTripId={ activeTripId } trips={ trips } vehicle={ vehicle } /> }
								</div> }

								{ trip && <div className="w-100">
									<div className="">
										<TripDetails query={ query } key={ `${ trip.id }-details` } vehicle={ vehicle } trip={ trip } bookOptions={ bookOptions } />
									</div>
								</div> }
							</Responsive>

							<Responsive minWidth={ 1280 }>
								<div className="pa3 flex flex-row">

									{/*ROUTES */ }
									<div className="flex w-100 flex-column pa3 pa0-ns transition-300">
										{ trips && <Trips activeTripId={ activeTripId } trips={ trips } vehicle={ vehicle } /> }
									</div>

									{ trip && <div className="w-100  pa4-l pa3">
										<TripDetails query={ query } key={ `${ trip.id }-details-s` } vehicle={ vehicle } trip={ trip } bookOptions={ bookOptions } />

										{/* MAP. TODO */ }
										{/*<div className="pa6 tc mt3 bg-white">*/ }
										{/*	MAP*/ }
										{/*</div>*/ }
									</div> }

								</div>
							</Responsive>

							{/*<div className="w-100 flex flex-column flex-row-l justify-start ph4 ph0-ns mw7 center ">*/ }
							{/*	/!*ROUTES *!/*/ }
							{/*	{!trip && <div className="flex w-100 flex-column order-1 order-0-l pa3 pa0-ns">*/ }
							{/*		{trips && <Trips activeTripId={activeTripId} trips={trips} vehicle={vehicle} />}*/ }
							{/*	</div>}*/ }

							{/*	{trip && <div className="w-100 order-0 order-1-l pa4-l pa3">*/ }
							{/*		<div className="pa5-l pa3 pt3 tc bg-white sticky top-2">*/ }
							{/*			<TripDetails key={trip.id} vehicle={vehicle} trip={trip} />*/ }
							{/*		</div>*/ }

							{/*		/!* MAP. TODO *!/*/ }
							{/*		/!*<div className="pa6 tc mt3 bg-white">*!/*/ }
							{/*		/!*	MAP*!/*/ }
							{/*		/!*</div>*!/*/ }
							{/*	</div>}*/ }
							{/*</div>*/ }
						</div>
					</React.Fragment> }

				{ noEntries && <div>
					<div className="pa4 tc">
						<Message>Keine { query.month === "OPEN" && "offenen" } Fahrten für den ausgewählten Zeitraum.</Message>
					</div>
				</div> }


			</div>

		</div>
	}
}


function getTripTypeName(name) {
	const tripTypeNames = {
		"eWork": "Arbeitsweg",
		"eBusiness": "Betriebsfahrt",
		"ePrivate": "Privat"
	}

	return tripTypeNames[ name ] || "Unbekannt"
}


export default DriveBookEditorPage


const DeltaValue = ({ fieldname, value, old })=>{
	if (!old && value) return <span>{fieldname}: {value}</span>
	if ( old !== value ) return <span className=""><span className="strike">{ old ? old : '-' }</span>{ "->" } { value }</span>
	if ( old === value ) return null
	return null
}


const TripDetails = ( ({ vehicle, query, trip, bookOptions })=>{
	const params: { tripId?: string } = useParams()
	const history = useHistory()

	const {
		trip_cause,
		trip_comment,
		trip_company,
		trip_contact,
		trip_driver
	} = trip
	const [ tripType, setTripType ] = React.useState(trip.trip_type)
	const [ updates, _setUpdate ]: [ any, any ] = React.useState({
		trip_cause,
		trip_comment,
		trip_company,
		trip_contact,
		trip_driver
	})
	const [ success, setSuccess ] = React.useState(false)

	const currentYear = moment().startOf("year")
	const past8Days = moment().subtract(8, "days")
	const isSameYear = trip.endDate.isAfter(currentYear)
	const isArchived = trip.endDate.isBefore(past8Days)
	const shallContinue = !isArchived
	const setUpdate = (data)=>{
		_setUpdate(Object.assign(updates, data))
	}

	const [ loading, setLoading ] = React.useState(false)
	const [ backLink, setBackLink ] = React.useState(false)

	const save = async(shallContinue = false)=>{
		setLoading(true)
		updates.trip_type = updates.trip_type || trip.trip_type || "ePrivate"
		// CHECK ANLASS & GESCHÄFTSPARTNER
		let ok = true
		if ( tripType === "eBusiness" ) {
			ok = ok && updates.trip_cause
			ok = ok && updates.trip_contact
			ok = ok && updates.trip_company
			if ( !ok ) {
				toast.warn("Bitte füllen Sie alle benötigten Felder aus")
				setLoading(false)
				return
			}
		}

		await trip.put(updates)
		setLoading(false)
		setSuccess(true)

		if ( !shallContinue ) {
			toast.success("Fahrt gespeichert")
			history.push(`${ vehicle.basePath }/fahrtenbuch/edit/?month=${ query.month }`)
			return
		}

		window.setTimeout(()=>{
			if ( !shallContinue ) return
			if ( trip.prev ) {
				let prevTripId = trip.prev.id
				if ( prevTripId ) {
					// console.log( "PREV TRIP ID", prevTripId )
					history.push(`${ vehicle.basePath }/fahrtenbuch/edit/${ prevTripId }${ document.location.search }`)

				} else {
					setBackLink(true)
				}
			} else {
				console.error("NO PREV TRIP", trip)
				setBackLink(true)
			}

		}, 250)

	}

	if ( !trip ) return null
	if ( !bookOptions ) return <div><Button loading /></div>
	const { companyOptions, causeOptions, contactOptions } = bookOptions

	const hasRevisions = trip.revisions && Object.keys(trip.revisions).length > 0
	const revisionList = trip.revisions ? orderBy(Object.values(trip.revisions), "updated", "desc") : []

	return <div className={ "sticky top-2 TripDetails" }>

		<PageHeaderContext
			close={ false }
			title={ <div>
				<div>{ moment(trip.start).format("dddd, DD. MMMM ") }</div>
				<div className={ "f6 black-70" }>{ trip.startDateFriendly } <span className="dib pr2"> <Icon icon={ faArrowRight } /></span> { trip.endDateFriendly }</div>
			</div> }>
			<Button as={ Link } to={ `${ vehicle.basePath }/fahrtenbuch/edit/?month=${ query.month || "OPEN" }` } icon={ <Icon icon={ faArrowLeft } /> } />
		</PageHeaderContext>

		<div className="pa3 pt3 pa4-ns tc bg-white ">

			<Form onSubmit={ ()=>{
				save(shallContinue)
			} } className="TripDetails tl">

				{ !success && <div className="pb3 animate fadeIn  ">

					<div className="dn db-ns pb3">
						<div className={ "b" }>{ moment(trip.start).format("dddd, DD. MMMM ") }</div>
						<div className={ "f6 black-70" }>{ trip.startDateFriendly } <span className="dib pr2"> <Icon icon={ faArrowRight } /></span> { trip.endDateFriendly }</div>
					</div>

					<div className="pb3  ph0-l f7 f6-m f5-ns">
						<div className="f6 black-70 ">START</div>
						<div className={ "black  pb2 animate fadeInLeftSmall" }>{ trip.locationStart }</div>

						<div className="f6 black-70 ">ZIEL</div>
						<div className={ "black pb2 animate fadeInLeftSmall" }>{ trip.locationEnd }</div>

						<div className="f6 flex flex-row justify-start items-center  black-70 pt2">
							<div className="pr2 pr3-ns nowrap">{ trip.distance_humanized } km</div>
							<div className="pr2 pr3-ns nowrap">&bull;</div>
							<div className="pr2 pr3-ns nowrap">{ moment.duration(trip.duration).humanize() }</div>
						</div>
					</div>


					<div className={ "f6 black-70 ttu" }>Fahrt buchen:</div>

					<div className="TripDetailsActions flex flex-row">
						<Button animated={ "vertical" }
										fluid
										type={ "button" }
										primary={ tripType === "eBusiness" }
										onClick={ ()=>{
											setTripType("eBusiness")
											setUpdate({ trip_type: "eBusiness" })
										} }
						>
							<Button.Content visible>
								<span className="f7 f5-ns fw2">Betriebsfahrt</span>
							</Button.Content>
							<Button.Content hidden>
								<Icon icon={ faBusinessTime } />
							</Button.Content>
						</Button>

						<Button animated={ "vertical" }
										fluid
										type={ "button" }
										primary={ tripType === "eWork" }
										onClick={ ()=>{
											setTripType("eWork")
											setUpdate({ trip_type: "eWork" })
											save(shallContinue)
										} }>
							<Button.Content visible>
								<span className="f7 f5-ns fw2">Arbeitsweg</span>
							</Button.Content>
							<Button.Content hidden>
								<Icon icon={ faHouseLeave } />
							</Button.Content>
						</Button>

						<Button animated={ "vertical" }
										fluid
										type={ "button" }
										primary={ tripType === "ePrivate" }
										onClick={ ()=>{
											setTripType("ePrivate")
											setUpdate({ trip_type: "ePrivate" })
											save(shallContinue)
										} }>
							<Button.Content visible>
								<span className="f7 f5-ns fw2">Privat</span>
							</Button.Content>
							<Button.Content hidden>
								<Icon icon={ faUmbrellaBeach } />
							</Button.Content>
						</Button>

					</div>

				</div> }

				<Divider fitted />

				{ !tripType && <React.Fragment>
					<div className="pt3 pb3 ph3 tc  f6 ph5-l black-50">
						Diese Fahrt ist noch nicht gebucht. <br /> Wählen Sie eine Kategorie.
					</div>
					<div className="flex flex-row justify-end">
						<Button basic as={ Link } to={ `${ vehicle.basePath }/fahrtenbuch/edit/?month=${ query.month }` } loading={ loading } type="button">Zurück</Button>
					</div>
				</React.Fragment> }

				{/*<pre>{ JSON.stringify(trip.revisions, null, 2) }</pre>*/ }

				{ isArchived && <div className={ "pb3" }>
					<div className="pt3 pb3 ph3 tc  f6 ph5-l black-50">
						Diese Fahrt ist bereits verbucht. <br /> Änderungen werden vermerkt.
					</div>
					{/*<div className="flex flex-row justify-end">*/ }
					{/*	<Button basic as={ Link } to={ `${ vehicle.basePath }/fahrtenbuch/edit/?month=${query.month}` } loading={ loading } type="button">Zurück</Button>*/ }
					{/*</div>*/ }
				</div> }

				{ success && <div className={ "absolute TripDetailSuccess flex flex-row justify-center items-center" }>
					<div className="animate fadeInDown silver  flex flex-column items-center justify-center">
						<Icon icon={ faCheckCircle } />

						{ ( backLink || !shallContinue ) && <div className="pt3">
							<Button basic as={ Link } to={ `${ vehicle.basePath }/fahrtenbuch/edit/?month=${ query.month }` }>Weiter</Button>
						</div> }
					</div>
				</div> }

				{ tripType && <React.Fragment>
					{ tripType === "eBusiness" && <div className="animate fadeIn pt3 ">
						<div className="flex flex-row pb3 ">
							<div className="w-100 pr2"><TripEntryAutocompleteInput
								onAddItem={ (e, data)=>{
									const text = data.value
									contactOptions.push({
										key: text,
										text,
										value: text
									})
									bookOptions.setContactOptions(contactOptions)
									// console.log( "ADD ITEM", data, data.value )
								} }

								options={ contactOptions } required onChange={ setUpdate } name={ "trip_contact" } defaultValue={ trip.trip_contact } fluid label={ "Geschäftspartner" } /></div>
							<div className="w-100 pl2">
								<TripEntryAutocompleteInput
									onAddItem={ (e, data)=>{
										const text = data.value
										companyOptions.push({
											key: text,
											text,
											value: text
										})
										bookOptions.setCompanyOptions(companyOptions)
										// console.log( "ADD ITEM", data, data.value )
									} }

									options={ companyOptions } required onChange={ setUpdate } name={ "trip_company" } defaultValue={ trip.trip_company } fluid label={ "Firma" } />
								{/*<TripEntryInput onChange={setUpdate} name={"trip_company"} defaultValue={trip.trip_company} fluid label={"Firma"} />*/ }
							</div>
						</div>

						<TripEntryAutocompleteInput
							onAddItem={ (e, data)=>{
								const text = data.value
								causeOptions.push({
									key: text,
									text,
									value: text
								})
								bookOptions.setCauseOptions(causeOptions)
								// console.log( "ADD ITEM", data, data.value )
							} }
							options={ causeOptions } required onChange={ setUpdate } name={ "trip_cause" } defaultValue={ trip.trip_cause } fluid label={ "Anlass" } />
						{/*<TripEntryInput required onChange={setUpdate} name={"trip_cause"} defaultValue={trip.trip_cause} fluid label={"Anlass"} />*/ }


						<TripEntryInput onChange={ setUpdate } name={ "trip_driver" } defaultValue={ trip.trip_driver || vehicle.driverName } fluid label={ "Fahrer/in" } />

						<Form.TextArea onChange={ e=>{
							let trip_comment = e.currentTarget.value
							setUpdate({ trip_comment })
						} } defaultValue={ trip.trip_comment } label={ "Bemerkung" } />
					</div> }
				</React.Fragment> }

				{ tripType === "eBusiness" && <div className="pt3 pb3 flex flex-row justify-end animate fadeIn ">
					<Button basic as={ Link } to={ `${ vehicle.basePath }/fahrtenbuch/edit/` } loading={ loading } type="button">Zurück</Button>
					<Button basic loading={ loading } type="submit" primary>Speichern</Button>
					{/*{trip.next && <Button loading={loading} as={Link} to={`${vehicle.basePath}/fahrtenbuch/edit/${trip.next}/`} type="button" onClick={save} color={"green"}>Speichern & Weiter</Button>}*/ }
				</div> }


				{ hasRevisions && <div className="pv3">
					{ revisionList.map((revision: any, index)=>{

						const lastRev = revisionList[ index - 1 ] || trip

						if ( isEqual(lastRev, revision) ) return null

						return <div key={ revision.id || revision.updated }>
							<div className="flex flex-row f7">
								<div>Änderungsvermerk</div>
								<div className="ml-auto"> { moment(revision.updated).local().format("HH:mm [Uhr], DD.MM.YYYY") }</div>
							</div>
							<div className="bg-silver shadow-md br3 mb2 pa3">
								<div className="">
									<div><DeltaValue fieldname={"Art"} old={ getTripTypeName(revision.trip_type) } value={ getTripTypeName(lastRev.trip_type) } /></div>
									<div><DeltaValue fieldname={"FahrerIn"} old={ revision.trip_driver } value={ lastRev.trip_driver } /></div>
									<div><DeltaValue fieldname={"Firma"} old={ revision.trip_company } value={ lastRev.trip_company } /></div>
									<div><DeltaValue fieldname={"GeschäftspartnerIn"} old={ revision.trip_contact } value={ lastRev.trip_contact } /></div>
									<div><DeltaValue fieldname={"Kommentar"} old={ revision.trip_comment } value={ lastRev.trip_comment } /></div>
									<div><DeltaValue fieldname={"Anlass"} old={ revision.trip_cause } value={ lastRev.trip_cause } /></div>
								</div>
							</div>

						</div>
					}) }

				</div> }

				<SimpleMap
					onGoogleApiLoaded={ ({ map, maps })=>{
						// console.log( "MAP READY", trip, vehicle )
						let posStart
						if ( trip.pos_start ) {
							posStart = {
								lat: trip.pos_start.lat,
								lng: trip.pos_start.lng
							}
						}

						if ( !posStart && trip.prev && trip.prev.lat ) {
							return false
						}

						const posEnd = trip.posEnd

						if ( posStart && posStart.lat && posStart.lng ) {
							const tripPath = [
								posStart,
								trip.posEnd
							]

							new maps.Marker({
								position: posStart,
								map,
								label: { text: "1", color: "white" },
								title: "Start"
							})

							new maps.Marker({
								position: posEnd,
								map,
								label: { text: "2", color: "white" },
								title: "Ziel"
							})

							// console.log( {tripPath} )
							var lineSymbol = {
								path: "M 0,-1 0,1",
								strokeOpacity: 1,
								scale: 4
							}
							const polyline = new maps.Polyline({
								path: tripPath,
								icons: [ {
									icon: lineSymbol,
									offset: "0",
									repeat: "15px"
								} ],
								geodesic: true,
								strokeColor: "#424249",
								strokeOpacity: 0,
								strokeWeight: 2
							})
							var bounds = new maps.LatLngBounds()
							bounds.extend(posStart)
							bounds.extend(posEnd)
							map.fitBounds(bounds)
							polyline.setMap(map)
						}


					} }
				/>
				{/*<pre>{JSON.stringify( params, null, 2 )}</pre>*/ }
			</Form>

		</div>


		{/*<div className="pt1 ph3">*/ }
		{/*	<Divider />*/ }
		{/*	<div className="flex flex-row">*/ }
		{/*		<Checkbox toggle checked />*/ }
		{/*		<div className={"f7 f6-ns black-70 pl3 ttu"}>Automatisch zur nächsten Fahrt wechseln</div>*/ }
		{/*	</div>*/ }
		{/*</div>*/ }


	</div>
} )

const TripEntryInput = ({ onChange, name, ...rest })=>{
	return <Form.Input { ...rest } fluid onChange={ (e)=>{
		const text = e.currentTarget.value
		onChange({ [ name ]: text })
	}
	} />
}

const TripEntryAutocompleteInput = ({ onChange, onAddItem, label, options, name, ...rest })=>{

	return <Form.Dropdown
		placeholder={ label }
		fluid
		search
		selection
		onAddItem={ (e, data)=>{
			const text = data.value
			onAddItem(e, data)
			onChange({ [ name ]: text })
		}
		}
		additionLabel={ "Neu " }
		allowAdditions
		label={ label }
		options={ options }
		{ ...rest }
		onChange={ (e, data)=>{
			const text = data.value
			onChange({ [ name ]: text })
		}
		}
	/>
}


@observer
class TripEntry extends React.Component<{ active: boolean, vehicle: any, trip: any }> {
	render() {
		let { vehicle, trip, active } = this.props

		// add city to destination if it's not the same as the starting city/locality
		const cityEnd = trip.geoStart.locality !== trip.geoEnd.locality ? `, ${ trip.geoEnd.locality }` : ""

		return <Link to={ { pathname: `${ vehicle.basePath }/fahrtenbuch/edit/${ trip.id }/`, search: document.location.search } }
								 className={ `TripEntry black-80 mb2 pv2 ph2 ph3-ns bg-white flex flex-row items-center  w-100 ${ active ? "active shadow-1" : "" }` }>

			<div className={ "flex flex-row items-center" }>
				<div className={ "dn  w-100 flex-ns flex-column justify-center tr pr3 nowrap " }>
					<div className={ "f6 black" }>{ trip.startDate.format("HH:mm [Uhr]") }</div>
					<div className={ "f6 black-70" }>{ trip.endDate.format("HH:mm [Uhr]") }</div>
				</div>

				<div className={ "flex flex-column justify-center pr3  nowrap" }>
					<div className={ "f7 f6-ns black" }>{ trip.geoStart.route || trip.geoStart.establishment } { trip.geoStart.street_number }, { trip.geoStart.locality } </div>
					<div className={ "f7 f6-ns black-70" }> { trip.geoEnd.route || trip.geoEnd.establishment } { trip.geoEnd.street_number } { cityEnd } </div>
				</div>
			</div>

			{/*TODO: Add more data for eBusiness*/}
			{/*<div className={ "dn flex-ns flex-column justify-center nowrap" }>*/}
			{/*	A*/}
			{/*</div>*/}

			<div className={ "flex flex-row items-center ml-auto" }>

				<div className={ "f7 f6-ns pr3 tr" }>
					<div>{ trip.distance }&nbsp;km</div>
					<div>{ trip.duration_h }</div>
				</div>

				<div className="flex flex-row  items-center">
					<div className=" pa3">
						{ trip.trip_type === "ePrivate" && <Icon className={ "f4 f3-ns blue" } icon={ faUmbrellaBeach } /> }
						{ trip.trip_type === "eWork" && <Icon className={ "f4 f3-ns blue" } icon={ faHouseLeave } /> }
						{ trip.trip_type === "eBusiness" && <Icon className={ "f4 f3-ns blue" } icon={ faBusinessTime } /> }
						{ !trip.trip_type && <Icon className={ "f4 f3-ns black-70" } icon={ faQuestionCircle } /> }
					</div>
				</div>
			</div>
		</Link>
	}
}

function TimeStream({ trips, month = "", year = `${ moment().year }` }) {
	const isCurrentYear = `${ moment().year }` === year
	const history = useHistory()
	const query = queryString.parse(history.location.search.slice(1))
	const months = moment.months()
	const currentMonth = moment().month()
	const activeMonth = `${ query.month }`

	return <div className="TimStream pv3">
		<div className="flex flex-row items-center justify-between w-100">
			<h4 className={ "ma0" }>{ month ? moment().month(month).format("MMMM") : "Fahrten" }</h4>

			<div className="flex flex-row justify-between items-end ">
				{/*<div>*/ }
				{/*	<Button*/ }
				{/*		as={Link}*/ }
				{/*		size={"small"}*/ }
				{/*		primary={activeMonth === "OPEN"}*/ }
				{/*		to={{pathname: ".", search: queryString.stringify( {year, month: "OPEN"} )}}*/ }
				{/*		key={"7-days"}*/ }
				{/*		className={`pa2 ph3 br-1 mr3`}><span className="ttu">Offene Fahrten</span></Button>*/ }
				{/*</div>*/ }

				<Form className={ "ui form" }>
					<select
						onChange={ (e)=>{
							history.replace({ pathname: ".", search: queryString.stringify({ year, month: e.target.value }) })
						} }
						className={ "ui input" }>
						<option value="OPEN">offene Fahrten</option>
						{ months.map((month, index)=>{
							const past = !isCurrentYear || currentMonth >= index
							const active = `${ activeMonth }` === `${ index }`
							if ( !past ) return null
							return <option
								key={ month }
								selected={ active }
								value={ index }>
								{ month }
							</option>
						}) }
					</select>
				</Form>
			</div>

			{/*<Responsive minWidth={769}>*/ }
			{/*	<Button*/ }
			{/*		as={Link}*/ }
			{/*		size={"tiny"}*/ }
			{/*		primary={activeMonth === "OPEN"}*/ }
			{/*		to={{pathname: ".", search: queryString.stringify( {month: "OPEN"} )}}*/ }
			{/*		key={"7-days"} className={`pa2 ph3 br-1 mr3`}><span className="ttu">Offene Fahrten</span></Button>*/ }
			{/*	{months.map( ( month, index ) => {*/ }
			{/*		const past = currentMonth >= index*/ }
			{/*		const active = `${activeMonth}` === `${index}`*/ }
			{/*		return <Button*/ }
			{/*			as={Link}*/ }
			{/*			size={"tiny"}*/ }
			{/*			to={{pathname: ".", search: queryString.stringify( {month: index} )}}*/ }
			{/*			primary={active} basic={!past} key={month} className={`pa2 ph3 br-1 mr3`}><span className="ttu">{month.substr( 0, 3 )}</span></Button>*/ }
			{/*	} )}*/ }
			{/*</Responsive>*/ }


		</div>
	</div>
}

