import * as React from "react"
import {Button, Container, Menu, Modal, Table} from "semantic-ui-react"
import Dropzone from "react-dropzone"
import store from "../store/store"
import {observer} from "mobx-react"
import {toast} from "react-toastify"
import moment from "moment"
import {Link, NavLink, Route, withRouter} from "react-router-dom"
import {categoryPlural, categorySingular, DocumentCategory, DocumentStatus, File} from "store/FileStore"
import {PageHeaderContext, PageHeaderSimple} from "component/PageHeader"
import parseQuery from "util/parseQuery"
import {SidebarContext} from "component/Vehicle/VehicleSidebar"
import Icon from "component/Icon"
import {faTimes} from "@fortawesome/free-solid-svg-icons"

interface IUploadFileProps {
	afterFileUpload( fileId: string ): void

	vehicleId: string
	categoryId: DocumentCategory
}


const statusTerms = {
	"draft"    : "Entwurf",
	"published": "Fertig",
	"trash"    : "gelöscht",
}

class UploadFile extends React.Component<IUploadFileProps, any> {
	state = {uploading: false}
	onDrop = async files => {
		if ( this.state.uploading )
			return
		this.setState( {uploading: true} )

		const file = {
			vehicleId : this.props.vehicleId,
			categoryId: this.props.categoryId,
			localFile : files[0]
		}

		const {fileId} = await store.account.files.uploadFile( file )
		this.setState( {uploading: false} )
		this.props.afterFileUpload( fileId )
	}

	render() {
		const {uploading} = this.state
		const {categoryId} = this.props

		return (
			<Dropzone multiple={false} onDrop={this.onDrop}>
				{( {getRootProps, getInputProps, isDragActive} ) => {
					const btnClass = (uploading && "secondary loading disabled") || isDragActive && 'secondary' || 'primary'
					return (
						<div
							{...getRootProps()}
							className={`dropzone ${isDragActive && "dropzone-active"}`}
						>
							<input {...getInputProps()} />
							<Button className={btnClass}>
								<span>{categorySingular[categoryId]} hinzufügen</span>
							</Button>

							{/* {isDragActive ? (
								<p>Dateien hochladen</p>
							) : (
								<p>Dateien hochladen</p>
							)} */}
						</div>
					)
				}}
			</Dropzone>
		)
	}
}


export default class DocumentsPage extends React.Component<any, any> {

	async componentDidMount() {
		const {vehicleId} = this.props.match.params
		if ( vehicleId ) {
			let files = await store.account.files.fetchByVehicleId( vehicleId )
			// console.log( {vehicleId, files} )
		}
	}

	render() {
		const {vehicleId, categoryId = ""} = this.props.match.params
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		if ( !vehicle ) return null
		const categoryName = categoryPlural[categoryId]

		const afterFileUpload = ( fileId: string ) =>
			this.props.history.push( `${vehicle.basePath}/documents/${categoryId || ""}/${fileId}?from=${categoryId || ""}` )

		// console.log( {categoryName, categoryId} )
		return (
			<div className="DocumentsPage w-100">

				<PageHeaderSimple title={"Dokumente"}>
					<UploadFile
						vehicleId={vehicleId}
						categoryId={"media"}
						afterFileUpload={afterFileUpload}
					/>
				</PageHeaderSimple>
				<SidebarContext optional />

				<Route
					path="/map/:vehicleId/documents/:categoryId/:fileId"
					component={DocumentsSinglePage}
				/>
				<Route
					path="/map/:vehicleId/documents/:categoryId?"
					component={DocumentsOverviewPage}
				/>
			</div>
		)
	}
}

function GenericMetaBarComponents( {document}: { document: File } ) {
	// console.log( {document} )
	return (
		<React.Fragment>
			<Table.Row>
				<Table.Cell className={"b"}>Name</Table.Cell>
				<Table.Cell>{decodeURIComponent( document.name )}</Table.Cell>
			</Table.Row>

			<Table.Row>
				<Table.Cell className={"b"}>Datum</Table.Cell>
				<Table.Cell>
					{moment( document.created ).format( "DD. MM. YYYY" )}
					<span className=" f6"> {moment( document.created ).format( "HH:mm [Uhr]" )}</span>
				</Table.Cell>
			</Table.Row>

			<Table.Row>
				<Table.Cell className={"b"}>Status</Table.Cell>
				<Table.Cell>{statusTerms[document.status] || "Neu"}</Table.Cell>
			</Table.Row>

			<Table.Row>
				<Table.Cell className={"b"}>Größe</Table.Cell>
				<Table.Cell>{document.filesize}</Table.Cell>
			</Table.Row>
		</React.Fragment>
	)
}

const metabars = {
	default : ( {document, onchage} ) => <div>
		<Table>
			<Table.Body>
				<GenericMetaBarComponents document={document} />


			</Table.Body>
		</Table>
	</div>,
	contract: ( {document, onchage} ) => (
		<div>
			<Table>
				<Table.Body>
					<GenericMetaBarComponents document={document} />


				</Table.Body>
			</Table>
		</div>
	),
	fuel    : ( {document} ) => (
		<div>
			<Table>
				<Table.Body>
					<GenericMetaBarComponents document={document} />


				</Table.Body>
			</Table>
		</div>
	),
	accident: ( {document} ) => (
		<div>
			<Table>
				<Table.Body>
					<GenericMetaBarComponents document={document} />


				</Table.Body>
			</Table>
		</div>
	)
}

class DocumentPreview extends React.Component<{ document: File }, any> {
	render() {
		const {document} = this.props

		if ( document.isImage )
			return <img src={document.url} />

		// look into https://github.com/wojtekmaj/react-pdf
		if ( document.mimetype === "application/pdf" )
			return <div className="iframe-container relative"
						style={{
							width        : "100%",
							height       : 0,
							paddingBottom: "100%"
						}}>
				<iframe
					style={{
						position: "absolute",
						left    : 0,
						right   : 0,
						top     : 0,
						bottom  : 0,
						height  : "100%",
						width   : "100%"
					}}
					src={document.url} frameBorder="0" />
			</div>
			// else if (document.mimetype === 'application/pdf')
			// return <object data={document.url} type="application/pdf" height="100%" width="100%" />
			// return <embed src={document.url} type="application/pdf" height="100%" width="100%" />
		// return <iframe src={document.url} height="100%" width="100%" />
		else
			return <div>
				<div className="flex justify-center items-center">
					<div className="bg-silver pa3 pt5 mt4 mw6 w-100 tc" style={{paddingBottom: "62%"}}>
						<div className={"f3 b"}>{decodeURIComponent( document.name )}</div>
						<div className={"pt2"}>{document.mimetype} ({document.filesize})</div>
					</div>
				</div>
				{/*<pre>{JSON.stringify(document, null, '\t')}</pre>*/}
			</div>
	}
}

// @ts-ignore
@withRouter @observer
export class DocumentsSinglePage extends React.Component<any, any> {

	state = {
		loading: false
	}

	updateDocument = async ( fileId, meta ) => {
		const {vehicleId, categoryId} = this.props.match.params
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		const file = store.account.files.byFileId.get( fileId )
		if ( !file ) return
		if ( !vehicle ) return
		this.setState( {loading: true} )
		await store.account.files.putMeta( fileId, meta )
		this.setState( {loading: false} )
		this.props.history.push( `${vehicle.basePath}/documents/${file.categoryId || ""}` )
		toast.success( `${file.name} aktualisiert` )
	}

	handleMetaChange = meta => {
		// console.log( "NEW META", {document} )
	}

	render() {
		const {loading} = this.state
		const query = parseQuery( this.props )
		const {vehicleId, categoryId, fileId} = this.props.match.params
		const vehicle = store.account.vehicle.byId.get( vehicleId )
		const document = store.account.files.byFileId.get( fileId )
		if ( !vehicle ) return <div className="">Kein Gerät</div>
		if ( !document ) return <div className="">Kein Document</div>

		if ( query.from === "undefined" ) query.from = ""

		// console.log( "${query.from || categoryId || \"\"}", query.from, categoryId )

		const docName = decodeURIComponent( document.name )

		// Select metabar from categoryId or use default
		const Metabar = metabars[categoryId] || metabars["default"]

		return (
			<Modal size={"large"} open={true} className="DocumentsSinglePage">
				<Modal.Header>
					<div className="flex flex-row justify-between">
						{docName}
						<Button as={Link} to={`${vehicle.basePath}/documents/${query.from || categoryId || ""}`} icon={<Icon icon={faTimes}/>} />
					</div>
				</Modal.Header>

				<Modal.Content>
					<Menu>
						<Menu.Item>{categorySingular[categoryId]} bearbeiten</Menu.Item>
						<Menu.Item
							position="right"
							onClick={() => {
								this.updateDocument( fileId, {status: "trash"} )
							}}
						>Entfernen</Menu.Item>
						<Menu.Item
							onClick={() => {
								this.updateDocument( fileId, {status: "draft"} )
							}}
						>Entwurf speichern</Menu.Item>
						<Menu.Item
							onClick={() => {
								this.updateDocument( fileId, {status: "published"} )
							}}
						>Speichern</Menu.Item>
					</Menu>

					{/* SIDEBAR + CONTENT */}
					<div className="ui grid">
						<div className="document-preview ten wide column pb4">
							<DocumentPreview document={document} />
						</div>

						<div className="document-meta six wide column">
							<Metabar document={document} onChange={this.handleMetaChange} />
						</div>
					</div>
				</Modal.Content>
			</Modal>
		)
	}
}

// TODO
type SortableKey =
	| "indicator"
	| "status"
	| "name"
	| "created"
	| "due"
	| "partner"
	| "size"
type SortOrder = "descending" | "ascending"

type DocumentsOverviewPageState = {
	sortOrder: SortOrder
	sortBy: SortableKey
	filterStatus: "all" | DocumentStatus
}
type RouteProps = {
	vehicleId: string
	categoryId: DocumentCategory
}

// @ts-ignore
@withRouter @observer
export class DocumentsOverviewPage extends React.Component<any,
	DocumentsOverviewPageState> {

	state: DocumentsOverviewPageState = {
		sortBy      : "indicator",
		sortOrder   : "ascending",
		filterStatus: "all"
	}

	setSort = ( sortBy: SortableKey ) => {
		return () => {
			if ( this.state.sortBy === sortBy ) {
				this.setState( {
					sortOrder:
						this.state.sortOrder === "ascending" ? "descending" : "ascending"
				} )
			} else this.setState( {sortBy, sortOrder: "descending"} )
		}
	}
	setFilter = ( filterStatus: "all" | DocumentStatus ) => {
		return () => {
			this.setState( {filterStatus} )
		}
	}

	render() {
		const {vehicleId, categoryId}: RouteProps = this.props.match.params
		const {filterStatus = "All", sortBy = "indicator", sortOrder} = this.state
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		if ( !vehicle ) return <div className="">Kein Fahrzeug gewählt</div>


		const categoryName = categoryPlural[categoryId]

		const files = store.account.files.files

		const flip = sortOrder === "ascending" ? 1 : -1
		let documents = files
			.filter( doc => !categoryId || doc.categoryId === categoryId )
			.filter( doc => doc.vehicleId === vehicleId )
			.filter( doc => filterStatus === "all" || doc.status === filterStatus )
			.map( ( doc, indicator ) => {
				return {...doc, indicator: indicator + 1}
			} )
			.sort( ( docA, docB ) => {
				const dA = "" + docA[sortBy]
				const dB = "" + docB[sortBy]
				const ret = dA.localeCompare( dB, "de", {numeric: true} )
				return ret * flip
			} )

		const documentsInTrash = documents.filter( doc => doc.status === "trash" )
		documents = documents.filter( doc => doc.status !== "trash" )
		const thClassName = ( key: SortableKey ) => {
			const {sortBy, sortOrder} = this.state
			return (key === sortBy && sortOrder + " sorted") || ""
		}
		const afterFileUpload = ( fileId: string ) =>
			this.props.history.push( `${vehicle.basePath}/documents/${categoryId || ""}/${fileId}?from=${categoryId || ""}` )

		return (
			<div className="DocumentsOverviewPage pa4">
				<PageHeaderContext title={`${vehicle.vehiclePlate} / ${categoryName}`}>
					<UploadFile
						vehicleId={vehicleId}
						categoryId={categoryId}
						afterFileUpload={afterFileUpload}
					/>
				</PageHeaderContext>
				<div className={"mw9 w-100 center"}>

					<div className="flex">
						<div className="document-sidebar mr4">
							<Menu vertical>
								<Menu.Item
									key={"ALLE"}
									as={NavLink}
									exact
									to={`${vehicle.basePath}/documents/`}>Alle</Menu.Item>
								{Object.entries( categoryPlural ).map( ( [catId, catName] ) => (
									<Menu.Item
										key={catId}
										as={NavLink}
										to={`${vehicle.basePath}/documents/${catId}/`}
									>
										{catName}
									</Menu.Item>
								) )}
							</Menu>
							<Menu className="mini three item">
								<Menu.Item
									active={filterStatus === "all"}
									onClick={this.setFilter( "all" )}
								>
									Alle
								</Menu.Item>
								<Menu.Item
									active={filterStatus === "default"}
									onClick={this.setFilter( "default" )}
								>
									Fertig
								</Menu.Item>
								<Menu.Item
									active={filterStatus === "draft"}
									onClick={this.setFilter( "draft" )}
								>
									Entwurf
								</Menu.Item>
							</Menu>
						</div>

						<div className="document-content flex-grow-1">
							<Table celled sortable selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell
											className={thClassName( "indicator" )}
											onClick={this.setSort( "indicator" )}
										>
											#
										</Table.HeaderCell>
										<Table.HeaderCell
											className={thClassName( "status" )}
											onClick={this.setSort( "status" )}
										>
											Status
										</Table.HeaderCell>
										<Table.HeaderCell
											className={thClassName( "name" )}
											onClick={this.setSort( "name" )}
										>
											Titel
										</Table.HeaderCell>
										<Table.HeaderCell
											className={thClassName( "created" )}
											onClick={this.setSort( "created" )}
										>
											Datum
										</Table.HeaderCell>
										<Table.HeaderCell
											className={thClassName( "size" )}
											onClick={this.setSort( "size" )}
										>
											Größe
										</Table.HeaderCell>
										{!categoryId && <Table.HeaderCell
											className={thClassName( "size" )}
											onClick={this.setSort( "size" )}
										>
											Kategorie
										</Table.HeaderCell>}
										{/* <Table.HeaderCell>Kategorie</Table.HeaderCell> */}
										{/* <Table.HeaderCell>Fahrzeug</Table.HeaderCell> */}
										{/* <Table.HeaderCell>Fahrer?</Table.HeaderCell> */}
										{/* <Table.HeaderCell>Betrag?</Table.HeaderCell> */}
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{documents.map( ( doc, i ) => (
										<Table.Row
											key={i}
											style={{cursor: "pointer"}}
											onClick={() => {
												this.props.history.push(
													`${vehicle.basePath}/documents/${doc.categoryId}/${doc.fileId}?from=${categoryId || ""}`
												)
											}}
										>
											<Table.Cell>{doc.indicator}</Table.Cell>
											<Table.Cell>{statusTerms[doc.status] || "Neu"}</Table.Cell>
											<Table.Cell>{decodeURIComponent( doc.name ).substr( 0, 45 )}</Table.Cell>
											<Table.Cell>
												{moment( doc.created ).format( "DD.MM.YYYY HH:mm" )}
											</Table.Cell>
											<Table.Cell>{doc.filesize}</Table.Cell>
											{!categoryId && <Table.Cell>{categorySingular[doc.categoryId]}</Table.Cell>}
										</Table.Row>
									) )}


								</Table.Body>
							</Table>

							{(documentsInTrash.length > 0) && <React.Fragment>
								<h3>Papierkorb</h3>
								<Table basic={"very"}>
									<Table.Body>

										{documentsInTrash.map( ( doc, i ) => (
											<Table.Row
												key={"trash" + i}
												style={{cursor: "pointer"}}
												onClick={() => {
													this.props.history.push(
														`${vehicle.basePath}/documents/${doc.categoryId}/${doc.fileId}?from=${categoryId || ""}`
													)
												}}
											>
												<Table.Cell>{doc.indicator}</Table.Cell>
												<Table.Cell>{statusTerms[doc.status] || "Neu"}</Table.Cell>
												<Table.Cell>{decodeURIComponent( doc.name ).substr( 0, 45 )}</Table.Cell>
												<Table.Cell>
													{moment( doc.created ).format( "DD.MM.YYYY HH:mm" )}
												</Table.Cell>
												<Table.Cell>{doc.filesize}</Table.Cell>
											</Table.Row>
										) )}
									</Table.Body>
								</Table>
							</React.Fragment>
							}


						</div>
					</div>
					{/*
					<Button
						circular
						icon="close"
						as={Link}
						to={{
							pathname: "/map",
							search: `?deviceId=${vehicleId}`
						}}
						floated="left"
					/> */}

					{/* <UploadFile
						vehicleId={`${vehicleId}`}
						meta={{
							vehicleId: `${vehicleId}`
						}}
					/> */}
				</div>
			</div>
		)
	}
}
