import React, {Component} from "react"

class EmptyLayout extends Component {
  render() {
    return (
      <div className="EmptyLayout">
        {this.props.children}
      </div>
    )
  }
}

export default EmptyLayout
