import React, { Component } from "react"
import GoogleMapReact from "google-map-react"
import store from "store/store"
import styles from "./styles.js"
import "./SimpleMap.css"

const { styleDefault } = styles

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
if (!GOOGLE_API_KEY) {
	console.error("NO Google API KEY in ENVIRONMENT")
}

export default class SimpleMap extends Component {

	static defaultProps = {
		center: {
			lat: 51.905924,
			lng: 10.778350
		},
		zoom:   7,
		style:  {
			height: "340px",
			width:  "100%"
		}
	}

	getMapBounds = (map, maps, locations) => {
		const bounds = new maps.LatLngBounds()
		if (locations.length === 0) return false
		locations.forEach((location) => {
			if (location.lat && location.lng) {
				bounds.extend(
					new maps.LatLng(location.lat, location.lng)
				)
			}
		})
		return bounds
	}

	automaticallyCalculateBounds() {
		const {
						map,
						maps
					} = this.google
		if (this.props.disableAutoBounds) return

		let locations = store.account.device.list
			.map(d => ({
				lat: d.lat,
				lng: d.lng
			}))

		const bounds = this.getMapBounds(map, maps, locations)
		bounds && map.fitBounds(bounds)
		// TOO CLOSE B/C BOUNDS ARE REALLY TIGHT => ZOOM OUT A BIT TO ALLOW MINIMAL OVERVIEW (ANYTHING HIGHER THAN 14 is starting to get really close
		if (map.getZoom() > 18) {
			map.setZoom(10)
		}

	}

	handleGoogleMapApi = (google) => {
		this.google = google
		this.automaticallyCalculateBounds()

		// Load the map from the last zoomed to state -> Doesn't follow moving vehicles
		if (this.props.rememberMapState) {
			google.map.addListener("bounds_changed", () => {
				const {
								zoom,
								center
							}      = google.map
				const props  = {
					zoom:   zoom,
					center: center.toJSON()
				}
				// console.log( "REMEMM", {props} )
				const slotId = `defaultMapProps-${this.props.rememberMapState}`
				window.localStorage.setItem(slotId, JSON.stringify(props))
			})
		}
		store.setGoogle(google)
		if (this.props.onReady) {
			this.props.onReady(google)
		}

		if (this.props.onGoogleApiLoaded) {
			this.props.onGoogleApiLoaded(google)
		}

	}

	render() {
		let {
					children,
					style,
					className = "",
					...props
				} = this.props

		if (props.rememberMapState) {
			const slotId   = `defaultMapProps-${props.rememberMapState}`
			const mapProps = JSON.parse(window.localStorage.getItem(slotId) || "{}")
			props          = { ...props, ...mapProps }
		}
		// const childrenWithLocation = _.filter(children, child => {
		//   return child && child.props &&  child.props.lat && child.props.lng
		// })

		const childrenWithLocation = React.Children.map(children, child => {
			if (child && child.props && child.props.lat && child.props.lng) {
				return child
			}
			return null
		})
		// console.log( "SIMPLE MAP", props )
		return (
			<div className={`SimpleMap ${className}`} style={style}>
				<GoogleMapReact
					yesIWantToUseGoogleMapApiInternals
					key={"gmr"}
					className={"SimpleMap-Map"}
					options={{
						disableDefaultUI:  true,
						zoomControl:       false,
						mapTypeControl:    true,
						scaleControl:      true,
						streetViewControl: false,
						rotateControl:     false,
						fullscreenControl: false,
						styles:            styleDefault
					}}
					bootstrapURLKeys={{
						key:       GOOGLE_API_KEY,
						region:    "de",
						language:  "de",
						libraries: ["places"]
					}}
					{...props}
					onGoogleApiLoaded={this.handleGoogleMapApi}

				>
					{childrenWithLocation}
				</GoogleMapReact>
			</div>
		)
	}
}

export const createHtmlMarker = ({
	google,
	map = google.map,
	visible = true,
	lat = null,
	lng = null,
	html,
	className = "",
	zIndex = null,
	angle = 0
}) => {
	class HTMLMapMarker extends google.maps.OverlayView {
		constructor() {
			super({
							visible,
							lat,
							lng
						})
			if (lat && lng) {
				this.latlng = new google.maps.LatLng(lat, lng)
			}

			this.div                  = document.createElement("div")
			this.div.classList.value  = className
			this.div.style.position   = "absolute"
			this.div.style.transform  = `rotate(${angle}deg)`
			this.div.style.visibility = visible ? "visible" : "hidden"
			this.div.innerHTML        = html
			this.divMarker            = this.div.querySelector(".Marker")
			this.divText              = this.div.querySelector(".Text")
			this.divSpeed             = this.div.querySelector(".Text .Speed")
			this.divPlate             = this.div.querySelector(".Text .Plate")
			if (zIndex !== null) {
				this.div.style.zIndex = zIndex
			}

			this.divSetUp = false
			this.setMap(map)
		}

		addClass(className) {
			this.div.classList.add(className)
		}

		removeClass(className) {
			this.div.classList.remove(className)
		}

		toggleClass(className, force) {
			if (force === undefined) {
				this.div.classList.toggle(className)
			}
			else {
				this.div.classList.toggle(className, force)
			}
		}

		setRotation(angle) {
			if (angle || angle === 0) {
				this.divMarker.style.transform = `rotate(${angle}deg)`
			}
			else {
				this.divMarker.style.transform = ``
			}
		}

		setPosition({
			lat,
			lng,
			speed
		}) {
			this.latlng = new google.maps.LatLng(lat, lng)
			// console.log( { speed } )
			if (speed <= 0) {
				this.divSpeed.style.display = "none"
			}
			else {
				this.divSpeed.style.display = "inline-block"
			}
			this.divSpeed.innerHTML = `${Math.round(speed)} km/h`
			this.draw()
		}

		setVisible(visible) {
			this.div.style.visibility = visible ? "visible" : "hidden"
		}

		setUpDiv() {
			google.maps.event.addDomListener(this.div, "click", event => {
				google.maps.event.trigger(this, "click", event)
			})

			const panes = this.getPanes()
			panes.overlayMouseTarget.appendChild(this.div)

			this.divSetUp = true
		}

		positionDiv() {
			if (!this.latlng) {
				return
			}
			const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
			if (point) {
				this.div.style.left = `${point.x.toFixed(1)}px`
				this.div.style.top  = `${point.y.toFixed(1)}px`
			}
		}

		draw() {
			if (!this.divSetUp) this.setUpDiv()
			this.positionDiv()
		}

		remove() {
			if (this.div) {
				this.div.parentNode.removeChild(this.div)
				this.div = null
			}
		}

		getPosition() {
			return this.latlng
		}

		getDraggable() {
			return false
		}
	}

	return new HTMLMapMarker()
}