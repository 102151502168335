import * as React from "react";
import {Modal} from "semantic-ui-react"
import {Redirect} from "react-router-dom"
import {Container, Form, Segment, Header, Input, Button} from "semantic-ui-react"
import {toast} from "react-toastify"
import http from "../service/http"

import "./RegisterDemoModal.css"
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon";


/**
 * Displays a Modal if user did not yet enter his email (localstorage). Redirects to /map
 */
export default class RegisterDemoModal extends React.Component<any, any> {

    state = {
        open: true
    }

    handleClose = () => {
        this.props.history.push("/map")
    }

    componentDidMount() {
        const open = localStorage.getItem("OPEN_REQUEST_MODAL") !== "NO"
        this.setState({open})
    }

    render() {
        return <div className="">
            {!this.state.open && <Redirect to={"/map"}/>}
            <Modal size="fullscreen" open={this.state.open} basic dimmer="inverted">
                <MarketingForm onClose={this.handleClose}/>
            </Modal>
        </div>
    }
}

class MarketingForm extends React.Component<any, any> {

    state ={
        loading:false
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({loading: true})
        const data = new FormData(event.target);
        let email = String(data.get("email"))
        let name = String(data.get("name"))
        // console.log.log("SUBMIT", {email, name})
        http.post("/api/demo", {email, name})
            .then(res => {
                const message = `${res.data.text} ${name}`
                this.setState({loading: false})
                toast.success(message)
                localStorage.setItem("OPEN_REQUEST_MODAL", "NO")
                this.props.onClose()
            })
            .catch(err => {
                this.setState({loading: false})
                // console.log.error(err)
            })
    }

    render() {
        return (
            <div className="RegisterDemoModal">
                <div className="mw6 center animate fadeIn delay-500">
                    <Container>

                        <Segment>
                            <div className="absolute right-1 top-1 tr">
                                {/*TODO: Visuell ausblenden*/}
                                <div className="o-0"><Button icon={<Icon icon={faTimes}/>} onClick={this.props.onClose}/></div>
                            </div>

                            <div className="bottom-flex">
                                <div className="pa3 pa4-ns ">
                                    <Header inverted textAlign="center">Mit E-Mail und Namen weiter</Header>
                                    <Form inverted onSubmit={this.handleSubmit}>
                                        <Form.Field>
                                            <label htmlFor="form-name"><span className="white">Ihr Name</span></label>
                                            <input id="form-name" name="name" placeholder='Name'/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label htmlFor="form-email"><span className="white">E-Mail</span></label>
                                            <input id="form-email"
                                                   type="email"
                                                   name="email" placeholder='E-Mail'/>
                                        </Form.Field>

                                        <div className="pt2">
                                            <Button
                                                loading={this.state.loading}
                                                circular fluid primary type='submit'>WEITER</Button>
                                        </div>
                                    </Form>

                                </div>
                            </div>

                        </Segment>

                    </Container>
                </div>
            </div>
        )
    }
}

