import axios from "axios"
import qs from "qs"
import {action} from "mobx";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080"

class HTTP {

    constructor() {
        this.hydrateToken()
    }

    hydrateToken() {
        let token = localStorage.getItem("ACCESS_TOKEN")
        if (token) this.setToken(token)
        let refreshToken = localStorage.getItem("REFRESH_TOKEN")
        if (refreshToken) this.setRefreshToken(refreshToken)
    }

    headers: { Authorization?: string } = {}

    setHeader(headers) {
        this.headers = headers
    }

    setToken(token) {
        // console.log("HTTP:NEW:TOKEN")
        this.headers = this.headers || {}
        this.headers.Authorization = `Bearer ${token}`
        // localStorage.setItem("AUTH_TOKEN", token)
    }

    setRefreshToken(token) {

        // console.log("HTTP:NEW:REFRESH")
        if (!token) return
        localStorage.setItem("REFRESH_TOKEN", token)
    }

    post(url: string, data: any, headers = {}) {

        return axios({url: BASE_URL + url, data, method: "POST", headers: Object.assign(headers, this.headers)})
            .then(this.handleTokens)
            .catch((error) => {
                console.info("error", error, error.response)
                throw {error: error.response.data.error}
            })
    }

    put(url: string, data: any, headers = {}) {

        return axios({url: BASE_URL + url, data, method: "PUT", headers: Object.assign(headers, this.headers)})
            .then(this.handleTokens)
            .catch((error) => {
                console.info("error", error, error.response)
                throw {error: error.response.data.error}
            })
    }

    delete(url: string, data: any) {

        return axios({url: BASE_URL + url, data, method: "DELETE", headers: this.headers})
            .then(this.handleTokens)
            .catch((error) => {
                console.info("error", error, error.response)
                throw {error: error.response.data.error}
            })
    }

    get(url: string, data: any = {}, headers = {}) {
        let dataUrl = `${url}?${qs.stringify(data)}`
        console.log("GET", dataUrl)
        return axios({url: BASE_URL + dataUrl, data, method: "GET", headers: Object.assign(headers, this.headers)})
            .then(this.handleTokens)
            .catch(error => {
                console.error("error", dataUrl, error, error.response)
                throw new Error(error)
            })
    }

    handleTokens = (response) => {
        if (response && response.data && response.data.token) {
            response.data.token.accessToken && this.setToken(response.data.token.accessToken)
            response.data.token.refreshToken && this.setRefreshToken(response.data.token.refreshToken)
        }
        return response
    }

    @action
    refreshToken() {
        let refreshToken = localStorage.getItem("REFRESH_TOKEN")
        if (refreshToken) {
            http.post("/api/user/token", {refreshToken})
        } else {
            console.warn("Warning: Can't refresh access tokens")
        }
    }
}


const http = new HTTP()
window["http"] = http
export default http