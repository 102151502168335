import * as React from "react";
import http from "service/http"
import {Redirect} from "react-router";
import store from "../store/store";
import {Spinner} from "component/Spinner/Spinner.js";
import {Link} from "react-router-dom";
import * as queryString from "querystring";

export default class LoginOncePage extends React.Component<any, any> {

    state = {
        error: false,
        loading: true,
        hasToken: false,
        success: false,
    }

    async componentDidMount() {
        const {hash} = this.props.match.params

        if (hash) {
            try {
                await store.account.logout()
                let res = await http.get(`/api/mfr/login/once/${hash}`)
                let token = res.data.token.accessToken
                if (token) {
                    this.setState({hasToken: true})
                    await store.account.loginWithToken(token)
                    this.setState({success: true})
                } else {
                    this.setState({error: true, loading: false, success: false})
                }
            } catch (error) {
                console.warn("Error w/ magic link login")
                this.setState({error: true, loading: false})
                console.warn(error)
            }

        }
    }

    render() {
        const {success, error, loading} = this.state

        const query = queryString.parse(this.props.location.search.substr(1))
        const referer = query.referer
        return <div className="LoginOncePage">
            {success && <Redirect to={referer ? `${referer}` : "/home/"}/>}
            {(!success && !error) && <Spinner/>}

            {error && <div className="flex flex-column items-center animate fadeIn vh-100 justify-center">
				<h2>Fehler bei der Anmeldung</h2>
				<div>Anmeldelinks sind einmalig für 5 Minuten gültig.</div>
				<div><Link to={"/login/"}>Zur Anmeldung</Link></div>
			</div>}

        </div>
    }
}