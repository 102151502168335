import * as React from "react"
import store from "store/store"
import VehicleSidebar from "component/Vehicle/VehicleSidebar"
import { Link, Route, Switch, withRouter } from "react-router-dom"
import "page/Map.css"
import "page/MapPage.css"
import { calcSpeedColor, Device, TRAIL_TIME_CUTOFF_MS } from "store/model/Device"
import { observer } from "mobx-react"
import SimpleMap, { createHtmlMarker } from "component/Map/SimpleMap.js"
import { observe } from "mobx"
import * as queryString from "querystring"
import parseQuery from "util/parseQuery"
import RoutePage from "page/RoutePage"
import ExportPage from "page/ExportPage"
import DriveBookPage from "page/DriveBookPage"
import DocumentsPage from "page/DocumentsPage"
import NotesPage from "page/NotesPage"
import GroupSidebar from "component/GroupSidebar"
import { Button, Modal } from "semantic-ui-react"
import VehicleForm from "component/Vehicle/VehicleForm"
import DriveBookEditorPage from "page/DriveBookEditorPage"
import Icon from "component/Icon"
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { Vehicle } from "../store/model/Vehicle"

type MapDashboardLayoutState = {
	selectedVehicleId: string,
	sidebar: boolean,
	mapProps: any,
	google: any
}


function Item({ children }) {
	return <div>{ children }</div>
}

const createHeadMarkerHTML = ({ vehicle })=>{

	return `<div>
<div class="Marker">
<svg xmlns="http://www.w3.org/2000/svg" class="driving-image arrow-circle marker" width="42" height="42" fill="none" viewBox="0 0 42 42">
		<circle class="filler" cx="20.848" cy="20.848" r="14" fill="#33C300"  stroke="#fff" stroke-width="2"/>
		<path fill="#fff" d="M21.789 12.49a.694.694 0 0 0-.679-.49.667.667 0 0 0-.505.206.66.66 0 0 0-.174.284l-5.05 15.152a.713.713 0 0 0 .307.829c.284.173.64.134.876-.103l4.546-4.545 4.546 4.545a.705.705 0 0 0 .876.103c.047-.032.094-.063.134-.103.19-.19.26-.465.174-.726L21.788 12.49z"/>
	</svg>

	<div class="parking-image">
		<svg xmlns="http://www.w3.org/2000/svg" class="p-pin shadow" width="54" height="54" fill="none" viewBox="0 0 39 39">
			<path fill="#000" fill-opacity=".25" d="M34.583 25.646c-5.053 4.325-16.336 4.8-19.193 9.554-.39-6.245.625-9.378.625-13.88 0-4.5 13.33-9.848 18.568-8.15 5.237 1.698 5.053 8.15 0 12.476z"/>
		</svg>
		<svg xmlns="http://www.w3.org/2000/svg" class="p-pin marker" width="54" height="54" fill="none" viewBox="0 0 39 39">
			<path class="filler" fill="#0355F4" d="M26.935 15.5c0 7.068-7.935 12.5-11.549 19.745C11.5 28 3.838 22.568 3.838 15.5c0-7.068 5.17-12.251 11.548-12.251 6.379 0 11.549 5.183 11.549 12.251z"  stroke="#fff" stroke-width="2" />
			<path class="pin-content-parking" fill="#fff" d="M12.477 20.515v-9.074h3.51c.442 0 .836.07 1.183.208.347.13.637.32.87.572.244.251.426.555.547.91.121.355.182.754.182 1.196 0 .442-.06.84-.182 1.196a2.41 2.41 0 0 1-1.417 1.495c-.347.13-.741.195-1.183.195H14.44v3.302h-1.963zm1.963-4.875h1.053c.46 0 .776-.087.949-.26.182-.173.273-.46.273-.858v-.39c0-.399-.091-.685-.273-.858-.173-.173-.49-.26-.95-.26H14.44v2.626z"/>
		</svg>

	</div>
</div>
<div class="Text Text-right">
	<div class="Plate">${ vehicle.vehiclePlate } <span class="Speed pl1 b">14 km/h</span></div>
</div>
</div>`
}


//@ts-ignore
@withRouter @observer
class MapDashboardLayout extends React.Component<any,
	MapDashboardLayoutState> {
	endObserver: any

	state: MapDashboardLayoutState = {
		sidebar: true,
		selectedVehicleId: "",
		mapProps: {},
		google: null
	}

	constructor(props) {
		super(props)
		// this seems hacky. don't know why showSidebar is handled
		// globally in store.app without vehicleId ref

		this.endObserver = observe(store.app, "showSidebar", change=>{
			if ( change.oldValue === true && change.newValue === false )
				this.selectVehicle(this.state.selectedVehicleId)
		})
	}

	componentDidMount() {
		const query = parseQuery(this.props) // context/query from router
		if ( query.vehicleId ) {
			this.selectVehicle(`${ query.vehicleId }`)
		}
	}

	componentWillUnmount = ()=>{
		this.endObserver()
	}

	selectVehicle = (selectedVehicleId: string)=>{
		const query = parseQuery(this.props)
		if ( selectedVehicleId === this.state.selectedVehicleId )
			selectedVehicleId = ""

		store.app.showSidebar = selectedVehicleId !== ""
		this.setState({ selectedVehicleId })
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: queryString.stringify({ ...query, vehicleId: selectedVehicleId })
		})
	}

	render() {
		const query = parseQuery(this.props) // context/query from router
		const selectedVehicleId = query.vehicleId
		const showSidebar = store.app.showSidebar
		const vehicle = store.account.vehicle.byId.get(`${ selectedVehicleId }`)
		return (
			<div className="MapPage MapDashboard">
				<div className="flex flex-row">
					<div className="fullscreen-map-container flex flex-row bg-silver overflow-hidden">
						<GroupSidebar />

						<div className="relative flex flex-row w-100 ">
							<React.Fragment>
								<Switch>
									<Route path={ "/map/:vehicleId" }>
										<VehicleSidebar />
										<Switch>
											<Route path="/map/:vehicleId/export" component={ ExportPage } />
											<Route path="/map/:vehicleId/route" component={ RoutePage } />
											<Route path="/map/:vehicleId/report" component={ DriveBookPage } />
											<Route path="/map/:vehicleId/documents" component={ DocumentsPage } />
											<Route path="/map/:vehicleId/fahrtenbuch/edit/:tripId?" component={ DriveBookEditorPage } />
											<Route path="/map/:vehicleId/fahrtenbuch" component={ DriveBookPage } />
											<Route path="/map/:vehicleId/notes" component={ NotesPage } />
											<Route path="/map/:vehicleId/edit" component={ EditVehicleModal } />

											{/*SHOW MAP IN BACKGROUND WHEN A VEHICLE IS SELECTED*/ }
											<Route path="/" component={ MapGlobal } />
										</Switch>
									</Route>
									{/*SHOW MAP IF NOTHING ELSE FITS*/ }
									<Route path="/">
										<VehicleSidebar />
										<MapGlobal />
									</Route>
								</Switch>
							</React.Fragment>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export { MapDashboardLayout }


function Toolbar({}) {
	const [ isActive, setActive ] = React.useState(false)
	//TODO: Markers don't show up on map :/
	return null
	return <div className="Toolbar flex flex-row items-center">
		<Button size="tiny"
						className={ `${ isActive && "icon" }` }
						onClick={ ()=>{
							setActive(!isActive)
						}
						}>
			{ !isActive && "Filter" }
			{ isActive && <Icon icon={ faTimes } /> }
		</Button>

		{ isActive && <div className={ "animated fadeInLeft" }>
			<Button size="tiny"><Icon className={ "mr1" } icon={ faCheckCircle } /> Fahrzeuge</Button>
			<Button size="tiny"><Icon className={ "mr1" } icon={ faCheckCircle } /> Auslöser</Button>
		</div> }

	</div>
}


function WithSidebar(props) {
	const { component, ...rest } = props
	const Comp = component
	return <React.Fragment>
		<VehicleSidebar />
		<Switch>
			{/*<Route path="/map/:vehicleId/export" component={withSidebar( vehicle, ExportPage )} />*/ }
			{/*<Route path="/map/:vehicleId/report" component={withSidebar( vehicle, DriveBookPage )} />*/ }
			{/*<Route path="/map/:vehicleId/documents" component={withSidebar( vehicle, DocumentsPage )} />*/ }
			{/*<Route path="/map/:vehicleId/notes" component={withSidebar( vehicle, NotesPage )} />*/ }
			<Route path="/map/:vehicleId/view/edit" component={ EditVehicleModal } />
		</Switch>
	</React.Fragment>
}

function EditVehicleModal(props) {
	const query = parseQuery(props) // context/query from router
	const selectedVehicleId = query.vehicleId
	const vehicle = store.account.vehicle.byId.get(`${ selectedVehicleId }`)
	if ( !vehicle ) return null
	return <Modal open>
		<Modal.Header>
			<div className="flex flex-row items-center justify-between">
				<div>
					<div> { vehicle.title }{ " " }{ vehicle.vehiclePlate }</div>
					<div className="fw2 f5 black-60">{ vehicle.vehicleManufacturer } </div>
				</div>
				<Button as={ Link } to={ "/vehicles/" } basic circular icon={ <Icon icon={ faTimes } /> } />
			</div>
		</Modal.Header>
		<Modal.Content>
			<VehicleForm vehicle={ vehicle } to={ `/map/${ vehicle._id }/` } />
			{/*<VehicleDataForm id={vehicleId}/>*/ }
		</Modal.Content>
	</Modal>
}


//@ts-ignore
@withRouter @observer
class MapGlobal extends React.Component<any,
	MapDashboardLayoutState> {
	endObserver: any

	state: MapDashboardLayoutState = {
		sidebar: true,
		selectedVehicleId: "",
		mapProps: {},
		google: null
	}

	constructor(props) {
		super(props)
		// this seems hacky. don't know why showSidebar is handled
		// globally in store.app without vehicleId ref

		this.endObserver = observe(store.app, "showSidebar", change=>{
			if ( change.oldValue === true && change.newValue === false )
				this.selectVehicle(this.state.selectedVehicleId)
		})
	}

	componentWillUnmount = ()=>{
		this.endObserver()
	}

	componentDidMount() {
		const query = parseQuery(this.props) // context/query from router
		if ( query.vehicleId ) {
			this.selectVehicle(`${ query.vehicleId }`)
		}
	}

	selectVehicle = (selectedVehicleId: string)=>{
		if ( selectedVehicleId === this.state.selectedVehicleId )
			selectedVehicleId = ""

		store.app.showSidebar = selectedVehicleId !== ""
		this.setState({ selectedVehicleId })
		this.props.history.replace({
			pathname: this.props.location.pathname,
			search: queryString.stringify({ vehicleId: selectedVehicleId })
		})
	}

	mapReady = google=>{
		this.setState({ google: google })
		google.map.addListener("click", ()=>{
			this.selectVehicle("")
		})
	}

	render() {
		const query = parseQuery(this.props) // context/query from router
		const { mapProps, google } = this.state
		const selectedVehicleId = query.vehicleId
		const vehicles = store.account.vehicle.vehicles

		const isMapPath = this.props.location.pathname.includes(( "/map/" ))
		const vehicle = store.account.vehicle.byId.get(`${ selectedVehicleId }`)

		return (
			<React.Fragment>
				<div className="relative" style={ { height: "100%", width: "100%" } }>
					<div className="absolute pa2 pa3-ns top-0 left-0 z-5">
						<Toolbar />
					</div>
					<SimpleMap
						style={ { height: "100%", width: "100%" } }
						onReady={ this.mapReady }
						{ ...mapProps }
					/>
					{ google && vehicles && vehicles.map((vehicle, i)=>{
						const deviceId = vehicle.deviceId
						const vehicleId = vehicle._id
						const device = store.account.device.byId.get(deviceId)
						const selected = vehicleId === selectedVehicleId
						if ( !device ) return null
						if ( !device.lat ) return null
						if ( !device.lng ) return null
						return (
							<Trail
								key={ i }
								device={ device }
								google={ google }
								vehicleId={ vehicleId }
								onClick={ this.selectVehicle }
								selected={ selected }
							/>
						)
					}) }
				</div>
			</React.Fragment>
		)
	}
}

export { MapGlobal }
type TrailProps = {
	google: any
	device: Device
	vehicleId: string
	onClick: (string)=>void
	selected?: boolean
}

@observer
class Trail extends React.Component<TrailProps, any> {
	google: any
	map: any
	device: any
	vehicleId: string
	vehicle: Vehicle | undefined
	onClick: (string)=>void

	privacyMode?: string
	// lat: number
	// lng: number
	// speed: number
	// angle: number
	selected: boolean
	headMarker: any

	oldSegments: Array<any>
	currentSegment: any
	currentSegmentStart: any

	cleanerInterval: any

	endHistoryObserver?: ()=>void

	constructor(props: TrailProps) {
		super(props)

		const { google, device, vehicleId, onClick, selected = false } = props

		this.google = google
		this.map = google.map
		this.device = device
		this.vehicleId = vehicleId
		this.vehicle = store.account.vehicle.byId.get(vehicleId)
		this.onClick = onClick
		this.selected = selected
		this.oldSegments = []

		this.initTrail(device.animated, device.shortHistory)
		this.updatePrivacyMode()
	}

	drawHistory(history: Array<any>) {
		// remove any history we drew before
		this.oldSegments.forEach(segment=>{
			segment && segment.setMap(null)
		})

		// put together old segments
		this.oldSegments = history
			.map((thisPing, i, allPings)=>{
				// skip the first (i.e. the head)
				if ( i === 0 ) return
				const nextPing = allPings[ i - 1 ]

				const segment = new this.google.maps.Polyline({
					path: [
						{ lat: nextPing.lat, lng: nextPing.lng },
						{ lat: thisPing.lat, lng: thisPing.lng }
					],
					geodesic: true,
					strokeColor: calcSpeedColor(thisPing.speed),
					// strokeColor: 'red',
					strokeWeight: 5,
					zIndex: 50,
					map: this.map,
					drawTime: Date.now()
				})
				// segment.addListener("click", ev => {
				// console.log(`${this.vehicleId} segment`, segment)
				// })
				return segment
			})
			.filter(segment=>!!segment)
	}

	initTrail(head: any, history: Array<any>) {
		this.cleanup()

		if ( !head )
			throw "missing param <head>"

		if ( history.length >= 1 ) {
			this.drawHistory(history.slice(1))
		}

		if ( history.length === 0 ) {
			// in case we haven't got a history for the device yet,
			// wait for it to be pushed from the server.
			// TODO: there is a race condition: when the first ping
			// hits before the history event, this listener will have
			// stopped and the longer history be ignored until the
			// mother component is mounted again.
			this.endHistoryObserver = observe(this.device, "shortHistory", change=>{
				if ( !change || !change.newValue )
					return
				this.drawHistory(change.newValue)
				this.endHistoryObserver && this.endHistoryObserver()
			})
		}
		const lastPoint = history.length && history[ 1 ]
		if ( lastPoint ) {
			const { lat, lng, speed } = lastPoint
			const initSegment = { lat, lng, ratio: 0, speed }
			this.updateCurrentSegment(initSegment)
		}

		this.headMarker = createHtmlMarker({
			google: this.google,
			html: createHeadMarkerHTML({ vehicle: this.vehicle }),
			className: `AutoDevice `,
			visible: false
		})

		this.headMarker.addListener("click", ev=>{
			ev.stopPropagation()
			// console.log(this)
			this.onClick(`${ this.vehicleId }`)
		})

		this.cleanerInterval = setInterval(
			this.removeOldSegments.bind(this),
			3 * 1000
		)
	}

	cleanup() {
		clearInterval(this.cleanerInterval)
		// remove anything we already put on the map
		this.headMarker && this.headMarker.setMap(null)
		this.currentSegment && this.currentSegment.setMap(null)
		this.oldSegments = ( this.oldSegments || [] ).filter(segment=>{
			if ( segment ) segment.setMap(null)
			return false
		})
		delete this.cleanerInterval
		delete this.headMarker
		delete this.currentSegment
		this.oldSegments = []
	}

	removeOldSegments() {
		// console.log(`${this.vehicleId} removing old segments`, this.oldSegments.length)
		if ( this.oldSegments.length === 0 ) return
		const cutoffTime = Date.now() - TRAIL_TIME_CUTOFF_MS
		this.oldSegments = this.oldSegments.filter((segment, i)=>{
			const remove = segment && segment.drawTime < cutoffTime
			if ( remove ) segment.setMap(null)

			const keep = segment && segment.drawTime > cutoffTime
			// console.log({segment, remove, keep})
			return keep
		})
	}

	updateCurrentSegment({ lat, lng, ratio, speed }) {
		if ( ratio === 1 && this.currentSegment ) {
			// finish currentSegment
			// this.currentSegment.setOptions({ strokeColor: "blue" })

			this.currentSegment.setPath([ this.currentSegmentStart, { lat, lng } ])
			this.oldSegments.unshift(this.currentSegment)
			this.currentSegment = null
			// console.log(`${this.vehicleId} pushed current segment on stack`, this.oldSegments.length)
		} else if ( ratio === 0 ) {
			// begin new curretSegment
			this.currentSegmentStart = { lat, lng, ratio }
			this.currentSegment = new this.google.maps.Polyline({
				geodesic: true,
				strokeColor: calcSpeedColor(speed),
				// strokeColor: '#ffcc00aa',
				strokeWeight: 5,
				// visible: false, // dont draw this yet
				opacity: 0.3,
				zIndex: 50,
				map: this.map,
				drawTime: Date.now()
			})
		} else {
			if ( !this.currentSegment || !this.currentSegmentStart ) return
			// this.currentSegment.setVisible(true) // begin showing current segment
			if ( isNaN(lat) || isNaN(lng) ) {
				console.error(`lat/lng is NaN:`, { lat, lng, ratio, speed })
			}
			this.currentSegment.setPath([ this.currentSegmentStart, { lat, lng } ])
			// this.currentSegment.setOptions({ opacity: ratio }) // begin showing current segment
		}
	}

	updateHead({ lat, lng, angle, speed, ratio }, device) {
		let trueSpeed = speed
		const moving = speed > 1 && device.activeLast24hours
		if(!moving) trueSpeed = 0
		this.headMarker.toggleClass("driving", moving)
		this.headMarker.toggleClass("parking", !moving)
		this.headMarker.toggleClass("selected", this.props.selected)
		this.headMarker.setRotation(moving ? angle : 0)
		this.headMarker.setPosition({ lat, lng, speed: trueSpeed })
		this.headMarker.setVisible(true)
	}

	componentWillUnmount() {
		this.endHistoryObserver && this.endHistoryObserver()
		this.cleanup()
	}

	componentDidUpdate() {
		this.headMarker.toggleClass("selected", this.props.selected)
		this.updatePrivacyMode()
	}

	updatePrivacyMode() {
		const { privacyMode } = this.device
		if ( this.privacyMode !== privacyMode ) {
			// NEW PRIVACY MODE
			this.headMarker.toggleClass("eTracking", privacyMode === "eTracking")
			this.headMarker.toggleClass("ePrivate", privacyMode === "ePrivate")
		}
		this.privacyMode = privacyMode
	}

	render() {
		const headPosition = this.device.animated
		// const { ratio, lat, lng, speed, angle } = headPosition
		// console.log({ ratio, lat, lng, speed, angle }, this.currentSegmentStart)
		// console.log(`${this.vehicleId} rendering new head`, headPosition, this.currentSegment, this.currentSegmentStart)
		this.updateHead(headPosition, this.device)
		this.updateCurrentSegment(headPosition)
		return null
	}
}
