import {action, observable} from "mobx"
import moment from "moment"
import http from "service/http"
import store from "store/store"
import _ from "lodash"

export const TRAIL_TIME_CUTOFF_MS = 2 * 60 * 1000 // 2 minutes
const ASSUME_MAX_SPEED_KMH = 130 // 130 km/h

export const calcSpeedColor = ( speed, time?, latest? ) => {
	const speedHue = 205 * (1 - speed / ASSUME_MAX_SPEED_KMH)
	if ( !time || !latest ) {
		return `hsla(${speedHue}, 100%, 40%, 100%)`
	}

	const latestTime = new Date( latest || time ).valueOf()
	const ageRatio =
		(latestTime - new Date( time ).valueOf()) / TRAIL_TIME_CUTOFF_MS
	const ageLuminocity = 80 - 30 * (1 - ageRatio)
	return `hsla(${speedHue}, 100%, ${ageLuminocity}%, 100%)`
}

export interface IGoogleAddress {
	locality?: string
	country?: string
	postal_code?: string
	route?: string
	street_number?: string
}

export interface INote {
}

export type Position = {
	lat: number
	lng: number
	time: Date
	speed: number
	angle: number
}

export enum PrivacyMode {
	PRIVATE = "ePrivate",
	TRACKING = "eTracking"
}

export type AnimatedPosition = Position & { ratio: number }

export class Device {
	@observable lat: number
	@observable lng: number
	@observable deviceId: string
	@observable speed: number
	@observable angle: number
	@observable time: any
	@observable vehicleGroup: string

	@observable privacyMode: PrivacyMode = PrivacyMode.TRACKING

	lastActiveHumanized: string
	lastActiveDelta: moment.Duration

	@observable plate: string
	@observable brand: string
	@observable modelName: string
	@observable firstRegistration: string
	@observable status: string
	@observable group: string
	@observable owner: string
	@observable features: Array<string>
	@observable access: Array<string>

	activeLast2hours: boolean = false
	activeLast24hours: boolean = false
	hasActivity: boolean = false

	@observable signals: any

	@observable noteById: Map<string, INote> = new Map()
	@observable shortHistory: Array<Position>
	// shortHistory: Array<Position>

	driverName: string
	carName: string

	carId: string
	contactName: string
	@observable lastLocation: IGoogleAddress = {}

	@observable animated: AnimatedPosition
	animationStart: any
	animationTarget: any
	animationTargets: Array<any>
	animationTimeout: any

	fetchNotesThrottled: Function

	constructor( data ) {
		Object.assign( this, data )
		const {lat, lng, speed, angle, time} = data
		this.animated = {lat, lng, speed, angle, ratio: 1, time}
		this.lat = lat
		this.lng = lng
		this.speed = speed
		this.angle = angle
		this.shortHistory = []
		this.animationTargets = []


		this.fetchNotesThrottled = _.throttle( () => this.fetchNotes, {trailing:true})
	}

	/**
	 * Update from Server
	 */
	@action
	fetch() {
		return http.get( `/api/v1/device/${this.deviceId}` )
			.then( res => {
				let device = res.data.device
				this.privacyMode = device.privacyMode
				// console.log( {res}, device.privacyMode )
				return res
			} )
	}

	@action
	async getGeocodeAddress() {
		return {}
	}

	get notes() {
		return [...this.noteById.entries()].map( e => e[1] )
	}

	findTripsLastDays( days ) {
		let start = moment()
			.subtract( days, "day" )
			.startOf( "day" )
			.format( "YYYY-MM-DD" )
		let end = moment()
			.endOf( "day" )
			.format( "YYYY-MM-DD" )
		return this.findTrips( {start, end} )
	}

	findTrips( {start, end} ) {
		console.warn( "ERROR: THIS IS OBSOLETE" )
	}

	@action
	fetchNotes() {
		return http.get( `/api/v1/device/${this.deviceId}/notes` ).then( res => {
			let notes = res.data.notes
			const noteById = new Map()
			notes.forEach( note => {
				// console.log({ note, id: note.noteId })
				noteById.set( note.noteId, note )
			} )
			this.noteById = noteById
			return notes
		} )
	}

	@action
	deleteNote( noteId ) {
		return http
			.delete( `/api/v1/device/${this.deviceId}/note/${noteId}`, {} )
			.then( res => {
				// console.log("REMOVED NOTE", res)
				return res
			} )
	}

	@action
	putNote( note ) {
		return http
			.post( `/api/v1/device/${this.deviceId}/note/${note.noteId}`, note )
			.then( res => {
				// console.log("POSTED NOTE", res)
				return res
			} )
	}

	@action
	postNote( content ) {
		return http
			.post( `/api/v1/device/${this.deviceId}/note`, {
				content
			} )
			.then( res => {
				// console.log("POSTED NOTE", res)
				return res
			} )
	}

	@action
	animate( newState: Position ): any {
		const ANIMATION_INTERVAL = 80

		this.animationTargets.push( newState )

		const tween0to1 = time => {
			const start = this.animationTargets[0]
			const end = this.animationTargets[1]
			const stopsToCome = Math.max( 1, this.animationTargets.length - 1 )

			// speed up animation if there are stops lined up: if we
			// are n stops in the past, simulate n times the speed
			// i.e. if there is one stop coming up, move twice as
			// fast as realtime during this segment
			let inc = ANIMATION_INTERVAL * stopsToCome

			if ( !end ) {
				const animated = interpolatePosition( start, start, 1 )
				this.animated = animated
				clearTimeout( this.animationTimeout )
				this.animationTimeout = null
				return
			}
			const animationDuration = Math.max( 1, +new Date( end.time ) - +new Date( start.time ) )
			const ratio = Math.max( 0, Math.min( 1, time / animationDuration ) )

			// interpolate position data; this will trigger the
			// proxy stuff in the mobx store
			const animated = interpolatePosition( start, end, ratio )
			this.animated = animated


			// skip small animations
			// const distance_m = getDistance_m(start, end)
			// const speed_mps = distance_m / animationDuration
			// if (distance_m < 1 || speed_mps < 0.1) {
			// 	console.log(`fast forward through small animations`)
			// 	inc = inc * 10
			// }


			if ( ratio === 1 ) {
				this.animationTargets.shift()
				return tween0to1( 0 )
			} else {
				this.animationTimeout = setTimeout(
					tween0to1,
					ANIMATION_INTERVAL,
					time + inc
				)
			}
		}

		// if no animation is in progress, start it here
		if ( !this.animationTimeout ) tween0to1( 0 )
	}

	@action
	cancelAnimation( newAnimated ) {
		// console.log(`${this.deviceId} cancel animation (${this.animationTargets.length} in queue)`)
		clearTimeout( this.animationTimeout )
		this.animationTargets = []
		this.animationTimeout = null
		this.animated = newAnimated
	}

	@action
	setPositionHistory( history: Array<Position> ) {
		if ( history.length === 0 ) {
			return
		}

		const sortedHistory = history
			.sort( ( a, b ) => {
				return +new Date( b.time ) - +new Date( a.time )
			} )

		const endState = sortedHistory[0]
		const endStateTime = +new Date( endState.time )

		const newHistory = sortedHistory
		// .filter(ping => {
		// return endStateTime - +new Date(ping.time) < TRAIL_TIME_CUTOFF_MS
		// })

		const lastState = newHistory[1]
		if ( !lastState ) {
			this.lat = endState.lat
			this.lng = endState.lng
			this.angle = endState.angle
			this.speed = endState.speed
			this.time = endState.time
			this.shortHistory = newHistory
			this.cancelAnimation( endState )
		} else {
			this.lat = lastState.lat
			this.lng = lastState.lng
			this.angle = lastState.angle
			this.speed = lastState.speed
			this.time = lastState.time
			this.shortHistory = newHistory.slice( 2 )

			this.cancelAnimation( lastState )
			this.updateFromPing( lastState )
			// simluate the endState ping juuusst coming
			setTimeout( () => {
				this.updateFromPing( endState )
			}, 100 )
		}
	}

	@action
	updateFromPing( ping: Position ) {
		// console.log({ping})
		// var deviceId = ping.deviceId
		const history = [ping, ...this.shortHistory]
		const newHistory = history
			.sort( ( a, b ) => {
				return new Date( b.time ).valueOf() - new Date( a.time ).valueOf()
			} )
		const endState = newHistory[0] || ping
		const endStateTime = new Date( endState.time ).valueOf()

		this.shortHistory = newHistory
			.filter( ping => {
				return endStateTime - new Date( ping.time ).valueOf() < TRAIL_TIME_CUTOFF_MS
			} )

		this.lat = endState.lat
		this.lng = endState.lng
		this.angle = endState.angle
		this.speed = endState.speed
		this.time = endState.time



		this.lastActiveHumanized = moment.duration( moment( this.time ).diff( moment() ) ).humanize( true )
		this.lastActiveDelta = moment.duration( moment( this.time ).diff( moment() ) )

		this.activeLast2hours = moment().subtract( 4, "hours" ).isBefore( moment( this.time ) )
		this.activeLast24hours = moment().subtract( 24, "hours" ).isBefore( moment( this.time ) )
		this.hasActivity = this.activeLast24hours && (this.speed > 2 || this && this.signals && (this.signals.ignition !== "00" || this.signals.movement !== "00"))

		this.animate( endState )
	}
}

function getDistance_m( a, b ) {
	function deg2rad( deg ) {
		return deg * (Math.PI / 180)
	}

	var R = 6371000 // Radius of the earth in meter
	var dLat = deg2rad( b.lat - a.lat ) // deg2rad above
	var dLon = deg2rad( b.lng - a.lng )
	var D =
		Math.sin( dLat / 2 ) * Math.sin( dLat / 2 ) +
		Math.cos( deg2rad( a.lat ) ) *
		Math.cos( deg2rad( b.lat ) ) *
		Math.sin( dLon / 2 ) *
		Math.sin( dLon / 2 )

	var c = 2 * Math.atan2( Math.sqrt( D ), Math.sqrt( 1 - D ) )
	return R * c // Distance in meter
}

function interpolatePosition(
	obj1: Position,
	obj2: Position,
	ratio: number
): AnimatedPosition {
	ratio = Math.max( 0, Math.min( ratio, 1 ) )

	const ret: AnimatedPosition = {
		lat  : 0,
		lng  : 0,
		time : new Date( 0 ),
		speed: 0,
		angle: 0,
		ratio: 0
	}
	return Object.keys( ret ).reduce( ( acc, key ) => {
		let value1 = obj1[key]
		let value2 = obj2[key]

		if ( key === "time" ) {
			value1 = +new Date( value1 )
			value2 = +new Date( value2 )
			acc[key] = new Date( value1 + (value2 - value1) * ratio )
		} else if ( key === "ratio" ) {
			acc[key] = ratio
		} else if ( key === "angle" ) {
			const diff = value2 - value1
			if ( diff >= 180 ) value1 += 360
			if ( diff < -180 ) value1 -= 360
			acc[key] = value1 + (value2 - value1) * ratio
		} else {
			acc[key] = value1 + (value2 - value1) * ratio
		}
		return acc
	}, ret )
}
