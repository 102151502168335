import * as React from "react";
import {Button, Divider, Form, Input, Modal} from "semantic-ui-react";
import {FormVehicleNew} from "./page/VehiclesPageNew";
import {Link, useHistory, withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import http from "./service/http";
import {toast} from "react-toastify";
import Icon from "./component/Icon";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import store from "./store/store";

//@ts-ignore
@withRouter
@observer
export class FormVehicleNewOnboarding extends React.Component<any, any> {

    state = {
        tracker: false,
        loading: false
    }

    handleSubmit = async (event) => {

    }

    update(data) {
        const vehicleId = String(data.get("vehicleId"))
        // console.log("UPDATE", {vehicleId})
    }

    render() {
        const {loading} = this.state
        return <div className="DeviceDataForm pt3">

        </div>
    }
}

export const Onboarding = observer(() => {
    const [closed, setClosed] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [openTracker, setOpenTracker] = React.useState(false)
    const [vehicleId, setVehicleId] = React.useState(false)

    const [loading, setLoading] = React.useState(false)

    const {vehicles, ready} = store.account.vehicle
    React.useEffect(() => {
        if (ready && vehicles.length === 0) {
            !closed && setOpen(true)
        }
    }, [vehicles, ready])

    const history = useHistory()

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        const data = new FormData(event.target);
        let vehiclePlate = String(data.get("vehiclePlate"))
        let vehicleName = String(data.get("vehicleName"))
        let vehicleManufacturer = String(data.get("vehicleManufacturer"))
        let vehicleGroup = String(data.get("vehicleGroup"))
        let mileage = String(data.get("mileage"))
        let deviceId = String(data.get("deviceId"))
        http.post(`/api/v1/vehicle/`, {vehiclePlate, deviceId, vehicleName, vehicleManufacturer, vehicleGroup, mileage})
            .then(res => {
                console.log("New vehicle", res)
                setVehicleId(res.data.record._id)
                setLoading(false)
                setOpenTracker(true)
                setOpen(false)
                store.account.vehicle.addVehicle(res.data.record)
                // history.replace(`/vehicles/`)
                // this.props.onClose && this.props.onClose()
                toast.success("Fahrzeug angelegt.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
    }

    return <>
        <Modal open={openTracker}>
            <Modal.Header>
                <div className="flex flex-row justify-between">
                    <div>
                        Tracker bestellen oder mit App verknüpfen
                    </div>
                    <div>
                        <Button
                            onClick={() => setClosed(true)}
                            as={Link} to={`/map/${vehicleId}/`} basic circular icon={<Icon icon={faTimes}/>}/>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Content>

                <div className="flex flex-row justify-around pv5-ns pv3">
                   <div>
                       <div className="f4 b pb3">
                           OBD2 Tracker für Ihr Fahrzeug
                       </div>
                       <Button onClick={() => setOpenTracker(false)} as={Link} to={"/buy/"} fluid primary>Tracker bestellen</Button>
                   </div>

                    <div>
                        <div className="f4 b pb3">
                            GPS App für iPhone & Android
                        </div>
                        <Button fluid primary as={Link} to={`/map/vehicle/${vehicleId}/connection`}>App verknüpfen</Button>
                    </div>

                </div>

            </Modal.Content>
        </Modal>
        <Modal open={open} as={Form} onSubmit={handleSubmit}>
            <Modal.Header>Legen Sie Ihr erstes Fahrzeug an</Modal.Header>
            <Modal.Content>
                <Form.Field>
                    <label htmlFor="form-name">Kennzeichen</label>
                    <Input id="form-vehiclePlate"
                           name="vehiclePlate"
                           placeholder='Kennzeichen'/>
                </Form.Field>

                <Form.Field>
                    <label htmlFor="form-name"> Name</label>
                    <Input id="form-name"
                           name="vehicleName"
                           placeholder='A8, A-Klasse, Sprinter'/>
                </Form.Field>

                {/*<Form.Field>*/}
                {/*    <label htmlFor="form-manufacturer">Marke</label>*/}
                {/*    <Input id="form-manufacturer"*/}
                {/*           name="vehicleManufacturer"*/}
                {/*           placeholder='Audi, Mercedes, BMW'/>*/}
                {/*</Form.Field>*/}

                <Form.Field>
                    <label htmlFor="form-manufacturer">Gruppe</label>
                    <Input id="form-manufacturer"
                           defaultValue={"Service"}
                           name="vehicleGroup"
                    />
                </Form.Field>

                {/*<Form.Field>*/}
                {/*    <label htmlFor="form-mileage">Kilometerstand (OPTIONAL)</label>*/}
                {/*    <Input id="form-mileage"*/}
                {/*           name="mileage"*/}
                {/*           placeholder='32000'*/}
                {/*           labelPosition="right"*/}
                {/*           label={"km"}*/}
                {/*    />*/}
                {/*</Form.Field>*/}

                {/*<div className="animated fadeInDown">*/}
                {/*    <Form.Field>*/}
                {/*        <Form.Input id="form-deviceId" name={"deviceId"} fluid label="Tracker IMEI  (OPTIONAL)"/>*/}
                {/*    </Form.Field>*/}
                {/*</div>*/}

            </Modal.Content>

            <Modal.Actions>
                <Button primary loading={loading} floated={"right"}>Speichern</Button>
                <Button
                    onClick={() => setOpen(false)}
                    role="button" type="button" icon={<Icon icon={faTimes}/>}
                    as={Link}
                    to={`/vehicles/`}/>
            </Modal.Actions>
        </Modal>
    </>
})