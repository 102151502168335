import * as React from "react"
import {
    Button,
    Card,
    Checkbox,
    Container,
    Divider,
    Dropdown,
    Form,
    Grid,
    Header,
    Image,
    Segment,
    Table
} from "semantic-ui-react"
import http from "../service/http";
import moment from "moment";
import {toast} from "react-toastify";
import {Redirect} from "react-router"
import {observer} from "mobx-react";

import CheckoutStore, {Product} from "store/CheckoutStore";
import "./BuyPage.css"
import FooterLegal from "../component/Footer/FooterLegal";
import store from "store/store"
const checkoutStore = new CheckoutStore()
const countOptions: Array<any> = []

let n = -1

while (n++ < 101) {
    countOptions.push({key: n, value: n, text: n})
}

const Place = ({children, style}: any) => (
    <div style={style}>{children}</div>
)

@observer
export class BuyForm extends React.Component<any, any> {

    state = {
        showWarnings: false,
        termsOk: false,
        success: false,
        useBillingAddress: true,
        useDeliveryAddress: true,
        order: {
            orderId: false
        },
        shipping: {},
        customer: {},
        billing: {},
        data: {
            email: "",
            count: 1
        }
    }

    componentDidMount() {
        checkoutStore.fixPricePerUnit = 150
        checkoutStore.calculate()
    }

    handleChange = (key, value) => {
        let data = this.state.data
        data[key] = value
        this.setState({data})
    }

    handleDataChange = (key, value) => {
        let customer = this.state.customer
        customer[key] = value
        this.setState({customer})
    }

    handleShippingChange = (key, value) => {
        let shipping = this.state.shipping
        shipping[key] = value
        this.setState({shipping})
    }

    handleInvoiceChange = (key, value) => {
        let billing = this.state.billing
        billing[key] = value
        this.setState({billing})
    }

    toggleBillingAddress = () => {
        this.setState({useBillingAddress: !this.state.useBillingAddress})
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({loading: true, showWarnings: true})
        const {termsOk, useBillingAddress, customer, billing, shipping, data} = this.state
        if (!termsOk) return toast.warn("Bitte bestätigen sie unsere AGB und Datenschutzbestimmungen")

        const body = {
            email: data.email,
            products: {
                easyDrive: checkoutStore.products.easyDrive.count,
                book: checkoutStore.products.book.count,
                map: checkoutStore.products.map.count,
            },
            termsOk,
            customer,
            billing,
            shipping: useBillingAddress ? billing : shipping
        }

        return http.post("/api/order", body)
            .then(res => {
                return res.data
            })
            .then(res => {
                this.setState({order: res.order, success: true})
                // this.props.onClose()
            })
            .catch(err => {
                console.error(err)
            })
    }

    render() {

        const {order, success, termsOk, useBillingAddress, useDeliveryAddress, billing, shipping, data} = this.state
        if (!order) return
        if (success) return <Redirect to={`/order/${order.orderId}`}/>
        return (
            <Container className="">
                <Form onSubmit={this.handleSubmit}>

                    <Grid columns={2}>
                        <Grid.Column>
                            <div className="pv4"><Image width={150} src={"/brand/flottr-brand-dark@600w.png"}/></div>


                            <Card style={{width: "100%"}}>
                                <Card.Content>
                                    <h3 className={"fw2"}>E-Mail</h3>

                                    <FormFieldStore setValue={this.handleChange} label={"E-Mail"} name={"email"}
                                                    defaultValue={store.account.user?.email}
                                                    placeholder={"E-Mail"} required/>
                                </Card.Content>
                            </Card>

                            {/*STAMMDATEN*/}
                            <Card style={{width: "100%"}}>
                                <Card.Content>
                                    <h3 className={"fw2 "}>Stammdaten</h3>

                                    <FormFieldStore setValue={this.handleDataChange} label={"Name"} name={"name"}
                                                    placeholder={"Name"}
                                                    defaultValue={store.account.user?.name}
                                                    required/>

                                    <FormFieldStore setValue={this.handleDataChange}
                                                    defaultValue={store.account.company?.title}
                                                    label={"Firma"} name={"company"}
                                                    placeholder={"Firma"} required/>

                                    {/*<Form.Field>*/}
                                    {/*    <label htmlFor="vatId">UST-ID*</label>*/}
                                    {/*    <input id="vatId" required name="vatId" placeholder='UST-ID'/>*/}
                                    {/*</Form.Field>*/}

                                    <Divider/>

                                    <FormFieldStore setValue={this.handleDataChange}
                                                    defaultValue={store.account.company?.street}
                                                    autoComplete={"billing street-address"}
                                                    label={"Straße"} name={"street"}
                                                    placeholder={"Straße"} required/>

                                    {/*<FormFieldStore setValue={this.handleDataChange} label={"Adresszusatz"}*/}
                                    {/*                name={"addition"}*/}
                                    {/*                placeholder={"Adresszusatz"}/>*/}


                                    <Form.Group widths='equal'>

                                        <FormFieldStore
                                            autoComplete={"billing postal-code"}
                                            setValue={this.handleDataChange} label={"PLZ"} name={"postalCode"}
                                            defaultValue={store.account.company?.postcode}
                                            placeholder={"PLZ"} required/>

                                        <FormFieldStore
                                            autoComplete={"billing locality"}
                                            defaultValue={store.account.company?.city}
                                            setValue={this.handleDataChange} label={"Ort"} name={"city"}
                                            placeholder={"Ort"} required/>

                                    </Form.Group>

                                    <Form.Field>
                                        <input readOnly id="country" name="country" value={"Deutschland"}/>
                                    </Form.Field>


                                    <FormFieldStore setValue={this.handleDataChange} label={"Telefon"} name={"phone"}
                                                    placeholder={"Telefon"} required/>

                                </Card.Content>
                            </Card>


                            {/*RECHNUNGSADRESSE*/}
                            <Card style={{width: "100%"}}>
                                <Card.Content>
                                    <h3 className={"fw2 "}>Rechnungsadresse</h3>

                                    <div className="pb2">
                                        <Checkbox onChange={this.toggleBillingAddress}
                                                  checked={useBillingAddress}
                                                  label={"wie Stammdaten"}/>
                                    </div>

                                    {!useBillingAddress &&
                                    <React.Fragment>

                                      <FormFieldStore setValue={this.handleInvoiceChange} label={"Name"} name={"name"}
                                                      placeholder={"Name"} required/>

                                      <FormFieldStore setValue={this.handleInvoiceChange} label={"Firma"}
                                                      name={"company"}
                                                      placeholder={"Firma"} required/>

                                        {/*<Form.Field>*/}
                                        {/*    <label htmlFor="vatId">UST-ID*</label>*/}
                                        {/*    <input id="vatId" required name="vatId" placeholder='UST-ID'/>*/}
                                        {/*</Form.Field>*/}

                                      <Divider/>

                                      <FormFieldStore setValue={this.handleInvoiceChange}
                                                      autoComplete={"billing street-address"}
                                                      label={"Straße"} name={"street"}
                                                      placeholder={"Straße"} required/>

                                      <FormFieldStore setValue={this.handleInvoiceChange} label={"Adresszusatz"}
                                                      name={"addition"}
                                                      placeholder={"Adresszusatz"}/>

                                      <Form.Group widths='equal'>

                                        <FormFieldStore
                                          autoComplete={"billing postal-code"}
                                          setValue={this.handleInvoiceChange} label={"PLZ"} name={"postalCode"}
                                          placeholder={"PLZ"} required/>

                                        <FormFieldStore
                                          autoComplete={"billing locality"}
                                          setValue={this.handleInvoiceChange} label={"Ort"} name={"city"}
                                          placeholder={"Ort"} required/>

                                      </Form.Group>

                                      <Form.Field>
                                        <input readOnly id="country" name="country" value={"Deutschland"}/>
                                      </Form.Field>


                                      <FormFieldStore setValue={this.handleInvoiceChange} label={"Telefon"}
                                                      name={"phone"}
                                                      placeholder={"Telefon"} required/>
                                    </React.Fragment>

                                    }

                                </Card.Content>
                            </Card>

                            {/*LIEFERDATEN*/}
                            <Card style={{width: "100%"}}>
                                <Card.Content>
                                    <h3 className={"fw2 "}>Lieferdaten</h3>

                                    <div className="pb2">
                                        <Checkbox onChange={() => {
                                            this.setState({useDeliveryAddress: !useDeliveryAddress})
                                        }} checked={useDeliveryAddress}
                                                  label={"wie Stammdaten"}/>
                                    </div>

                                    {!useDeliveryAddress &&
                                    <React.Fragment>

                                      <FormFieldStore setValue={this.handleShippingChange} label={"Name"} name={"name"}
                                                      placeholder={"Name"} required/>

                                      <FormFieldStore setValue={this.handleShippingChange} label={"Firma"}
                                                      name={"company"}
                                                      placeholder={"Firma"} required/>

                                        {/*<Form.Field>*/}
                                        {/*    <label htmlFor="vatId">UST-ID*</label>*/}
                                        {/*    <input id="vatId" required name="vatId" placeholder='UST-ID'/>*/}
                                        {/*</Form.Field>*/}

                                      <Divider/>

                                      <FormFieldStore setValue={this.handleShippingChange}
                                                      autoComplete={"billing street-address"}
                                                      label={"Straße"} name={"street"}
                                                      placeholder={"Straße"} required/>

                                      <FormFieldStore setValue={this.handleShippingChange} label={"Adresszusatz"}
                                                      name={"addition"}
                                                      placeholder={"Adresszusatz"}/>

                                      <Form.Group widths='equal'>

                                        <FormFieldStore
                                          autoComplete={"billing postal-code"}
                                          setValue={this.handleShippingChange} label={"PLZ"} name={"postalCode"}
                                          placeholder={"PLZ"} required/>

                                        <FormFieldStore
                                          autoComplete={"billing locality"}
                                          setValue={this.handleShippingChange} label={"Ort"} name={"city"}
                                          placeholder={"Ort"} required/>

                                      </Form.Group>

                                      <Form.Field>
                                        <input readOnly id="country" name="country" value={"Deutschland"}/>
                                      </Form.Field>


                                      <FormFieldStore setValue={this.handleShippingChange} label={"Telefon"}
                                                      name={"phone"}
                                                      placeholder={"Telefon"} required/>
                                    </React.Fragment>

                                    }

                                </Card.Content>
                            </Card>


                            <div className={"black-70 f6"}>Hilfe benötigt? <a target={"_blank"}
                                                                              href="mailto:info@flottr.com"><span
                                className={"black"}>Kontaktieren Sie uns</span></a></div>


                        </Grid.Column>
                        <Grid.Column className={"sidebar"}>


                            <div className="ph4 pt4">
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Artikel</Table.HeaderCell>
                                            <Table.HeaderCell>Menge</Table.HeaderCell>
                                            <Table.HeaderCell>Einzelpreis</Table.HeaderCell>
                                            <Table.HeaderCell>Zwischensumme</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>


                                        <Table.Row>
                                            <Table.Cell>
                                                <div className={"f5 b"}>{checkoutStore.count} x flottr Easy Drive
                                                </div>
                                                <div className={"f6 black-70"}>GPS Tracker inklusive SIM Karte. OBD2
                                                    Anschluss.
                                                    Sofort einsatzbereit.
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div style={{width: "75px"}}>
                                                    <Dropdown size={"tiny"} value={checkoutStore.products.easyDrive.count} fluid
                                                              onChange={(e, data) => {
                                                                  const count = Number(data.value)
                                                                  // console.log({data})
                                                                  this.handleChange("count", data.value)

                                                                  if (count <= checkoutStore.products.map.count) {
                                                                      checkoutStore.products.map.count = count
                                                                  }

                                                                  if (count <= checkoutStore.products.book.count) {
                                                                      checkoutStore.products.book.count = count
                                                                  }

                                                                  checkoutStore.products.easyDrive.count = count
                                                                  checkoutStore.calculate()

                                                              }} selection options={countOptions}/>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.easyDrive.price * 12)}</div>
                                                <div className="tr f6 black-70">12
                                                    x {currency(checkoutStore.price, 0)}</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.easyDrive.price * checkoutStore.products.easyDrive.count * 12)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className={"f5 b"}>Digitales Fahrtenbuch</div>
                                                <div className={"f6 black-70"}></div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div style={{width: "75px"}}>
                                                    <Dropdown size={"tiny"} value={checkoutStore.products.book.count} fluid
                                                              onChange={(e, data) => {
                                                                  // console.log({data})
                                                                  this.handleChange("count", data.value)
                                                                  checkoutStore.products.book.count = Number(data.value)
                                                                  checkoutStore.calculate()
                                                              }} selection options={countOptions.slice(0, checkoutStore.products.easyDrive.count + 1)}/>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.book.price * 12)}</div>
                                                <div className="tr f6 black-70">12
                                                    x {currency(checkoutStore.products.book.price, 0)}</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.book.price * checkoutStore.products.book.count * 12)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                        {/* GEOMAPS*/}
                                        <Table.Row>
                                            <Table.Cell>
                                                <div className={"f5 b"}>Geo</div>
                                                <div className={"f6 black-70"}>Weltweites Echtzeit-Tracking für Ihr Fahrzeug.</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div style={{width: "75px"}}>
                                                    <Dropdown size={"tiny"} value={checkoutStore.products.map.count} fluid
                                                              onChange={(e, data) => {
                                                                  // console.log({data})
                                                                  this.handleChange("count", data.value)
                                                                  checkoutStore.products.map.count = Number(data.value)
                                                                  checkoutStore.calculate()
                                                              }} selection options={countOptions.slice(0, checkoutStore.products.easyDrive.count + 1)}/>
                                                </div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.map.price * 12)}</div>
                                                <div className="tr f6 black-70">12
                                                    x {currency(checkoutStore.products.map.price, 0)}</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="b tr">{currency(checkoutStore.products.map.price * checkoutStore.products.map.count * 12)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                    </Table.Body>
                                </Table>

                                <Table>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 black-70 pb2">Versandkosten</div>
                                                <div className="f5 black-70 pb2">Zwischensumme</div>
                                                <div className="f5 black-70 pb2">Hardware (Einmalig)</div>
                                            </Table.Cell>

                                            <Table.Cell>
                                                <div className="black-70 tr pb2">kostenlos</div>
                                                <div className="b tr pb2">{currency(checkoutStore.sumPerYear)}</div>
                                                <div className="black-70 tr pb2">{currency(checkoutStore.setupPrice)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 black-70 ">zzgl. USt. (19%)</div>

                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="black-70 tr ">{currency(checkoutStore.sumTax)}</div>

                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 b">Summe</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="f4 b tr">{currency(checkoutStore.total)}</div>
                                            </Table.Cell>
                                        </Table.Row>

                                        <Table.Row>
                                            <Table.Cell>
                                                <div className="f5 black-70">Im Folgejahr (Netto)</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <div className="f5 black-70 tr">{currency(checkoutStore.sumPerYear)}</div>
                                            </Table.Cell>
                                        </Table.Row>


                                    </Table.Body>
                                </Table>


                                <div className={"pv3 pb4"}>
                                    <div className="fl pr2"><Checkbox onChange={e => {
                                        this.setState({termsOk: !this.state.termsOk})
                                    }}/></div>
                                    <div className={"f6 black-70"}>Ich stimme den <a target={"_blank"}
                                                                                     href="https://flottr.com/agb.html">AGB</a> der
                                        Simplias GmbH zu. Die <a
                                            target={"_blank"}
                                            href="https://flottr.com/datenschutz">Datenschutzerklärung</a> habe ich
                                        gelesen
                                    </div>
                                </div>

                                <Button size={"large"} color={"yellow"} fluid>Jetzt
                                    Bestellen</Button>
                            </div>

                        </Grid.Column>
                    </Grid>
                </Form>


                <FooterLegal/>

            </Container>
        )
    }
}

const currency = (value, n = 2) => {
    return `${value.toFixed(n).replace(".", ",")} €`
}

export default class BuyPage extends React.Component<any, any> {


    state = {
        success: false,
        loading: false,
    }


    render() {
        const {success} = this.state
        if (success) return <Redirect to={"/map/"}/>
        return (
            <div className="BuyPage">
                <div className="">
                    <BuyForm/>
                    {/*<BuyFormOld*/}
                    {/*    loading={this.state.loading}*/}
                    {/*    handleSubmit={this.handleSubmit}/>*/}
                </div>
            </div>
        )
    }
}


const BuyFormOld = ({handleSubmit, loading}) => <React.Fragment>
    <div className="mw6 center animate fadeIn delay-500 ">


        <Container>

            <Segment>

                <div className="bottom-flex">
                    <div className="pa3 pa4-ns ">
                        <Header textAlign="center">Tracker Bestellen</Header>
                        <Form onSubmit={handleSubmit}>

                            <Form.Field>
                                <label htmlFor="name">Anzahl*</label>
                                <input
                                    required
                                    type="number"
                                    defaultValue={"1"}
                                    id="name" name="fname"/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="name">Name*</label>
                                <input
                                    required
                                    id="name" name="fname" placeholder='Vorname'/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="form-email">E-Mail*</label>
                                <input
                                    required
                                    id="form-email"
                                    type="email"
                                    name="email" placeholder='E-Mail'/>
                            </Form.Field>


                            <Form.Field>
                                <label htmlFor="form-phone">Telefon*</label>
                                <input
                                    required
                                    id="form-phone"
                                    type="text"
                                    name="phone" placeholder='Tel. Nr.'/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="company">Firma*</label>
                                <input id="company" required name="company" placeholder='Firma'/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="vatId">UST-ID*</label>
                                <input id="vatId" required name="vatId" placeholder='UST-ID'/>
                            </Form.Field>

                            <Divider/>

                            <Form.Field>
                                <label htmlFor="street">Straße</label>
                                <input id="street" name="street" placeholder='Straße'/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="postcode">PLZ</label>
                                <input id="postcode" name="postcode" placeholder='PLZ'/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="city">Ort</label>
                                <input id="city" name="city" placeholder='Ort'/>
                            </Form.Field>


                            <div className="pt2 pb3">
                                <p><a href={"https://flottr.com/agb/"}
                                      target={"_blank"}> AGB </a> und unsere <a
                                    href={"https://flottr.com/datenschutz/"}
                                    target={"_blank"}> Datenschutzerklärung </a></p>
                            </div>

                            <Form.Field>
                                <div className="pt2">
                                    <Checkbox className="f6 black-70" name="terms_ok"
                                              value={moment.utc().toISOString()} required
                                              label="Ich habe die AGB gelesen und akzeptiert."/>
                                </div>
                                <div className="pt2">
                                    <Checkbox className="f6 black-70" name="privacy_ok"
                                              value={moment.utc().toISOString()} required
                                              label="Ich habe die Datenschutzerklärung gelesen und akzeptiert."/>
                                </div>
                            </Form.Field>

                            <div className="pt2">
                                <Button loading={loading} circular fluid primary type='submit'>ANGEBOT ANFRAGEN</Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </Segment>
        </Container>
    </div>
</React.Fragment>


const FormFieldStore = ({name, setValue, label, autoComplete = "", ...rest}) => <Form.Field>
    <label htmlFor="name">{label}</label>
    <input onChange={(e) => {
        setValue(name, e.target.value)
    }} id={name} name={name} autoComplete={autoComplete || name} {...rest} />
</Form.Field>