import * as React from "react"
import {Link, Redirect, withRouter} from "react-router-dom"
import {Breadcrumb, Button, ButtonGroupProps, Card, Container, Form, Label, Modal, Segment} from "semantic-ui-react"
import store from "store/store"
import {observer} from "mobx-react"
import http from "service/http"
import {faChevronLeft, faChevronRight, faCheckCircle, faCheckDouble, faSignal4,} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import TableBase from "component/Table/TableBase.js"
import {Vehicle} from "store/model/Vehicle"
import VehicleForm, { ArchiveVehicle } from "component/Vehicle/VehicleForm"
import {Device} from "store/model/Device"
import {faCar, faEye, faShoppingCart, faWifi, faTimesCircle} from "@fortawesome/pro-solid-svg-icons"
import Icon from "component/Icon"
import moment from "moment"
import {Spinner} from "component/Spinner/Spinner.js"
import "./VehiclesPage.css"
import VehicleSidebar from "component/Vehicle/VehicleSidebar"
import {PageHeaderSimple} from "component/PageHeader"
import {faTimes} from "@fortawesome/free-solid-svg-icons";

//@ts-ignore
@withRouter
@observer
class VehicleDataForm extends React.Component<any, any> {
    state = {
        loading: false
    }

    handleSubmit = async event => {
        event.preventDefault()
        const {id} = this.props
        const vehicle = store.account.vehicle.byId.get(id)
        if (!vehicle) return
        this.setState({loading: true})
        const data = new FormData(event.target)
        let carId = String(data.get("vehiclePlate"))
        let carName = String(data.get("carName"))
        http
            .put(`/api/v1/vehicle/${this.props.id}/rename`, {carId, carName})
            .then(res => {
                this.setState({loading: false})
                vehicle.carId = res.data.carId
                vehicle.carName = res.data.carName
                this.props.history.replace(`/vehicles/`)
                this.props.onClose && this.props.onClose()
            })
            .catch(err => {
                this.setState({loading: false})
                // console.log.error( err )
            })
    }

    update(data) {
        const carId = String(data.get("carId"))
        // console.log.log( "UPDATE", {carId} )
    }

    render() {
        const {id} = this.props
        const {loading} = this.state
        const vehicle: Vehicle | undefined = store.account.vehicle.byId.get(id)
        if (!vehicle)
            return (
                <div>
                    <Segment loading/>
                </div>
            )
        return (
            <div className="VehicleDataForm pt3">
                <Form key={vehicle._id} onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <label htmlFor="form-name">KFZ</label>
                        <input
                            id="form-carId"
                            name="carId"
                            defaultValue={vehicle.vehiclePlate}
                            placeholder="Kennzeichen"
                        />
                    </Form.Field>

                    <Form.Field>
                        <label htmlFor="form-name">Fahrzeugname</label>
                        <input
                            id="form-name"
                            defaultValue={vehicle.title}
                            name="carName"
                            placeholder="Name"
                        />
                    </Form.Field>

                    <Button primary loading={loading} floated={"right"}>
                        SPEICHERN
                    </Button>
                    <Button
                        role="button"
                        type="button"
                        icon={"close"}
                        as={Link}
                        to={`/vehicles/`}
                    />
                </Form>
            </div>
        )
    }
}

@observer
class Vehicles extends React.Component {
    render() {
        const {accounts} = store.account
        // let trips = all.filter(trip => trip.type === "eDriving")
        return (
            <Card.Group itemsPerRow={5}>
                {accounts.map(acc => {
                    return (
                        <Card key={acc.id} as={Link} to={`/vehicle/${acc.id}`}>
                            <Card.Content>
                                <div className="black b">{acc.carId || "-"}</div>
                                <div className="black">{acc.carName}</div>
                                <div className="black f6">{acc.contactName}</div>
                                <div className="f6 black-60">{acc.id}</div>
                            </Card.Content>
                        </Card>
                    )
                })}
            </Card.Group>
        )
    }
}

// @ts-ignore
@observer
export class VehicleList extends React.Component<any, any> {
    render() {
        return (
            <div className="pb4 f6">
                <TableBase
                    basic={"very"}
                    compact
                    singleLine
                    data={[...store.account.vehicle.vehicles]}
                    columns={this.props.columns}
                />
            </div>
        )
    }
}

@observer
class TrackerConfigList extends React.Component<any, any> {
    render() {

        if (!store.account.user.keycard.deviceManager) return null

        const devices = [...store.account.device.list].filter(device => {
            let vehicle = store.account.vehicle.getVehicleByTrackerId(device.deviceId)
            return !vehicle
        })

        if (devices.length === 0) {
            return (
                <div className={"tc pt4-ns"}>
                    <div className="pv3">Alle Tracker eingerichtet.</div>
                    <Button as={Link} to={"/buy/"} circular>
                        <Icon icon={faShoppingCart}/> GPS-Tracker bestellen
                    </Button>
                </div>
            )
        }

        return (
            <div className="TrackerConfigList pt4">
                <h3>Tracker ohne Fahrzeug</h3>
                <TableBase
                    basic={"very"}
                    data={devices}
                    columns={[
                        {
                            Header: "TrackerID",
                            accessor: "deviceId"
                        },
                        {
                            Header: () => <div className="tc">Fahrzeug</div>,
                            id: "vehicleId",
                            Cell: ({row}) => {
                                const device: Device = row.original
                                const vehicle = store.account.vehicle.getVehicleByTrackerId(
                                    `${device.deviceId}`
                                )
                                if (!vehicle) {
                                    return (
                                        <div>
                                            <Label
                                                as={Link}
                                                to={`/vehicles/tracker/${device.deviceId}/`}
                                                color={"green"}
                                            >
                                                {" "}
                                                Fahrzeug zuweisen
                                            </Label>
                                        </div>
                                    )
                                }
                                return (
                                    <div className={"tc"}>
                                        {vehicle.deviceId && (
                                            <Label
                                                as={Link}
                                                to={`/vehicle/${vehicle._id}/`}
                                                size={"tiny"}
                                                color={"green"}
                                            >
                                                <Icon icon={faCheckCircle}/>
                                                {vehicle.title} {vehicle.vehiclePlate}
                                            </Label>
                                        )}
                                    </div>
                                )
                            }
                        }
                    ]}
                />
                {devices.length > 0 && <div className={"tc pt4"}>
                  <Button as={Link} to={"/buy/"} circular>
                    <Icon icon={faShoppingCart}/> GPS-Tracker bestellen
                  </Button>
                </div>}
            </div>
        )
    }
}

//@ts-ignore
@withRouter
class TrackerToVehicle extends React.Component<any, any> {
    render() {
        const assign = (vehicle: Vehicle) => {
            const {deviceId} = this.props
            vehicle.assignTracker({deviceId})
            this.props.history.push("/vehicles/")
            // console.log.log( {vehicle} )
        }

        return (
            <VehicleList
                columns={[
                    {
                        Header: "Kennzeichen",
                        accessor: "vehiclePlate"
                    },
                    {
                        Header: "Name",
                        accessor: "title",
                        className: "f6 mw5-ns overflow-hidden truncate"
                    },
                    {
                        Header: "Marke",
                        accessor: "vehicleManufacturer",
                        className: "f6 mw5-ns overflow-hidden truncate"
                    },
                    {
                        Header: "Gruppe",
                        accessor: "vehicleGroup",
                        className: "f6 mw5-ns overflow-hidden truncate"
                    },
                    {
                        Header: "GPS",
                        className: "f6 mw5-ns overflow-hidden truncate",
                        id: "actions",
                        Cell: ({row}) => (
                            <div>
                                <span className="f6">{row.original.deviceId}</span>
                            </div>
                        )
                    },
                    {
                        Header: "Auswählen",
                        id: "actions",
                        Cell: ({row}) => (
                            <Button size={"tiny"} onClick={() => assign(row.original)}>
                                Auswählen
                            </Button>
                        )
                    }
                ]}
            />
        )
    }
}


type SelectStatsRangeState = {
    selectedTime: any
    mode: "week" | "year"
}
type SelectStatsRangeProps = ButtonGroupProps & {
    className?: string
    onChange(statsQuery: string, statsQueryDisplay: string): void
}

export class SelectStatsRange extends React.Component<SelectStatsRangeProps,
    SelectStatsRangeState> {
    state: SelectStatsRangeState = {
        mode: "week",
        selectedTime: moment()
    }

    componentDidMount() {
        this.emitChange()
    }

    emitChange = () => {
        const query = this.state.mode === "week" ? "YYYY-W" : "YYYY"
        const display = this.state.mode === "week" ? "YYYY [KW] W" : "YYYY"
        const statsQuery = this.state.selectedTime.format(query)
        const statsQueryDisplay = this.state.selectedTime.format(display)
        this.props.onChange(statsQuery, statsQueryDisplay)
    }

    decrement() {
        this.state.selectedTime = this.state.selectedTime.subtract(
            1,
            this.state.mode
        )
        this.emitChange()
        this.setState({})
    }

    increment() {
        const selectedTime = this.state.selectedTime.add(1, this.state.mode)
        const now = moment()
        if (+selectedTime > +now) return
        this.state.selectedTime = selectedTime
        this.emitChange()
        this.setState({})
    }

    switchMode() {
        this.state.mode = this.state.mode === "week" ? "year" : "week"
        this.emitChange()
        this.setState({})
    }

    render() {
        const {mode, selectedTime} = this.state
        const display = mode === "week" ? "YYYY [KW] W" : "YYYY"
        return (
            <Button.Group {...this.props}>
                <Button size="mini" onClick={() => this.decrement()}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Button>
                <Button size="mini" onClick={() => this.switchMode()}>
                    {selectedTime.format(display)}
                </Button>
                <Button size="mini" onClick={() => this.increment()}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Button>
            </Button.Group>
        )
    }
}


type StatsMeta = {
    cnt: number
    sum: number
    min: number
    max: number
    avg: number
}

interface MetaMap {
    [key: string]: StatsMeta
}

type VehiclesPageState = {
    meta?: MetaMap
    statsByVehicleId?: Map<string, any>
    columns?: Array<any>
    currentStatsQuery: string
    currentStatsDisplay: string
}

@observer
class VehiclesPage extends React.Component<any, any> {
    state: VehiclesPageState = {
        currentStatsQuery: moment().format("YYYY-W"),
        currentStatsDisplay: moment().format("YYYY [KW] W")
    }

    async componentDidMount() {
        store.account.vehicle.get()
        this.loadStats()
    }

    async componentDidUpdate(prevProps, oldState) {
        if (oldState.currentStatsQuery !== this.state.currentStatsQuery)
            this.loadStats()
    }

    async loadStats() {
        // console.log.log( `loading state for ${this.state.currentStatsQuery}` )
        if (!this.state.currentStatsQuery)
            return console.warn("currentStatsQuery not set")
        const statsByVehicleId: Map<string, any> = new Map()
        const meta: MetaMap = {}
        let statsReponse = await http.get(
            `/api/v2/company/stats/week/${this.state.currentStatsQuery}`
        )
        statsReponse.data.stats.forEach(({stats}) => {
            const vehicleId = stats.vehicleId
            delete stats.vehicleId
            // console.log(`ROW add stats to ${vehicleId}`, stats)
            statsByVehicleId.set(vehicleId, stats)

            for (let fieldName in stats) {
                const dat = parseFloat(stats[fieldName])
                if (!isNaN(dat)) {
                    let fieldMeta: StatsMeta = meta[fieldName] || {
                        max: 0,
                        min: Number.POSITIVE_INFINITY,
                        cnt: 0,
                        sum: 0,
                        avg: 0
                    }
                    fieldMeta.cnt = fieldMeta.cnt + 1
                    fieldMeta.sum = fieldMeta.sum + dat
                    fieldMeta.max = Math.max(fieldMeta.max, dat)
                    fieldMeta.min = Math.min(fieldMeta.min, dat)
                    meta[fieldName] = fieldMeta
                }
            }
        })
        for (let fieldName in meta)
            meta[fieldName].avg = meta[fieldName].sum / meta[fieldName].cnt

        this.setState({statsByVehicleId, meta})
    }

    downloadCSV() {
        const base64_header = 'data:text/csv;charset=utf-8,'
        const BOM = "\uFEFF"
        const valsep = ';'
        const linesep = '\r\n'
        const {vehicles} = store.account.vehicle
        const {statsByVehicleId, currentStatsDisplay} = this.state

        if (!statsByVehicleId)
            return

        const vehicleColumns = [
            "vehicleGroup",
            "vehiclePlate",
            "title",
            "vehicleManufacturer"
        ]
        const statsColumns = [
            "distanceDriven",
            "timeDriven"
        ]

        const csvContent =
            base64_header +
            BOM +
            [...vehicleColumns, ...statsColumns].join(valsep) +
            linesep +
            vehicles
                .map(row => {
                    const vehicleData = vehicleColumns.map(col => row[col])
                    const statsData = statsColumns.map(col => {
                        let stats = statsByVehicleId.get(row._id)
                        let value = stats && stats[col]
                        return value
                    })
                    return [...vehicleData, ...statsData].join(valsep)
                })
                .join(linesep)


        const date = moment().format('YYYY-MM-DD--kk-mm')
        const filename = `Flottr Export ${currentStatsDisplay} (${date}).csv`
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", filename)
        document.body.appendChild(link) // Required for FF

        link.click() // This will download the file created above
    }

    render() {

        if (!store.account.user.keycard.vehicleManager) return <Redirect to={"/map/"}/>

        const {vehicles} = store.account.vehicle
        const {vehicleId, deviceId} = this.props.match.params
        const {
            statsByVehicleId,
            currentStatsQuery,
        } = this.state
        const vehicle = store.account.vehicle.byId.get(vehicleId)
        const hasVehicles = vehicles && vehicles.length > 0

        if (!statsByVehicleId)
            return <Spinner/>

        return (
            <React.Fragment>
                <VehicleSidebar/>
                {deviceId && (
                    <div>
                        <Modal open>
                            <Modal.Header>
                                <div className="flex flex-row justify-between items-center">
                                    <div> Tracker zuweisen – {deviceId}</div>
                                    <Button as={Link} to={"/vehicles/"} icon={<Icon icon={faTimes}/>} circular basic/>
                                </div>
                            </Modal.Header>
                            <Modal.Content>
                                <TrackerToVehicle deviceId={deviceId}/>
                            </Modal.Content>
                        </Modal>
                    </div>
                )}

                <PageHeaderSimple title={"Fahrzeuge"}>
                    {/*<Button*/}
                    {/*    basic*/}
                    {/*    size="tiny"*/}
                    {/*    onClick={() => this.downloadCSV()}*/}
                    {/*>*/}
                    {/*    CSV*/}
                    {/*</Button>*/}
                    <Button

                        primary
                        as={Link}
                        to={"/vehicles/create"}
                        size="tiny"
                    >
                        hinzufügen
                    </Button>


                </PageHeaderSimple>

                <div className="pt4-ns min-h-100">
                    <Container className={"pt4 container-raised"}>
                        {vehicle && (
                            <div>
                                <div className="pb3">
                                    <Breadcrumb>
                                        <Breadcrumb.Section as={Link} to={"/"}>
                                            Home
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider icon="right chevron"/>
                                        <Breadcrumb.Section as={Link} to={"/vehicles/"}>
                                            Fahrzeuge
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider icon="right arrow"/>
                                        <Breadcrumb.Section active>
                                            {vehicle.carName} {vehicle.vehiclePlate}
                                        </Breadcrumb.Section>
                                    </Breadcrumb>
                                </div>
                                <Modal open>
                                    <Modal.Header>
                                        <div className="flex flex-row justify-between">
                                            <div>
                                                <div> {vehicle.title}{" "}{vehicle.vehiclePlate}</div>
                                                <div className="fw2 f5 black-60">{vehicle.vehicleManufacturer} </div>
                                            </div>
                                            <div>
                                                <Button as={Link} to={"/vehicles/"} basic circular icon={<Icon icon={faTimes}/>}/>
                                            </div>
                                        </div>
                                    </Modal.Header>
                                    <VehicleForm vehicle={vehicle} to={`/map/${vehicle._id}/`}/>

                                </Modal>
                            </div>
                        )}

                        {!vehicleId && (
                            <div>
                                {!hasVehicles && (
                                    <Container text>
                                        <Segment as={Link} to={"/vehicles/create"}>
                                            <div className="pv4 ">
                                                <div className="tc">
                                                    <FontAwesomeIcon icon={faCar} className={"f2"}/>
                                                </div>
                                                <div className="tc black-70 pt3 pb3 ">
                                                    Das erste Fahrzeug anlegen.
                                                </div>
                                                <div className="tc">
                                                    <Button color={"yellow"}>Fahrzeug anlegen</Button>
                                                </div>
                                            </div>
                                        </Segment>
                                    </Container>
                                )}
                                {/*<TableVehicles/>*/}
                                {/*<DeviceSegments {...this.props} to={"/vehicles"}/>*/}
                            </div>
                        )}

                        {hasVehicles && (
                            <React.Fragment>

                                <VehicleList
                                    stats={currentStatsQuery}
                                    columns={[
                                        {
                                            Header: "Kennzeichen",
                                            className: "mw4 overflow-hidden truncate",
                                            id: "vehiclePlate",
                                            accessor: "vehiclePlate"

                                        },
                                        {
                                            Header: "Gruppe",
                                            accessor: "vehicleGroup",
                                            className: "mw4 overflow-hidden truncate"
                                        },
                                        {
                                            Header: "Fahrer",
                                            accessor: "driverName",
                                            className: "mw4 overflow-hidden truncate"
                                        },

                                        // {
                                        //     Header: "Marke",
                                        //     accessor: "vehicleManufacturer",
                                        //     className: "mw4 overflow-hidden truncate"
                                        // },
                                        //TODO: Connect Backend Data for stats
                                        // {
                                        //     id: "distance",
                                        //     align: "right",
                                        //     Header: "Strecke",
                                        //     Subheader: `in ${currentStatsDisplay}`,
                                        //     sortable: true,
                                        //     accessor: row =>
                                        //         statsByVehicleId.get(row._id)?.distanceDriven,
                                        //     Cell: ({row}) => {
                                        //         const vehicleId = row.original._id
                                        //         const stats = statsByVehicleId.get(vehicleId)
                                        //         const distance = Math.round(
                                        //             stats?.distanceDriven
                                        //         ).toLocaleString()
                                        //         return <div className="tr">{distance} km</div>
                                        //
                                        //         // const d = stats.distanceDriven
                                        //         // const D = meta.distanceDriven.max
                                        //         // const avg =
                                        //         // 	meta.distanceDriven.avg / meta.distanceDriven.max
                                        //         // const ratio = d / D
                                        //         // return <Progress percent={ratio * 100} />
                                        //         /* return (
                                        //             <div style={{ width: `100%`, backgroundColor: "#eee" }}>
                                        //                 <div
                                        //                     style={{
                                        //                         width: `ratio*100${ratio * 100}%`,
                                        //                         height: "1.4em",
                                        //                         backgroundColor: "#666"
                                        //                     }}
                                        //                 ></div>
                                        //                 <div
                                        //                     style={{
                                        //                         left: `ratio*100${avg * 100}%`,
                                        //                         position: "absolute",
                                        //                         height: "1.4em",
                                        //                         borderRight: "1px solid #c00"
                                        //                     }}
                                        //                 ></div>
                                        //             </div>
                                        //         ) */
                                        //     }
                                        // },
                                        // {
                                        //     Header: "Laufzeit",
                                        //     Subheader: `in ${currentStatsDisplay}`,
                                        //     align: "right",
                                        //     id: "time",
                                        //     accessor: row => statsByVehicleId.get(row._id) && statsByVehicleId.get(row._id)?.timeDriven,
                                        //     Cell: ({row}) => {
                                        //         const vehicleId = row.original._id
                                        //         const stats = statsByVehicleId.get(vehicleId)
                                        //         const time = Math.round(stats?.timeDriven).toLocaleString()
                                        //         return <div className="tr">{time} h</div>
                                        //     }
                                        // },
                                        {
                                            Header: "Status",
                                            id: "status",
                                            Cell: ({row}) => {
                                                const vehicle = row.original
                                                const device = store.account.device.byId.get(vehicle.deviceId)
                                                if (!device) return <Label
                                                    as={Link}
                                                    to={`/vehicles/${vehicle._id}`}
                                                    size={"tiny"}
                                                >
                                                    <Icon name={"x"}/> Tracker hinzufügen
                                                </Label>

                                                return (
                                                    (device && (
                                                        <React.Fragment>
                                                            <div className="flex flex-row items-center">
                                                                <GPSLabel device={device}/>
                                                            </div>
                                                            {/*<FontAwesomeIcon icon={faCheckCircle} />*/}
                                                        </React.Fragment>
                                                    )) || (
                                                        <Label
                                                            as={Link}
                                                            to={`/vehicles/${vehicle._id}`}
                                                            size={"tiny"}
                                                        >
                                                            <Icon name={"x"}/> kein Tracker
                                                        </Label>
                                                    )
                                                )
                                            }
                                        },
                                        {
                                            Header: "Easy Drive / Fahrtenbuch / Echtzeit",
                                            id: "has-easy-drive",
                                            Cell: ({row}) => {
                                                const vehicle = row.original
                                                return <div className="flex flex-row f4  ">
                                                    {vehicle.featureByKey["FEATURE_BASE"] ? <div className="tc"><Icon title={"Easy Drive: OBD2 Stecker"} icon={faCheckCircle} className={"green mr1"}/></div> :
                                                        <div><Icon icon={faTimesCircle} className="black-70 mr1"/></div>}
                                                    {vehicle.featureByKey["FEATURE_DRIVEBOOK"] ? <div className="tc"><Icon title={"Fahrtenbuch"} icon={faCheckCircle} className={"green mr1"}/></div> :
                                                        <div><Icon icon={faTimesCircle} className="black-70 mr1"/></div>}
                                                    {vehicle.featureByKey["FEATURE_REALTIME"] ? <div className="tc"><Icon title={"Echtzeittracking"} icon={faCheckCircle} className={"green mr1"}/></div> :
                                                        <div><Icon icon={faTimesCircle} className="black-70 mr1"/></div>}
                                                </div>
                                            }
                                        },
                                        {
                                            id: "actions",
                                            Cell: ({row}) => {
                                                const vehicle = row.original
                                                const {deviceId} = vehicle
                                                return (
                                                    <React.Fragment>
                                                        <div className="flex flex-row justify-end">
                                                            <div className=" VehicleActions flex flex-row items-center justify-start">

                                                                {!vehicle.active && <div className={"flex flex-row items-center justify-between"}>
                                                                    <div className="pr2 silver">Fahrzeug wird archiviert</div>
                                                                    </div>}
                                                                {vehicle.active && <>
                                                                    <ArchiveVehicle size="tiny" vehicle={vehicle}/>

                                                                    <Button as={Link} basic size={"tiny"} className={"pr3"} to={`/vehicles/${vehicle._id}/`}>
                                                                        bearbeiten
                                                                    </Button>

                                                                    <Button as={Link} basic size={"tiny"} className={"pr3"} to={`/map/${vehicle._id}/`}>
                                                                        <span className="db dn-ns"><Icon icon={faEye}/></span>
                                                                        <span className="dn db-ns">anzeigen</span>
                                                                    </Button>

                                                                </>}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        }
                                    ]}
                                />

                            </React.Fragment>
                        )}
                    </Container>


                    {store.account.user.keycard.deviceManager && <Container className={"pb4"}>
                      <TrackerConfigList/>
                    </Container>}

                </div>
            </React.Fragment>
        )
    }
}

export default VehiclesPage

@observer
export class GPSLabel extends React.Component<{ text?: boolean, device: any }> {
    render() {
        let {device, text = false} = this.props
        if (!device) return null

        const privacyMode = device && device.privacyMode || "eTracking"
        const activeLast2hours = moment().subtract(4, "hours").isBefore(moment(device.time))
        const activeLast24hours = moment().subtract(24, "hours").isBefore(moment(device.time))
        const hasActivity = activeLast24hours && (device.speed > 2 || device && device.signals && (device.signals.ignition !== "00" || device.signals.movement !== "00"))
        const titleLastActive = device.time ? moment.duration(moment(device.time).diff(moment())).humanize(true) : "Neuer Tracker"
        const isPluggedIn = !device.isUnplugged
        return <div className={"flex flex-row items-center"}>
            {privacyMode === "eTracking" ? <React.Fragment>
                    {activeLast2hours ? <div title={titleLastActive} className={`green f5 pa1`}>
                        <FontAwesomeIcon icon={faSignal4}/>
                        {text && `${device.time ? "" : "Neuer Tracker"}`}
                    </div> : <React.Fragment>
                        {activeLast24hours ? <div title={titleLastActive} className={`yellow f5 pa1`}>
                                <FontAwesomeIcon icon={faSignal4}/>
                            </div> :
                            <div title={titleLastActive} className={`red f5 pa1`}>
                                <FontAwesomeIcon icon={faSignal4}/>
                            </div>}
                    </React.Fragment>}

                    {isPluggedIn ? <div title="OBD2 eingesteckt" className={`green f5 pa1`}>
                            <FontAwesomeIcon icon={faCheckDouble}/>
                        </div> :
                        <div title="OBD2 inaktiv" className={`gray f5 pa1`}>
                            <FontAwesomeIcon icon={faCheckDouble}/>
                        </div>
                    }

                    {hasActivity ? <div title="Fahrzeug in Betrieb" className={`green f5 pa1`}>
                            <FontAwesomeIcon icon={faCar}/>
                        </div> :
                        <div title="Motor aus" className={`gray f5 pa1`}>
                            <FontAwesomeIcon icon={faCar}/>
                        </div>
                    }
                </React.Fragment> :
                <div title="Privatmodus" className={`blue f5 pa1`}>
                    <FontAwesomeIcon icon={faWifi}/> {text && "Privatmodus"}
                </div>
            }
        </div>
    }
}