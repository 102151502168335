import * as React from "react"

import {Route, Redirect} from "react-router-dom"
import store from "store/store"

function RouteWithLogin({ layout, component, path , footer = null, exact = false }) {
  if( !store.account.user  ) return <Redirect to="/login"/>
  return (
    <Route exact={exact} path={path} render={( props ) =>
      React.createElement( layout, props
        , React.createElement( component, props )
        , footer ? React.createElement( footer, props ) : null
      )
    }/>
  )
}

export default RouteWithLogin
