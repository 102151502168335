import {action, computed, observable} from "mobx"
import moment from "moment"
import Trip from "../Trip"
import http from "../../service/http"
import Trips from "store/Trips"
import store from "store/store"
import * as _ from "lodash"
import {Device} from "./Device"

export type DriveStats = {
    distance: number,
    distancePrivate: number,
    distanceWork: number,
    distanceBusiness: number,
    trips?: Array<Trip>
}

export interface ITrips {
    all: Array<Trip>
    byDay: Map<string, Array<Trip>>
    byMonth: Map<string, Array<Trip>>
    byId: Map<string, Trip>
    journeys: Array<Trip>
    sortedDays: Array<string | Array<Trip>>
    totalTime: moment.Duration
    totalDistance_km: string
    firstTrip: Trip
    centerTrips: Array<Trip>
    lastTrip: Trip,
    statsByDay: Map<string, DriveStats>,
    statsByMonth: Map<string, DriveStats>,
    statsByYear: Map<string, DriveStats>,
}

export interface IGoogleAddress {
    locality?: string
    country?: string
    postal_code?: string
    route?: string
    street_number?: string
}

export interface INote {
}

export class Vehicle {
    @observable id: string
    @observable _id: string
    @observable title: string
    @observable vehicleGroup: string
    @observable vehiclePlate: string
    @observable vehicleManufacturer: string
    @observable vehicleImage: string = ""

    @observable features: Array<string> = []
    @observable featureByKey: { [key: string]: boolean } = {}

    @observable lastNote: any

    @observable companyId: string
    @observable deviceId: string = ""
    @observable mileage: string
    @observable mileageDate: string
    @observable tracker: Device

    @observable invoiceByFeatureList: boolean = false

    @observable driverName: string
    @observable driverImage: string

    drivebookNotificationMode: boolean
    drivebookEmail: string

    @observable noteById: Map<string, INote> = new Map()

    basePath: string

    carName: string
    carId: string
    @observable lastLocation: IGoogleAddress = {}
    trips: Trips

    constructor(data) {
        Object.assign(this, data)
        this.trips = new Trips(this)

        if (this.deviceId === "null") {
            this.deviceId = ""
        }

        this.features = this.features || []

        if (!this.invoiceByFeatureList) {
            this.features = [
                "FEATURE_BASE",
                "FEATURE_DRIVEBOOK",
                "FEATURE_REALTIME"
            ]
        }

        this.features.map(featureKey => {
            this.featureByKey[featureKey] = true
        })

        this.basePath = `/map/${this._id}`

    }

    @computed
    get cost(){
        let cost = 0
        if( this.featureByKey["FEATURE_BASE"]) cost += 12
        if( this.featureByKey["FEATURE_DRIVEBOOK"]) cost += 3
        if( this.featureByKey["FEATURE_REALTIME"]) cost += 3
        return cost
    }

    get device() {
        return store.account.device.byId.get(this.deviceId)
    }

    @computed
    get driverInitials() {
        let initials = this.driverName ? this.driverName.split(" ").slice(0, 2).map(e => e[0]) : []
        return initials.join("")
    }

    @action
    assignTracker({deviceId}) {
        this.deviceId = deviceId
        this.put({deviceId})
    }

    @action
    createShareURL({hours}) {
        // console.log( "CREATE SHARE" )
        return http.post(`/api/mfr/share/${this._id}`, {
            licensePlate: this.vehiclePlate,
            validFrom: "FROM",
            validTo: "TO",
            hours,
            vehicle: this._id
        })
    }

    @action
    put(data) {
        return http.put(`/api/v1/vehicle/${this._id}`, data).then(res => {
            Object.assign(this, res.data.vehicle)
            return res.data.vehicle
        })
    }

    @action
    update(data) {
        Object.assign(this, data)
    }

    @action
    async getGeocodeAddress() {
        return this.debouncedGeocoder()
    }

    get notes() {
        return [...this.noteById.entries()].map(e => e[1])
    }

    debouncedGeocoder = _.debounce(
        async () => {
            let location = await store.geocode(this)
            let address = {}
            if (location && location.address_components) {
                location.address_components.forEach(locComponent => {
                    address[locComponent.types[0]] = locComponent.short_name
                })
                this.lastLocation = address
            }
        },
        1000 * 60,
        {leading: true, trailing: true}
    )

    debouncedFetchNotes = _.debounce(this.fetchNotes, 1000 * 60, {leading: true, trailing: true})


    @action
    fetchNotes() {
        return http.get(`/api/v1/vehicle/${this._id}/notes`)
            .then(res => {
                let notes = res.data.notes
                const noteById = new Map()
                let lastNote = false
                notes.forEach(note => {
                    // console.log( {note, id: note.noteId} )
                    noteById.set(note.noteId, note)
                    lastNote = note
                })
                this.noteById = noteById
                this.lastNote = lastNote
                return notes
            })
    }

    @action
    fetchTripsForDay({day = moment().format("YYY-MM-DD")}) {
        const end = moment(day, "YYYY-MM-DD")
            .add(1, "day")
            .format("YYYY-MM-DD")

        return this.trips.fetch({start: day, end, records: true})
    }

    @action
    deleteNote(noteId) {
        return http
            .delete(`/api/v1/vehicle/${this._id}/note/${noteId}`, {})
            .then(res => {
                // console.log( "REMOVED NOTE", res )
                return res
            })
    }

    @action
    putNote(note) {
        return http
            .post(`/api/v1/vehicle/${this._id}/note/${note.noteId}`, note)
            .then(res => {
                // console.log( "POSTED VNOTE", res )
                return res
            })
    }

    @action
    postNote(content) {
        return http
            .post(`/api/v1/vehicle/${this._id}/note`, {
                content
            })
            .then(res => {
                // console.log( "POSTED NOTE", res )
                return res
            })
    }
}
