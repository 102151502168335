import {action, computed, observable} from "mobx";
import {Account} from "./Account";


type UserProfile = {
    userId: string
    name: string
    companyId: string
    userRole: string
    userGroup: string
    vehicles: Array<{ _id: string }>
    email: string
    _modified: number
    driverLicense: string
    driverLicenseExpiration: string
    driverEmail: string
    driverPhone: string
    driverRemark: string
    _created: number
    _id: string
}

interface IToken {
    token: string
    comment: string
}

export class User {
    _id: string
    accounts: Array<Account>
    accountById: Map<string, Account>
    active: boolean = false
    profile: any = {}
    id: string
    userId: string
    activationToken: string
    clientId: string
    vehicles: Array<{ _id: string }>
    email: string
    companyId: string
    name: string
    username: string
    userRole: string
    callsign: string
    isDemo: boolean
    @observable tokens: Array<IToken> = ["_"]

    keycard = {
        deviceManager: false,
        eventManager: false,
        vehicleManager: false,
        userManager: false,
        companyManager: false,
    }

    userProfile: UserProfile

    @action
    setToken(token) {
        this.tokens[0] = {
            token: token,
            comment: "Primär"
        }
    }

    constructor(data, userProfile) {
        this.userProfile = userProfile

        this.tokens = []
        try {
            this.tokens.push({token: data.profile.token, comment: "Primär"})
        } catch (err) {
            // OLD TOKEN IN DB

        }

        this.vehicles = []
        this.accounts = []
        this.accountById = new Map()

        if (this.userProfile.userRole === "admin") {
            this.keycard.deviceManager = true
            this.keycard.vehicleManager = true
            this.keycard.userManager = true
            this.keycard.companyManager = true
        }

        if (window.$zoho) {
            try {
                window.$zoho.salesiq.visitor.name(this.userProfile.name);
                window.$zoho.salesiq.visitor.email(this.userProfile.email);
            } catch (err) {
                console.error(err)
            }
        }

        if (data.accounts) {
            this.accounts = data.accounts
                .filter(acc => acc.contactId)
                .map(account => new Account(account))
        }

        this.accounts.forEach(account => {
            this.accountById.set(account.id, account)
        })

        delete data.accounts
        Object.assign(this, data)
        this.active = !this.activationToken
        // console.log( "new User", this )
    }

    @computed
    get accountSelection() {
        return this.accounts.map(account => {
            return {
                text: `${account.accountURL ? account.accountURL : ""}${account.carName} (${account.carId})`,
                value: `${account.id}`
            }
        })
    }
}

