import {DriveBookTableSummary} from "component/DriveBookTable"
import * as React from "react"
import {observer} from "mobx-react"
import {Button, Container, Loader} from "semantic-ui-react"
import {Link} from "react-router-dom"
import store from "store/store"
import moment from "moment"
import Helmet from "react-helmet"
import "moment/locale/de"
import * as queryString from "querystring";
import parseQuery from "util/parseQuery"
import {PageHeaderSimple} from "component/PageHeader"
import "./DriveBookPage.css"
import {SidebarContext} from "component/Vehicle/VehicleSidebar"

import axios from "axios"
import OpenNewDrivelog from "page/OpenNewDrivelog"
import Icon from "component/Icon"
import {faDownload, faPencil} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import DropdownYear from "component/DropdownYear"

window["moment"] = moment

function download( url, filename ) {
	var a = document.createElement( "a" )
	a.href = url
	a.setAttribute( "download", filename )
	// a.target = "_blank"
	document.body.appendChild( a ) // Required for Firefox
	a.click()
	a.remove()

	return false
}

const postPDF = async ( {filename = `FLOTTR-EXPORT.pdf`} = {} ) => {
	let html = document.querySelector( ".print-this" )?.outerHTML
	if ( !html ) return

	let cssRules = ''

	let response = await axios.post( `https://ncg.struktur.online/html2pdf`, {
		html,
		cssRules
	} )
	const {pdfKey} = response.data
	let url = `https://ncg.struktur.online/pdf/${pdfKey}/${filename}.pdf`
	download( url, filename )
	return {url}
}

@observer class DriveBookPage extends React.Component {
	render() {
		const query = parseQuery( this.props )
		const {vehicleId, year} = query
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		const device = vehicle && store.account.device.byId.get( `${vehicle.deviceId}` )

		if ( !vehicle || !device ) return <div>Kein Fahrzeug</div>
		if ( !vehicle.mileage || !vehicle.mileageDate ) return <OpenNewDrivelog />
		return <div className={"w-100"}>
			<SidebarContext forceHide />
			<PageHeaderSimple title={"Fahrtenbuch"}>
				<DropdownYear query={query} />
				<Button as={Link} to={`${vehicle.basePath}/fahrtenbuch/edit/?${queryString.stringify( query )}`}>
					<div className="nowrap"><Icon icon={faPencil} />
						<span className="pl2 dn dib-ns">bearbeiten</span></div>
				</Button>
				<Button primary as={"a"} target={"_blank"} download href={`${process.env.REACT_APP_API_URL}/fahrtenbuch/${vehicle._id}/${year}/`}>
					<div className="nowrap">
						<Icon icon={faDownload} />
						<span className="pl2 dn dib-ns">Download</span>
					</div>
				</Button>
			</PageHeaderSimple>
			{vehicle && <DriveBook {...this.props} />}
		</div>
	}
}

class DriveBook extends React.Component<any, any> {
	last: any = false
	year: any

	state = {
		loading: false,
		trips  : null,
		error  : null,
	}

	handleTimeUpdate = ( {startDate, endDate, scale} ) => {
		const query = parseQuery( this.props )
		const {deviceId} = query
		// TODO: TEST REDIRECT
		this.props.history.replace( {
			search: queryString.stringify( {
				deviceId,
				startDate: startDate.format( "YYYY-MM-DD" ),
				endDate  : endDate.format( "YYYY-MM-DD" ),
				scale
			} )
		} )
	}

	componentDidMount() {
		const query = parseQuery( this.props )
		if ( !query.startDate ) {
			const startDate = moment().startOf( "year" )
			const endDate = moment().endOf( "year" )
			this.handleTimeUpdate( {startDate, endDate, scale: "year"} )
		} else {
			this.load()
		}
	}

	sortByDays( byDay ) {
		let days = [...byDay.entries()]
		days = days.sort( ( a, b ) => {
			let dateA = a[0]
			let dateB = b[0]
			if ( dateA < dateB ) {
				return 1
			} else {
				return -1
			}
		} )
		return days
	}

	tripSetup( all, byId ) {
		all.forEach( trip => {
			trip.setup( byId )
		} )
		return all
	}

	load = async () => {
		const {vehicleId, year} = parseQuery( this.props )

		if ( this.year === year ) return
		this.setState( {loading: true} )
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		if ( !vehicle ) return

		this.year = year

		vehicle.trips.fetchDrivebook( {month: "ALL", year: `${year}`} )
			.then( res => {
				this.setState( {trips: res, loading: false} )
			} )
	}

	componentDidUpdate( prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any ) {
		this.load()
	}

	render() {
		const {trips, loading} = this.state
		const query = parseQuery( this.props )
		const {vehicleId, year} = query
		const vehicle = store.account.vehicle.byId.get( `${vehicleId}` )
		const device = vehicle && store.account.device.byId.get( `${vehicle.deviceId}` )

		if ( !vehicle ) return null

		return (
			<div className="DriveBookPage page w-100 h-100 pb7-ns pb3 overflow-y-auto">

				<Helmet>
					<title>Fahrtenbuch {year} für {vehicle.vehiclePlate}</title>
				</Helmet>

				<div className="pt4 ph3 ph3-m ph5-ns relative ">
					{loading && <Loader />}
					<div className="print-this">
						{(!loading && trips) && <DriveBookTableSummary vehicle={vehicle} trips={trips} year={`${year}`} />}
						{loading && <div className="tc pv5 bg-white segment"><Button basic loading/></div>}
						{/*<div className="dn dn-m db-l">*/}
						{/*	{!loading && <DriveBookTable vehicle={vehicle} trips={trips} />}*/}
						{/*</div>*/}
					</div>
				</div>
			</div>
		)
	}
}
export default DriveBookPage
