import {action, observable} from "mobx"
import http from "../service/http"

export type DocumentCategory = "contract" | "fuel" | "invoice" | "damage" | "media"
export type DocumentStatus = "default" | "draft" | "trash"

export const categoryPlural = {
    contract: "Verträge",
    fuel: "Tankbelege",
    invoice: "Rechnungen",
    damage: "Schadensberichte",
    media: "Medien",
}
export const categorySingular = {
    contract: "Vertrag",
    fuel: "Tankbeleg",
    invoice: "Rechnung",
    damage: "Schadensbericht",
    media: "Medien",
}

type newFileData = {
    fileId: string
    userId: string
    vehicleId: string
    companyId: string

    status: DocumentStatus
    categoryId: DocumentCategory

    url: string
    name: string
    size: number
    created: string
    mimetype: string
    container: string
}

function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = Math.round(Math.floor(Math.log(bytes) / Math.log(1024)))
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export class File {
    fileId: string
    userId: string
    vehicleId: string
    companyId: string

    status: DocumentStatus
    categoryId: DocumentCategory

    url: string
    name: string
    size: number
    created: string
    mimetype: string
    container: string

    isImage: boolean
    filesize: string

    constructor(data: newFileData) {

        this.fileId = data.fileId
        this.userId = data.userId
        this.vehicleId = data.vehicleId
        this.companyId = data.companyId

        this.status = data.status
        this.categoryId = data.categoryId

        this.url = data.url
        this.name = data.name
        this.size = data.size || Math.random() * 3e7
        this.created = data.created
        this.mimetype = data.mimetype
        this.container = data.container

        this.isImage = !!this.mimetype && this.mimetype.split("/")[0] === "image"
        this.filesize = bytesToSize(this.size)
    }
}

export default class FileStore {
    @observable files: Array<File>
    @observable byFileId: Map<string, File>
    @observable byVehicleId: Map<string, Map<string, File>>

    constructor() {
        this.files = []
        this.byFileId = new Map()
        this.byVehicleId = new Map()
    }

    @action
    async uploadFile({localFile, vehicleId, categoryId}): Promise<any> {
        const uploadUrl = `/api/upload/${vehicleId}/${categoryId}`
        const uploadHeaders = {"content-type": "multipart/form-data"}
        const uploadFormData = new FormData()
        uploadFormData.append("file", localFile)
        const uploadFilesResponse = await http.post(
            uploadUrl,
            uploadFormData,
            uploadHeaders
        )

        const remoteFile = uploadFilesResponse.data.files[0]

        const newFileData: newFileData = {
            vehicleId,
            userId: remoteFile.userId,
            companyId: remoteFile.companyId,
            fileId: remoteFile.fileId,

            status: "draft",
            categoryId,

            url: remoteFile.url,
            name: remoteFile.name,
            size: remoteFile.size,
            created: remoteFile.created,
            mimetype: remoteFile.mimetype,
            container: remoteFile.container
        }

        const appendMetadataUrl = `/api/upload/${remoteFile.fileId}`
        let response = await http.put(appendMetadataUrl, newFileData)
        this.indexFile(newFileData)
        return remoteFile
    }

    @action
    putMeta(fileId, meta) {
        return http.put(`/api/upload/${fileId}`, meta).then(res => {
            const {files} = res.data
            let file = this.byFileId.get(fileId)
            if (file) {
                Object.assign(file, meta)
                this.byFileId.set(fileId, file)
            }
            return file
        })
    }


    @action
    indexFile(fileData: newFileData) {
        const newFile = new File(fileData)
        this.byFileId.set(newFile.fileId, newFile)
        this.files = [...this.byFileId.values()]

        const filesByVehicleId = this.byVehicleId.get(newFile.vehicleId) || new Map()
        filesByVehicleId.set(newFile.fileId, newFile)
        this.byVehicleId.set(newFile.vehicleId, filesByVehicleId)
    }

    @action
    fetchByVehicleId(vehicleId) {
        return http.get(`/api/upload/vehicle/${vehicleId}`).then(res => {
            const {files} = res.data
            files.forEach(fileData => this.indexFile(fileData))
            return files
        })
    }

    @action
    get() {
        // TODO: VERY NAIVE FETCH ALL IMPLEMENTATION
        if (this.files.length > 0)
            return console.log("already fetched files!?")
        return http.get("/api/upload").then(res => {
            const {files} = res.data
            files.forEach(fileData => this.indexFile(fileData))
            return files
        })
    }
}
