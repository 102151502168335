import * as React from "react"
import {Segment, Table} from "semantic-ui-react"
import {observer} from "mobx-react"
import moment from "moment"
import "./TripTable.css"
import {ITrips, Vehicle} from "store/model/Vehicle"

export class DriveBookTableSummary extends React.Component<{ trips: ITrips | null, vehicle: Vehicle, year: string }, { showDetails: boolean }> {


	state: { showDetails: boolean } = {
		showDetails: false
	}

	render() {
		const {year, vehicle, trips} = this.props
		if ( !trips ) return null

		const statsYear = trips.statsByYear.get( year )

		return <div className="DriveBookTableSummary ">
			<div className="f3 f1-ns b pv4-ns lh-title ">Jahresübersicht {year}</div>
			<Table singleLine unstackable>
				<Table.Header>
					<Table.Row>
						<Table.Cell colSpan={5}>
							<div className="fl tl">
								<div className="black-70 f6">Amtliches Kennzeichen <span className="b ">{vehicle.vehiclePlate}</span></div>
							</div>
							{vehicle.driverName && <div className="tr fr">
								<div className="black-70">Name des Fahrers:</div>
								{vehicle.driverName}
							</div>}
						</Table.Cell>
					</Table.Row>

					<Table.Row>
						<Table.HeaderCell textAlign={"right"}>Monat</Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}>Betrieblich (km)*</Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}>Arbeitsweg (km)*</Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}>Privat (km)*</Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}>Gesamt (km)*</Table.HeaderCell>
					</Table.Row>

				</Table.Header>

				<Table.Body>
					{moment.months().map( ( month, index ) => {
						const stats = trips.statsByMonth.get( `${index + 1}` )
						return <Table.Row key={month}>
							<Table.Cell textAlign={"right"}>{month}</Table.Cell>
							<Table.Cell textAlign={"right"}>{Math.round( stats?.distanceBusiness || 0 )}</Table.Cell>
							<Table.Cell textAlign={"right"}>{Math.round( stats?.distanceWork || 0 )}</Table.Cell>
							<Table.Cell textAlign={"right"}>{Math.round( stats?.distancePrivate || 0 )}</Table.Cell>
							<Table.Cell textAlign={"right"}>{Math.round( stats?.distance || 0 )}</Table.Cell>
						</Table.Row>
					} )}
				</Table.Body>

				{statsYear && <Table.Footer>

					<Table.Row>
						<Table.HeaderCell textAlign={"right"}><b>Summe</b></Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}><span className="b">{Math.round( statsYear.distanceBusiness )}</span></Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}><span className="b">{Math.round( statsYear.distanceWork )}</span></Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}><span className="b">{Math.round( statsYear.distancePrivate )}</span></Table.HeaderCell>
						<Table.HeaderCell textAlign={"right"}><span className="b">{Math.round( statsYear.distance )}</span></Table.HeaderCell>
					</Table.Row>

					<Table.Row>
						<Table.Cell textAlign={"right"}> </Table.Cell>
						<Table.Cell textAlign={"right"}><span className="black-70">{Math.round( 100 * statsYear.distanceBusiness / statsYear.distance )}%</span></Table.Cell>
						<Table.Cell textAlign={"right"}><span className="black-70">{Math.round( 100 * statsYear.distanceWork / statsYear.distance )}%</span></Table.Cell>
						<Table.Cell textAlign={"right"}><span className="black-70">{Math.round( 100 * statsYear.distancePrivate / statsYear.distance )}%</span></Table.Cell>
						<Table.Cell textAlign={"right"}><span className="black-70">100%</span></Table.Cell>
					</Table.Row>

				</Table.Footer>
				}

			</Table>

			<DriveBookTable trips={trips} vehicle={vehicle} sort={trips => {
				return trips.slice().reverse()
			}} />
		</div>
	}
}

@observer
class DriveBookTable extends React.Component<any, any> {

	render() {
		let {trips, vehicle, sort = a => a.reverse()} = this.props
		if ( !trips ) return <div className="pt3 pa3 bg-white "><Segment basic>
			Keine Fahrten
		</Segment></div>
		const {all, sortedDays, journeys} = trips
		if ( !all.length ) return <div>
			<Segment>
				<div className="black-70">Keine Fahrten</div>
			</Segment>
		</div>
		let index = 0 // journeys.length

		return (
			<Table className="DriveBookTable TripTable">
				<Table.Header>
					<Table.Row>
						<Table.Cell colSpan={15}>
							<div className="fl tl">
								<div className="black-70 f6">Amtliches Kennzeichen <span className="b ">{vehicle.vehiclePlate}</span></div>
							</div>
							{vehicle.driver && <div className="tr fr">
								<div className="black-70">Name des Fahrers:</div>
								{vehicle.driver}
							</div>}
						</Table.Cell>
					</Table.Row>

					<Table.Row>
						<Table.HeaderCell textAlign="center" width={1}>#</Table.HeaderCell>
						<Table.HeaderCell width={1}>KM-Stand</Table.HeaderCell>
						{/*<Table.HeaderCell width={2}>Zeit</Table.HeaderCell>*/}
						<Table.HeaderCell width={3}>Startadresse <br />Zieladresse</Table.HeaderCell>
						<Table.HeaderCell>Typ</Table.HeaderCell>
						<Table.HeaderCell width={2}>
							<div>B* / A* / P*</div>
							<div>in km</div>
						</Table.HeaderCell>
						<Table.HeaderCell width={3}>
							<div>Zweck der Fahrt</div>
							<div>Geschäftspartner</div>
						</Table.HeaderCell>
						<Table.HeaderCell width={1}>Fahrer/in</Table.HeaderCell>
						<Table.HeaderCell width={1}>Bemerkung</Table.HeaderCell>
						<Table.HeaderCell width={1}>
							Zeitstempel <br />
							Bearbeitung
						</Table.HeaderCell>
						{/*<Table.HeaderCell width={1}>Datum</Table.HeaderCell>*/}
						{/*<Table.HeaderCell>Distanz</Table.HeaderCell>*/}
						{/*<Table.HeaderCell>&nbsp;</Table.HeaderCell>*/}
						{/*<Table.HeaderCell>Geschäftspartner</Table.HeaderCell>*/}
						{/*<Table.HeaderCell>Anlass der Fahrt</Table.HeaderCell>*/}
						{/*<Table.HeaderCell>Fahrer</Table.HeaderCell>*/}
						{/*<Table.HeaderCell>Bemerkung</Table.HeaderCell>*/}
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{sort( sortedDays )
						.map( ( data ) => {
							let [dateString, trips] = data
							return (<React.Fragment key={dateString}>
								<Table.Row>
									<Table.HeaderCell colSpan={13}>
										<div className="pv3 f4 ttu pv2 pl3 bg-silver"><span className="f5 fw4 black-70">{moment.utc( dateString ).format( "DD.MM.YYYY" )}</span></div>
									</Table.HeaderCell>
								</Table.Row>
								{trips
									.map( ( trip ) => {
										index++
										let out = <TripRow index={index} key={trip.id} trip={trip} />
										return out
									} )}
							</React.Fragment>)
						} )}
				</Table.Body>

			</Table>
		)
	}
}

export default DriveBookTable

const roundTo = ( num = 0, decimalPlaces = 0 ) => {
	return num ? num.toFixed( decimalPlaces ) : 0
}


const TripRow = ( {trip, index} ) => {
	const distance = <span className="b black">{`${trip.distance.toFixed( 2 )}`}</span>
	return <Table.Row onClick={() => {
		console.log( {trip} )
	}}>
		<Table.Cell textAlign="center">
			<div className="black-70 f6" title={trip.id}><b>{index}</b></div>
		</Table.Cell>

		<Table.Cell>
			<div className={"f6"}>{roundTo( trip.mileageStart, 1 )} km</div>
			<div className={"f6"}>{roundTo( trip.mileageEnd, 1 )} km</div>
		</Table.Cell>

		{/*<Table.Cell>*/}
		{/*    <div>{moment(trip.start).format("HH:mm")} Uhr</div>*/}
		{/*    <div>{moment(trip.end).format("HH:mm")} Uhr</div>*/}
		{/*</Table.Cell>*/}


		<Table.Cell>
			<div className=" fw2 truncate-ns">{trip.geoStart.route} {trip.geoStart.street_number}, {trip.geoStart.locality}</div>
			<div className=" fw2 truncate-ns">{trip.geoEnd.route} {trip.geoEnd.street_number}, {trip.geoEnd.locality}</div>
		</Table.Cell>

		<Table.Cell>
			<div className="f6">
				{trip.trip_type === "ePrivate" && "Privat"}
				{trip.trip_type === "eWork" && "Arbeitsweg"}
				{trip.trip_type === "eBusiness" && "Betriebsfahrt"}
			</div>
		</Table.Cell>

		<Table.Cell>
			<div className="f6 black-70"><span>{trip.trip_type === "eBusiness" ? distance : "0,0"} </span>/ <span>{trip.trip_type === "eWork" ? distance : "0,0"} </span>/ <span>{trip.trip_type === "ePrivate" ? distance : "0,0"} </span>
			</div>
		</Table.Cell>

		{/*ZWECK & ANSPRECHPARTNER*/}
		<Table.Cell>
			<div className=" fw2 truncate-ns">{trip.trip_cause}</div>
			<div className=" fw2 truncate-ns">{trip.trip_contact}</div>
		</Table.Cell>

		{/*FAHRER*/}
		<Table.Cell>
			<div className=" fw2 truncate-ns">{trip.trip_driver}</div>
		</Table.Cell>

		{/*BEMERKUNG*/}
		<Table.Cell>
			<div className=" fw2 truncate-ns">{trip.trip_comment}</div>
		</Table.Cell>


		{/*BEMERKUNG*/}
		<Table.Cell>
			<div className=" fw2 truncate-ns">{trip.first_save && moment( trip.first_save ).format( "DD.MM.YYYY" )}</div>
		</Table.Cell>

		{/*/!*BUCHUNGSDATUM*!/*/}
		{/*<Table.Cell>*/}
		{/*    <div className=" fw2 truncate-ns">{moment(trip.day).format("DD.MM.YYYY")}</div>*/}
		{/*</Table.Cell>*/}

		{/*<Table.Cell>*/}
		{/*    {trip.trip_type === "ePrivate" && <span>{Math.round(trip.distance)} km</span>}*/}
		{/*    {!trip.trip_type && <div>{trip.distance > 1 ? <span>{Math.round(trip.distance)}</span> :*/}
		{/*        <span>{trip.distance.toFixed(2)}</span>} km</div>}*/}
		{/*    /!*<div>{moment.duration(trip.duration).humanize()}</div>*!/*/}
		{/*</Table.Cell>*/}

		{/*<Table.Cell>*/}
		{/*{trip.isMixed ? "z" : ""}*/}
		{/*</Table.Cell>*/}
		{/*<Table.Cell>*/}
		{/*{trip.trip_cause}*/}
		{/*</Table.Cell>*/}
		{/*<Table.Cell>*/}
		{/*{trip.trip_company} {trip.trip_contact}*/}
		{/*</Table.Cell>*/}
		{/*<Table.Cell>*/}
		{/*{trip.trip_driver}*/}
		{/*</Table.Cell>*/}
		{/*<Table.Cell>*/}
		{/*{trip.trip_comment}*/}
		{/*</Table.Cell>*/}
		{/*<Table.Cell>*/}
		{/*{trip.trip_lastEdit}*/}
		{/*</Table.Cell>*/}
	</Table.Row>
}

