import {action, observable} from "mobx";
import {User} from "./model/User";
import http from "../service/http";
import store from "./store";

export default class CompanyStore {
    @observable id: string = ""
    @observable title: string = ""
    @observable maxVehicles: number = 0
    @observable loading: boolean = false
    @observable street: string = ""
    @observable streetNumber: string = ""
    @observable country: string = ""
    @observable city: string = ""
    @observable ustId: string = ""
    @observable postcode: string = ""
    @observable notificationEmail: string = ""
    @observable API_TOKEN: string = ""
    @observable plugins: Array<string> = []

    @observable users: Array<User> = []
    @observable userById: Map<string, User> = new Map()
    @observable usersByCategory: Map<string, Array<User>> = new Map() // TODO: Load users into group-buckets

    user?: User

    hydrate({user}) {
        this.user = user
        if (this.user && this.user.companyId) {
            this.get({companyId: user.companyId})

        }
    }

    @action
    putUser(userId, data) {
        const user = store.account.user
        return http.put(`/api/v1/company/${user.companyId}/user/${userId}`, data)
            .then(res => res.data)
            .then(data => {
                // console.log("RES", data)
            })
    }

    @action
    async getUsers() {
        const user = store.account.user
        return http.get(`/api/v1/company/${user.companyId}/users`)
            .then(res => res.data)
            .then(data => {
                this.users = data.users
                this.users.forEach(user => {
                    this.userById.set(user._id, user)
                })
            })
    }

    @action
    update(data) {
        return http.put(`/api/v1/company/${this.user.companyId}`, data)
            .then(res => res.data)
            .then(res => {
                // console.log("COMPANY EDIT", res)
                this.street = res.street
                this.ustId = res.ustId
                this.title = res.title
                this.country = res.country
                this.city = res.city
                this.postcode = res.postcode
                this.notificationEmail = res.notificationEmail
                this.loading = false

                return res

            })
            .catch(err => {
                this.loading = false
                console.error(err)
            })
    }

    @action
    get({companyId}) {
        this.loading = true
        console.info("fetching", {companyId})
        return http.get(`/api/v1/company/${companyId}`)
            .then(response => response.data)
            .then(response => {
                const {company} = response
                company.plugins = company.plugins || []
                Object.assign(this, company)
                if (this.user) {
                    this.user.keycard.eventManager = company.plugins.includes("GeoEvents")
                }
                return company
            })
            .catch(err => {
                console.error("Company not found", err)
            })
    }

    post(company) {
        // const {street, streetNumber, postcode, country, ustId, city} = company
        return http.post(`/api/v1/company/${this.id}`, company)
            .then(response => response.data)
            .then(data => {
                // console.log("company", {data})
            })
            .catch(err => {
                console.error("Company not found", err)
            })
    }
}