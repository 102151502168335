import {action, observable} from "mobx";
import {Vehicle} from "./model/Vehicle";
import {User} from "./model/User";
import http from "../service/http";
import _ from "lodash"
import {Store} from "store/store"

export default class VehicleStore {

	@observable ready = false
	@observable loading = false

	@observable vehicles: Array<Vehicle> = []
	@observable byId: Map<string, Vehicle> = new Map()
	@observable byGroup: Map<string, Array<Vehicle>> = new Map()

	@observable vehicleFilter: string = ""
	@observable vehiclesFiltered: Array<Vehicle> = []
	@observable vehiclesFilteredByGroup: Map<string, Array<Vehicle>> = new Map()
	store: Store

	searchOptions: Array<{
		title: string,
		description: string,
		searchString: string,
		vehicle: Vehicle
	}> = []

	user: User

	constructor( store ) {
		this.store = store
	}

	hydrate( {user} ) {
		this.user = user
		return this.get()
	}

	getVehicleByTrackerId( deviceId ) {
		return _.first( this.vehicles.filter( vehicle => vehicle.deviceId === `${deviceId}` ) )
	}

	@action setVehiclesFiltered( vehicleFilter, vehicles ) {
		let byGroup = new Map()

		vehicles.forEach( vehicle => {
			const groupName = vehicle.vehicleGroup || " Keine Gruppe"
			const group = byGroup.get( groupName ) || []
			group.push( vehicle )
			byGroup.set( groupName, group )
		} )

		this.vehicleFilter = vehicleFilter
		this.vehiclesFilteredByGroup = byGroup
		this.vehiclesFiltered = vehicles
	}


	@action
	addVehicle(data){
		let vehicle = new Vehicle( data )
		this.byId.set( vehicle._id, vehicle )
		const groupName = vehicle.vehicleGroup || " Keine Gruppe"
		const group = this.byGroup.get( groupName ) || []
		if(!group.map(g => g._id).includes(vehicle._id)) group.push( vehicle )
		this.byGroup.set( groupName, group )
		this.vehicles = []
		this.byId.forEach( vehicle => {
			this.vehicles.push(vehicle)
		})
		return vehicle
	}

	@action
	async get() {
		try {
			this.loading = true
			let response = await http.get( "/api/v1/vehicle" )
			let vehicles = response.data

			this.vehicles = vehicles
				.filter( vehicle => vehicle.active )
				.map( data => {
					let vehicle = this.addVehicle(data)
					return vehicle
				} )

			this.vehicles.map( vehicle => {
				const vehicleId = vehicle._id
				this.store.socketBackend.emit( "subscribe:vehicle", {vehicleId} )
				this.store.socket.emit( "subscribe:vehicle", {vehicleId} )
			} )

			this.searchOptions = this.vehicles.map( vehicle => {
				return {
					title       : vehicle.carId,
					description : `${vehicle.vehiclePlate} ${vehicle.title}`,
					searchString: `${vehicle.vehiclePlate} ${vehicle.title} ${vehicle.driverName} ${vehicle.vehicleGroup}`,
					vehicle
				}
			} )
			this.ready = true
			this.loading = false
			return response
		} catch ( error ) {
			console.error( "Error loading vehicles", error )
		}

	}
}
