import {action, observable} from "mobx"
import {toast} from "react-toastify"
import http from "../service/http"
import DeviceStore from "store/DeviceStore"
import CompanyStore from "store/CompanyStore"
import {User} from "./model/User"
import {Account} from "./model/Account"
import VehicleStore from "store/VehicleStore"
import FileStore from "store/FileStore"
import * as queryString from "querystring"
import {PrivacyMode} from "store/model/Device"


export default class AccountStore {
    @observable username: string = ""
    @observable password: string = ""
    @observable ready: boolean = false

    @observable inActive = false

    @observable user: User
    @observable active: Account
    @observable device: DeviceStore
    @observable vehicle: VehicleStore
    @observable company: CompanyStore
    @observable accounts: Array<Account>
    @observable store: any
    @observable loading: boolean
    @observable preparing: boolean = true

    files: FileStore

    constructor(store) {
        this.device = new DeviceStore()
        this.files = new FileStore()
        this.vehicle = new VehicleStore(store)
        this.company = new CompanyStore()
        this.store = store
        let username = localStorage.getItem("username")
        let password = localStorage.getItem("password")
        let permanent = !!localStorage.getItem("permanent")
        let token = sessionStorage.getItem("token")
        console.log( "RECEIVED_MFR_TOKEN")
        if (token) {
            this.loginWithToken(token)
        } else if (username && password) {
            this.login({username, password, permanent})
        } else {
            this.setReady()
        }
    }

    getNewToken() {
        return http.post("/api/v2/account/token", {})
            .then(res => res.data)
            .then(data => {
                this.user.setToken(data.token)
                console.log(data.token)
            })
    }

    @action
    setActiveAccount(account) {
        this.active = account
        this.store.trips.reset()
        this.store.trips.get({account, start: "2018-01-01", end: "2019-01-01"})
    }

    @action
    addAccount({car, carId, mileage, driver}) {
        let url = `/api/account`
        return http.post(url, {car, carId, mileage, driver})
    }

    subscribeToDevice = (device) => {
        const {deviceId} = device
        this.store.socketBackend.emit("subscribe:device", {deviceId})
        this.store.socket.emit("subscribe:device", {deviceId})
    }

    onLog = () => {
    }

    onBackendLog = (data) => {
        if (data.toast) {
            const type = data.toast.type || "info"
            toast[type](data.toast.content, data.toast.options)
        }
        // console.log( "socket:log", data )
    }

    onPing = (ping) => {
        const {deviceId} = ping
        let device = this.device.byId.get(`${deviceId}`)

        if (device && device.privacyMode !== PrivacyMode.PRIVATE) {
            device.updateFromPing(ping)
        }
    }

    onPositionHistory = ({deviceId, history}) => {
        if (!deviceId)
            throw "onPositionHistory event: <deviceId> is empty"
        if (!history)
            return
        // throw `onPositionHistory event: <history> for device ${deviceId} is empty`
        let device = this.device.byId.get(`${deviceId}`)

        if (device)
            device.setPositionHistory(history)
        else
            console.warn(`cannot update history: device ${deviceId} not found`)
    }

    @action
    register(data) {
        return http.post(`/api/user/register/`, data)
    }

    @action
    registerCompanyUser(data) {
        return http.post(`/api/v1/company/user/`, data)
    }

    @action
    logout(reload = true) {
        localStorage.clear()
        sessionStorage.clear()
        reload && document.location && document.location.reload()
    }

    onActivation = () => {
        // console.log( "ACTIVATED" )
        this.user.active = true
        toast.success("Sie haben ihren Account bestätigt!")
    }

    bindSockets() {
        this.store.socket.on("log", this.onLog)

        this.store.socket.on("position", this.onPing)
        this.store.socket.on("history", this.onPositionHistory)

        this.store.socketBackend.emit("subscribe:user", {id: this.user.id})

        this.store.socketBackend.on("activated", this.onActivation)
        this.store.socketBackend.on("log", this.onBackendLog)
    }

    @action
    setReady() {
        this.loading = false
        this.preparing = false
        this.ready = true
        console.log("SET READY")
    }

    @action
    async handleUser(user) {
        this.company.hydrate({user})
        await this.vehicle.hydrate({user})
        this.accounts = this.user.accounts
        this.active = this.user.accounts[0]
        await this.device.loadDevices()

        if (user.isDemo) {
            const {default: DemoPlayer} = await import("../util/demoPlayer")
            const deviceIds = this.device.list.map(d => d.deviceId)
            const statusChanged = status => console.log(`demoPlayer changed status`, status)
            const onHistory = data => this.onPositionHistory(data)
            const onPing = ping => this.onPing(ping)

            const demo = new DemoPlayer(deviceIds, onHistory, onPing, statusChanged)

            const slotId = `defaultMapProps-MapDashboardLayout`

            // window.localStorage.setItem(slotId, JSON.stringify(demo.map))
            window["demo"] = demo
            demo.play()

        } else {
            this.bindSockets()
        }

        this.files.get()
        this.loading = false
        this.device.list.forEach(this.subscribeToDevice)

        this.setReady()
        console.log("PREP NOW FALSE")


        // TODO: ZOHO UPDATE
        // try {
        // 	//@ts-ignore
        // 	// $zoho.salesiq.floatbutton.visible("show");
        // 	//@ts-ignore
        // 	window.$zoho.salesiq.visitor.email(this.user.email)
        // 	//@ts-ignore
        // 	window.$zoho.salesiq.visitor.name(this.user.name)
        // } catch (err) {
        // 	console.error("ERROR W/ ZOHO SETUP")
        // }
    }

    @action
    login({username, password, save = true, permanent = true, mfr = false}) {
        this.loading = true
        this.ready = false
        this.preparing = true
        username = `${username}`.toLowerCase()
        let url = `/api/user/login?${queryString.stringify({permanent, mfr})}`
        // let url = `/api/user/login/${permanent ? "?permanent=true" : ""}`

        return http.post(url, {username, password})
            .then(async response => {
                // this.accounts = [new Account(response.data.user)]
                let user = new User(response.data.user, response.data.userProfile)
                this.user = user
                this.handleUser(user)

                if (this.user) {
                    this.store.init()
                    if (save) {
                        localStorage.setItem("username", username)
                        localStorage.setItem("password", password)
                        permanent && localStorage.setItem("permanent", "true")
                    }
                }
                return this.user
            }).catch(err => {
                console.error(err)
                this.ready = true
                this.loading = false
                this.preparing = false
                toast.warn("Fehler bei der Anmeldung", err)
            })
    }

    @action
    loginWithToken(token) {
        this.loading = true
        this.preparing = true
        let url = `/api/user/me`
        http.setToken(token)
        return http.get(url)
            .then(async response => {

                console.log({user: response.data})
                // this.accounts = [new Account(response.data.user)]
                let user = new User(response.data.user, response.data.userProfile)
                this.user = user
                this.handleUser(user)

                if (this.user) {
                    this.store.init()
                    sessionStorage.setItem("token", token)
                    sessionStorage.setItem("mfr_login", token)
                }
                return this.user
            }).catch(err => {
                console.error(err)
                this.ready = true
                this.loading = false
                this.preparing = false
                toast.warn("Fehler bei der Anmeldung", err)
            })
    }

}
