import {Button} from "semantic-ui-react";
import {Link, useLocation, useParams, withRouter} from "react-router-dom";
import moment from "moment";
import * as React from "react";
import {useEffect} from "react";
import Icon from "component/Icon"
import {faTimes} from "@fortawesome/pro-solid-svg-icons"
import parseQuery from "util/parseQuery"
import create from "zustand"
import "./PageHeader.css"

const [usePageContext, headerApi] = create( set => ({
	title     : null,
	children  : null,
	setContext: ( {title = null, children = null} ) => set( state => ({title, children}) )
}) )


export function PageHeaderContext( {children, title, close}: any ) {
	useEffect( () => {
		headerApi.setState( {children, title, close} )
		return () => {
			headerApi.setState( {children: null, title: null, close: undefined} )
		}
	}, [children, title] )
	return null
}

export function PageHeaderSimple( props: { title: any, children?: any, close?: boolean } ) {
	const location = useLocation()
	const params = useParams()
	const {vehicleId} = parseQuery( {location, match: {params}} )

	let children = usePageContext( state => state.children ) || props.children
	let title = usePageContext( state => state.title ) || props.title
	let close = usePageContext( state => state.close )
	if ( close === undefined ) close = <div className="pl2 ">
		<Button size={"small"} as={Link} basic to={`/map/${vehicleId}/`}><Icon icon={faTimes} /></Button>
	</div>

	return <div className="pa3 flex flex-row justify-between items-center bg-black-05 PageHeaderSimple">
		<div className="b f3-ns f5">{title}</div>
		<div className={"flex flex-row items-center justify-end"}>
			{children}
			{close}
		</div>
	</div>
}

const PageHeader = ( {loading = false, device, startDate, endDate, title = "Bericht"} ) => {
	if ( !device ) return null
	return <div className="cf pb2">
		<div className="fr tr">
			<Button icon={"print"}
					circular
					onClick={() => {
						window.print()
					}}
			/>

			<Button
				loading={loading}
				circular
				basic
				icon={<Icon icon={faTimes}/>}
				as={Link} to={{
				pathname: "/map",
				search  : `?deviceId=${device.deviceId}`
			}} />
		</div>
		<div className="fw2 f5">
			<div className={"f3 pv2"}>{title} für {device && device.carId}</div>
			<div>{moment( startDate ).format( "DD.MM.YYYY" )} - {moment( endDate ).format( "DD.MM.YYYY" )}</div>
		</div>
	</div>
}

export default PageHeader
