const styleDefault = [
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ffbb00"
      },
      {
        "saturation": "-53"
      },
      {
        "lightness": 37.599999999999994
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#00a1ff"
      },
      {
        "saturation": "1"
      },
      {
        "lightness": "0"
      },
      {
        "gamma": 1
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ffbd00"
      },
      {
        "saturation": "0"
      },
      {
        "lightness": "0"
      },
      {
        "gamma": 1
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ff0300"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 51.19999999999999
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ff0300"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 52
      },
      {
        "gamma": 1
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#0078FF"
      },
      {
        "saturation": -13.200000000000003
      },
      {
        "lightness": 2.4000000000000057
      },
      {
        "gamma": 1
      }
    ]
  }
]

const styleDark = [
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "saturation": 36
      },
      {
        "color": "#000000"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#000000"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#919191"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "weight": "0.01"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#6f602f"
      },
      {
        "lightness": 17
      },
      {
        "gamma": "0.75"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 29
      },
      {
        "weight": 0.2
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2d291a"
      },
      {
        "lightness": 18
      },
      {
        "weight": "1"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#53461b"
      },
      {
        "lightness": 16
      },
      {
        "weight": "0.50"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 17
      }
    ]
  }
]

const styleMidnight = [
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#aeaeaf"
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#000000"
      },
      {
        "lightness": 13
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#144b53"
      },
      {
        "lightness": 14
      },
      {
        "weight": 1.4
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "all",
    "stylers": [
      {
        "color": "#08304b"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#0c4152"
      },
      {
        "lightness": 5
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#0b434f"
      },
      {
        "lightness": 25
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#0b3d51"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "color": "#146474"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#021019"
      }
    ]
  }
]

const styleCalmDetailedGrayscale = [
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				saturation: 36
			},
			{
				color: "#333333"
			},
			{
				lightness: 40
			}
		]
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#ffffff"
			},
			{
				lightness: 16
			}
		]
	},
	{
		featureType: "all",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "all",
		stylers: [
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#fefefe"
			},
			{
				lightness: 20
			}
		]
	},
	{
		featureType: "administrative",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#fefefe"
			},
			{
				lightness: 17
			},
			{
				weight: 1.2
			}
		]
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				lightness: 20
			},
			{
				color: "#ececec"
			}
		]
	},
	{
		featureType: "landscape.man_made",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#f0f0ef"
			}
		]
	},
	{
		featureType: "landscape.man_made",
		elementType: "geometry.fill",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#f0f0ef"
			}
		]
	},
	{
		featureType: "landscape.man_made",
		elementType: "geometry.stroke",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#d4d4d4"
			}
		]
	},
	{
		featureType: "landscape.natural",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#ececec"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				lightness: 21
			},
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "geometry.fill",
		stylers: [
			{
				visibility: "on"
			},
			{
				color: "#d4d4d4"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#303030"
			}
		]
	},
	{
		featureType: "poi",
		elementType: "labels.icon",
		stylers: [
			{
				saturation: "-100"
			}
		]
	},
	{
		featureType: "poi.attraction",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.business",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.government",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.medical",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.park",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				color: "#dedede"
			},
			{
				lightness: 21
			}
		]
	},
	{
		featureType: "poi.place_of_worship",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.school",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "poi.school",
		elementType: "geometry.stroke",
		stylers: [
			{
				lightness: "-61"
			},
			{
				gamma: "0.00"
			},
			{
				visibility: "off"
			}
		]
	},
	{
		featureType: "poi.sports_complex",
		elementType: "all",
		stylers: [
			{
				visibility: "on"
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.fill",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 17
			}
		]
	},
	{
		featureType: "road.highway",
		elementType: "geometry.stroke",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 29
			},
			{
				weight: 0.2
			}
		]
	},
	{
		featureType: "road.arterial",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 18
			}
		]
	},
	{
		featureType: "road.local",
		elementType: "geometry",
		stylers: [
			{
				color: "#ffffff"
			},
			{
				lightness: 16
			}
		]
	},
	{
		featureType: "transit",
		elementType: "geometry",
		stylers: [
			{
				color: "#f2f2f2"
			},
			{
				lightness: 19
			}
		]
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [
			{
				color: "#dadada"
			},
			{
				lightness: 17
			}
		]
	}
]


const styles = {
  styleDefault,
  styleMidnight,
  styleDark,
  styleCalmDetailedGrayscale
}

export default styles