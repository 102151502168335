import React, {Component} from "react"
import "./BaseLayout.css"

class BaseLayout extends Component {
  render() {
    return (
      <div className="BaseLayout h-100 min-h-bug ">
        {this.props.children}
      </div>
    )
  }
}

export default BaseLayout
