import * as React from "react"
import { useTable, useSortBy } from 'react-table'
import { Table } from "semantic-ui-react"
import "./TableBase.css"

// https://github.com/tannerlinsley/react-table/blob/master/docs/examples.md

export default function TableBase( { columns, data, ...rest } ) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable( {
    columns,
    data,
  }, useSortBy )

  // Render the UI for your table
  return (
    <Table {...getTableProps()} {...rest}>
      <Table.Header>
        {headerGroups.map( headerGroup => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map( column => (
              <Table.HeaderCell
                {...column.getHeaderProps( column.getSortByToggleProps() )}
              >
                <div
                  className={`${
                    column.align === "right"
                      ? "header-align-right"
                      : "header-align-left"
                  }`}
                >
                  <div className="header">
                    {column.render( "Header" )}
                    {column.Subheader && (
                      <div className="subheader">{column.Subheader}</div>
                    )}
                  </div>
                  <span
                    className={`marker marker-${
                      column.isSorted ? "sorted" : "unsorted"
                    }`}
                  >
										{column.isSortedDesc ? "🔽" : "🔼"}
									</span>
                </div>
              </Table.HeaderCell>
            ) )}
          </Table.Row>
        ) )}
      </Table.Header>
      <Table.Body {...getTableBodyProps()}>
        {rows.map(
          ( row, i ) =>
            prepareRow( row ) || (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map( cell => {
                  return (
                    <Table.Cell
                      {...cell.getCellProps( [
                        {
                          className: cell.column.className,
                          style    : cell.column.style,
                        }
                      ] )}
                    >
                      {cell.render( "Cell" )}
                    </Table.Cell>
                  )
                } )}
              </Table.Row>
            )
        )}
      </Table.Body>
    </Table>
  )
}


/* 

											<div
												style={{
													display: "inline-block",
													position: "relative",
													marginLeft: "0.2em"
												}}
											>
												Strecke
												<div
													style={{
														fontSize: "xx-small",
														fontWeight: "lighter",
														position: "absolute",
														bottom: "-1.5em",
														right: "0"
													}}
												>
													
												</div>
											</div>

*/