import * as React from "react"
import Icon from "component/Icon"
import {Button, Input, Form, Segment} from 'semantic-ui-react'
import {Link} from "react-router-dom"
import {observer} from "mobx-react"
import store from "store/store"
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

@observer
class LoginForm extends React.Component<any, any> {

    state = {
        showPassword: false
    }

    loginDemo = async () => {
        const username = "demo@flottr.com"
        const password = "tolle demo"
        const permanent = true
        window["REF_URL"] = "/demo"
        let session
        try {
            session = await store.account.login({username, password, permanent})
            if (session) {
                this.props.onSuccess()
            }
        } catch (err) {
            // console.log.warn( err );
        }
        // console.log.log( session );
    }

    handleSubmit = async (event) => {
        const {mfr} = this.props

        event.preventDefault();
        const data = new FormData(event.target);
        let username = String(data.get("username"))
        let password = String(data.get("password"))
        let permanent = !!data.get("permanent")
        let session
        try {
            session = await store.account.login({username, password, permanent, mfr})
            if (session) {
                this.props.onSuccess()
            }
        } catch (err) {
            console.warn(err);
        }
        // console.log.log( session );
    }

    render() {
        const {password, username, loading} = store.account
        const {showPassword} = this.state
        return (
            <div className="mw6 center ph3 fadeIn animate">
                <div className={"pa3-ns pa3 box-shadow LoginSegment bg-white"}>
                    <div className="pa4-ns">
                        <h2 className={"b tc f1-ns  pb3"}>Willkommen</h2>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Field>
                                <label htmlFor="username"><span className="ttu">E-Mail</span></label>
                                <Input name="username" type={"email"} defaultValue={username} placeholder="E-Mail"/>
                            </Form.Field>
                            <Form.Field>
                                <label htmlFor="password"><span className="ttu">Passwort</span></label>
                                <Input name="password" defaultValue={password}
                                       type={showPassword ? "text" : "password"}
                                       icon={<i
                                           className={"pointer o-60"}
                                           style={{
                                               background: "transparent",
                                               position: "absolute",
                                               right: "10px",
                                               top: 0,
                                               margin: "auto",
                                               display: "flex",
                                               justifyContent: "center",
                                               height: " 100%",
                                               alignItems: "center",
                                           }}
                                       ><Icon className={""} icon={(showPassword) ? faEye : faEyeSlash}
                                              onClick={() => {
                                                  this.setState({showPassword: !this.state.showPassword})
                                              }}/></i>}
                                       placeholder="Passwort"/>
                            </Form.Field>

                            <div className="pt4">
                                <Button fluid color={"yellow"} type="submit" loading={loading}>
                                    <div className="b ttu">Anmelden</div>
                                </Button>
                            </div>

                            <div className="pt3">
                                <Button basic fluid as={Link} to={"/registration"} loading={loading}>
                                    <div className="b ttu">Neues Konto</div>
                                </Button>
                            </div>

                            <div className="pt3">
                                <a
                                    className={"black-70 f6 tc"}
                                    href={"https://app.flottr.com/demo-login"}
                                ><span className=""><div
                                    className="">Demo Zugang</div></span></a>
                            </div>
                        </Form>
                    </div>
                </div>

                <div className="pt2">
                    <div className="absolute-ns bottom-1 right-1 left-1 flex flex-row justify-around justify-end-ns ">
                        <div className="animate fadeInUpSmall duration-800 delay-500">


                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default LoginForm