import {faMapMarkerAlt} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import SimpleMap from "component/Map/SimpleMap.js"
import * as React from "react"
import {useEffect, useState} from "react"
import {Button, Image} from "semantic-ui-react"
import http from "service/http.ts"
import Icon from "component/Icon"
import {animated, config, useSpring} from "react-spring"
import "./Share.css"
import io from "socket.io-client"
import {faChevronUp} from "@fortawesome/free-solid-svg-icons"
import moment from "moment"

const GPS_SERVER_URL = process.env.REACT_APP_GPS_SERVER_URL || "https://gps.mobilefieldreport.com"

// CONNECT TO WEBSOCKET
const connect = () => {
	let socket = io( GPS_SERVER_URL, {transports: ["websocket"]} )
	return socket
}

let socket = connect()
// socket.emit( "subscribe:debug" )

socket.on( "log", ( log ) => {
	// console.log.log( "log", log )
} )


function ShareWelcome( {onClick, share} ) {

	const [open, setOpen] = useState( true )
	const [bgProps, setBg] = useSpring( () => ({
		opacity     : 1,
		width       : `${window.innerWidth}px`,
		height      : `${window.innerHeight}px`,
		borderRadius: "0px",
		transform   : `scale(1)`,
		bottom      : "0rem",
		background  : "#363636",
		config      : config.stiff
	}) )
	const [imageProps, setImage] = useSpring( () => ({opacity: 1, config: config.wobbly}) )
	const [iconProps, setIcon] = useSpring( () => ({opacity: 0, config: config.wobbly}) )


	const handleContinue = () => {
		setBg( {width: "50px", height: "50px", borderRadius: "50px", opacity: 1, bottom: "2.5rem", background: "#020202"} )
		setImage( {opacity: 0} )
		setIcon( {opacity: 1} )
		setOpen( false )
	}

	useEffect( () => {
		// if( share){
		// 	setBg( { width: "50px", height: "50px", borderRadius: "50px", opacity: 1, bottom: "2.5rem", background: "#020202" } )
		// 	setImage( { opacity: 0 } )
		// 	setIcon( { opacity: 1 } )
		// }
	}, [share] )

	// useChain( [bgRef] , [1] /*1000*/ )

	return <animated.a
		onClick={() => {
			// console.log.log( "CLICK" )
			!open && onClick()
		}} style={bgProps} className="ShareWelcome center pointer bg-black absolute  bottom-0 left-0 right-0 z-2 ">
		<div className="h-100 w-100 flex flex-column items-center justify-center relative">
			<div className="tc overflow-hidden">
				<animated.div
					className={"absolute top-0 pt2 mt1 left-0 right-0 bottom-0 no-pointer-events"}
					style={iconProps}
				>
					<Icon className={"f3 mt1 white"} icon={faMapMarkerAlt} />
				</animated.div>
				<animated.div
					className={""}
					style={imageProps}
				>
					<Image width={220} src={"/brand/flottr-brand-dark@600w.png"} style={{
						filter: "hue-rotate(155deg) brightness(4.5) saturate(5)"
					}} />
					<div className={"white pt4 f4 tl"}>
						<div className={"b f3 pb3"}>Positionsfreigabe</div>
						{share && <React.Fragment>
							<div className={"b f5"}>Kennzeichen</div>
							<div>{share.licensePlate}</div>

							<div className="pt3 b f5">Gültigkeit</div>
							<div>{moment.utc( share.validFrom ).format( "DD. MM. YYYY HH:MM [Uhr]" )}</div>
							<div>bis {moment( share.validTo ).format( "HH:MM [Uhr]" )}</div>

							<div className="pt3">
								<Button circular onClick={handleContinue} color={"yellow"}>Position verfolgen</Button>
							</div>
						</React.Fragment>}
					</div>

				</animated.div>

			</div>
		</div>
	</animated.a>
}

let google = false
export default function Share( props ) {
	const {shareId} = props.match.params
	const [error, setError] = useState( false )
	const [share, setShare] = useState( {} )

	const [ping, setPing] = useState( {angle: 0, speed: 0, lat: 51.9, lng: 10.7} )


	const handleGoogleAPI = ( g ) => {
		google = g
		// console.log.log( "google", google )
	}

	const setCenter = ( pos ) => {
		pos = pos || ping
		google && google.map.setCenter( pos )
	}


	async function fetchData() {
		const res = await http.get( `/api/mfr/share/${shareId}` )
		if ( res.status === 200 ) {
			socket.emit( "subscribe:share", {shareId} )
			socket.on( "position", ( ping, data ) => {

				if ( ping.deviceId === share.deviceId ) {
					ping && setPing( ping )
				}
				// console.log.log( "ping", ping, data )
			} )
			setShare( res.data.share )
			setPing( {...ping, ...res.data.health} )
		} else {
			setError( "Tracker nicht verfügbar" )
		}
	}

	useEffect( () => {
		fetchData()
	}, [shareId] )


	// console.log.log( {share, error} )
	return <div className="Share">

		<ShareWelcome onClick={setCenter} share={share} />

		<SimpleMap
			yesIWantToUseGoogleMapApiInternals
			onGoogleApiLoaded={handleGoogleAPI}
			zoom={12}
			center={{
				lat: ping.lat,
				lng: ping.lng
			}}
			className={"absolute left-0 right-0 top-0 bottom-0"}
			style={{
				width : "100vw",
				height: "100vh"
			}}
		>
			<Tracker
				vehicle={share}
				ping={ping}
				angle={ping.angle}
				lat={ping.lat}
				lng={ping.lng} />
		</SimpleMap>
	</div>
}

export function Tracker( {lat, lng, vehicle, ping, text} ) {
	const [pulse, setPulse] = useState( false )
	window.setPulse = setPulse

	return <div className={`Tracker relative ${pulse ? "pulse" : ""}`}>
		<div className="pulseAnimation" />
		<div
			style={{
				transform: `rotate(${ping.angle}deg)`
			}}
			className="icon f5 tc white absolute left-0 right-0 top-0 bottom-0 w-100 h-100 flex flex-row items-center justify-center"><Icon icon={faChevronUp} /></div>
		<div className="bg-white">
			<div className="text">
				{text && text}
				{!text && <div className="">{vehicle.vehiclePlate || vehicle.licensePlate}</div>}
			</div>
			{/*{JSON.stringify( ping, null, 2 )}*/}
		</div>
	</div>
}

