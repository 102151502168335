import {observer} from "mobx-react"
import * as React from "react"

import {toast} from "react-toastify"
//@ts-ignore
import {withRouter} from "react-router"
import {Link} from "react-router-dom"
import { Button, ButtonProps, Divider, Form, Image, Input, Modal, Segment } from "semantic-ui-react"
import http from "service/http"
import {Vehicle} from "store/model/Vehicle";
import store from "../../store/store";
import Dropzone from "react-dropzone";
import moment from "moment"
import { useState } from "react"

//@ts-ignore
@withRouter @observer
class VehicleForm extends React.Component<any, any> {

    state = {
        tracker: false,
        loading: false,
        uploadingVehicle: false,
        uploadingDriver: false,
        drivebookEmail: this.props.vehicle.drivebookEmail || "",
        drivebookNotificationMode: this.props.vehicle.drivebookNotificationMode || false,
        deviceId: this.props.vehicle.deviceId || ""
    }

    handleDropDriver = async (files) => {
        const {vehicle} = this.props
        if (this.state.uploadingDriver) {
            return
        }

        this.setState({uploadingDriver: true})

        const file = {
            vehicleId: vehicle._id,
            categoryId: "media",
            localFile: files[0]
        }

        const response = await store.account.files.uploadFile(file)
        const {fileId} = response
        if (response.url) {
            await vehicle.put({driverImage: response.url})
            vehicle.driverImage = response.url
            // console.log.log( "UPLOAD", {fileId} )
        }

        this.setState({uploadingDriver: false})
    }

    handleDrop = async (files) => {
        const {vehicle} = this.props
        if (this.state.uploadingVehicle) {
            return
        }

        this.setState({uploadingVehicle: true})

        const file = {
            vehicleId: vehicle._id,
            categoryId: "media",
            localFile: files[0]
        }

        const response = await store.account.files.uploadFile(file)
        const {fileId} = response
        if (response.url) {
            await vehicle.put({vehicleImage: response.url})
            vehicle.vehicleImage = response.url
            // console.log.log( "UPLOAD", {fileId} )
        }

        this.setState({uploadingVehicle: false})
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const vehicle: Vehicle = this.props.vehicle
        if (!vehicle) return
        this.setState({loading: true})
        const data = new FormData(event.target);

        let drivebookNotificationMode
        let drivebookEmail
        let deviceId
        if (store.account.user.keycard.userManager) {
            drivebookNotificationMode = this.state.drivebookNotificationMode
            drivebookEmail = this.state.drivebookEmail
            deviceId = this.state.deviceId
            // console.log( {drivebookNotificationMode, drivebookEmail} )
        }

        let update: any = {
            drivebookNotificationMode,
            drivebookEmail,
            vehiclePlate: String(data.get("vehiclePlate")),
            title: String(data.get("title")),
            vehicleManufacturer: String(data.get("vehicleManufacturer")),
            vehicleGroup: String(data.get("vehicleGroup")),
            mileage: String(data.get("mileage")),
            deviceId,
            driverName: String(data.get("driverName")),
            mileage_date: undefined
        }

        if (update.mileage) {
            update.mileage_date = moment().toISOString()
        }

        http.put(`/api/v1/vehicle/${vehicle._id}`, update)
            .then(res => {
                // console.log.log( {res, vehicle} )
                this.setState({loading: false})
                vehicle.update(res.data.vehicle)
                const {to = `/map/${vehicle._id}/`} = this.props
                this.props.history.replace(to)
                this.props.onClose && this.props.onClose()
                toast.success("Fahrzeug gespeichert.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
            .catch(err => {
                this.setState({loading: false})
                toast.error("err")
                // console.log.error( err )
            })
    }

    update(data) {
        const vehicleId = String(data.get("vehicleId"))
        // console.log.log( "UPDATE", {vehicleId} )
    }

    render() {
        const {loading, uploadingDriver, uploadingVehicle} = this.state
        const vehicle: Vehicle = this.props.vehicle
        const {to = `/map/${vehicle._id}/`} = this.props
        if (!vehicle) return <div>Kein Fahrzeug</div>
        // console.log( {vehicle} )
        return <div className="DeviceDataForm ph4-ns pt3-ns pb4-ns ">

            <div className="flex flex-row-ns flex-column">

                <div className="vehicle w-100 pr3-ns">
                    <h3>Fahrzeug</h3>
                    <Dropzone accept={'image/jpeg, image/png'} multiple={false} onDrop={this.handleDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return <div
                                {...getRootProps()}
                                className={`mb3 dropzone ${isDragActive && "dropzone-active"}`}
                            >
                                <input {...getInputProps()} />
                                <Segment loading={uploadingVehicle} className={"tc"}>
                                    {vehicle.vehicleImage && <Image width={160} src={vehicle.vehicleImage}/>}
                                    {!vehicle.vehicleImage && <Button basic>Fahrzeugbild hinzufügen</Button>}
                                </Segment>
                            </div>
                        }}
                    </Dropzone>
                </div>

                <div className="driver w-100 pl3-ns">
                    <h3>&nbsp;</h3>
                    <Dropzone accept={'image/jpeg, image/png'} multiple={false} onDrop={this.handleDropDriver}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return <div
                                {...getRootProps()}
                                className={`mb3 dropzone ${isDragActive && "dropzone-active"}`}
                            >
                                <input {...getInputProps()} />
                                <Segment loading={uploadingDriver} className={"tc"}>
                                    {vehicle.driverImage && <Image circular width={120} src={vehicle.driverImage}/>}
                                    {!vehicle.driverImage && <Button basic>Fahrerbild hinzufügen</Button>}
                                </Segment>
                            </div>
                        }}
                    </Dropzone>
                </div>

            </div>

            <Form onSubmit={this.handleSubmit}>
                <Modal.Content>
                    <div className="flex flex-row-ns flex-column">
                        {/* FAHRZEUG */}
                        <div className={"w-100 pr3-ns"}>
                            <Form.Field>
                                <label htmlFor="form-name"> Name</label>
                                <Input id="form-name"
                                       name="title"
                                       defaultValue={vehicle.title}
                                       placeholder="Einsatzwagen A"/>
                            </Form.Field>

                            <Form.Field>
                                <label htmlFor="form-vehiclePlate">Kennzeichen</label>
                                <Input id="form-vehiclePlate"
                                       defaultValue={vehicle.vehiclePlate}
                                       name="vehiclePlate"
                                       placeholder="Kennzeichen"/>
                            </Form.Field>


                            <Form.Field>
                                <label htmlFor="form-manufacturer">Marke</label>
                                <Input id="form-manufacturer"
                                       name="vehicleManufacturer"
                                       defaultValue={vehicle.vehicleManufacturer}
                                       placeholder="Audi, Mercedes, BMW"/>
                            </Form.Field>



                        </div>

                        {/*FAHRER*/}
                        <div className={"w-100 pl3-ns"}>
                            <Form.Field>
                                <label htmlFor="form-driverName">Name (Fahrer)</label>
                                <Input id="form-driverName"
                                       defaultValue={vehicle.driverName}
                                       name="driverName"
                                       placeholder="Max Muste..."/>
                            </Form.Field>


                            <Form.Field>
                                <label htmlFor="form-manufacturer">Gruppe</label>
                                <Input id="form-manufacturer"
                                       defaultValue={vehicle.vehicleGroup}
                                       name="vehicleGroup"
                                />
                            </Form.Field>
                        </div>


                    </div>

                    <Divider/>
                    <div className="">
                        <h3>Fahrtenbuch</h3>
                        {!store.account.user.keycard.userManager && <div>
                          <div className="pv3 black-70">
                            Diese Einstellungen werden von Ihrem Account-Manager verwaltet.
                          </div>

                        </div>}

                        <div className="animated fadeInDown pb3">
                            <Form.Field>
                                <Form.Checkbox
                                    checked={this.state.drivebookNotificationMode}
                                    onChange={(e, data) => {
                                        this.setState({drivebookNotificationMode: !this.state.drivebookNotificationMode})
                                    }}
                                    defaultValue={this.state.drivebookNotificationMode}
                                    id="form-drivebookEmailId" name={"drivebookEmail"} fluid label="Sende tägliche Fahrtenbucherinnerungen"/>
                            </Form.Field>
                        </div>

                        <div className="animated fadeInDown pb3">
                            <Form.Field>
                                <Form.Input
                                    onChange={(e) => {
                                        this.setState({drivebookEmail: e.currentTarget.value})
                                    }}
                                    defaultValue={this.state.drivebookEmail}
                                    id="form-drivebookEmailId" name={"drivebookEmail"} fluid label="E-Mail"/>
                            </Form.Field>
                        </div>

                        <Form.Field>
                            <label htmlFor="form-mileage"><span className="o-40">Kilometerstand {vehicle.mileageDate && <span>(Fahrtenbuch am {vehicle.mileageDate})</span>}</span></label>
                            <Input id="form-mileage"
                                   disabled={!!vehicle.mileage}
                                   name="mileage"
                                   defaultValue={vehicle.mileage}
                                   placeholder=""
                                   labelPosition="right"
                                   label={"km"}
                            />
                        </Form.Field>
                    </div>


                    <Divider/>
                    <h3>Features</h3>
                    <div className="flex flex-row items-center">
                        <div className="pr3">
                            <div className="b">Easy Drive</div>
                            {vehicle.featureByKey["FEATURE_BASE"] ? <div>Aktiv</div> : <div>Inaktiv</div>}</div>
                        <div className="pr3">
                            <div className="b">Fahrtenbuch</div>
                            {vehicle.featureByKey["FEATURE_DRIVEBOOK"] ? <div>Aktiv</div> : <div>Inaktiv</div>}</div>
                        <div className="pr3">
                            <div className="b">Echtzeit</div>
                            {vehicle.featureByKey["FEATURE_REALTIME"] ? <div>Aktiv</div> : <div>Inaktiv</div>}</div>
                    </div>
                    <Divider/>


                    <h3>GPS</h3>
                    {store.account.user.keycard.deviceManager &&
                    <React.Fragment>
                    <div className="flex flex-row pb3">
                        <div className="animated fadeInDown  ns-w-50 pa2">
                            <Form.Field>
                                <Form.Input
                                  onChange={e => {
                                      this.setState({deviceId: e.currentTarget.value})
                                  }}
                                  value={this.state.deviceId}
                                  id="form-deviceId" name={"deviceId"} fluid label="Tracker IMEI  (OPTIONAL)"/>
                            </Form.Field>
                        </div>

                    </div>


                    </React.Fragment>


                    }
                    {!store.account.user.keycard.deviceManager &&
                    <div>
                        {this.state.deviceId ? "Ja" : "Nein"}
                    </div>}


                </Modal.Content>

                <Divider/>
                <div className="flex flex-row justify-end">
                    <Button role="button" type="button" as={Link}
                            to={to}>Zurück</Button>
                    <Button primary loading={loading}>Speichern</Button>
                </div>
            </Form>
        </div>
    }
}



export const ArchiveVehicle = ({vehicle, size="medium"}:any) => {

    const [open, setOpen] = useState(false)


    function archiveThis(){
        vehicle.update({ active:false })
        console.log("ARCHIVE VEHICLE", vehicle)
        http.put(`/api/v1/vehicle/${vehicle._id}`, {active:false})
          .then(res => {
              // console.log.log( {res, vehicle} )
              vehicle.update(res.data.vehicle)
              store.account.vehicle.addVehicle(vehicle)
              toast.success(`${vehicle.vehiclePlate} archiviert`, {
                  position: toast.POSITION.BOTTOM_CENTER
              });

          })
          .catch(err => {
              toast.error("Fehler beim Archivieren ihres Fahrzeugs.")
              vehicle.update({ active:true })
              // console.log.error( err )
          })
        setOpen(false)
    }

    return <div>
        <Modal size={"small"} open={open}>
            <Modal.Header>{vehicle.vehiclePlate || "Fahrzeug"}{" "} archivieren?</Modal.Header>
            <Modal.Content>
                <div>
                    Hiermit entfernen Sie das Fahrzeug aus Ihrem Konto.
                </div>
               
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={() => {
                    setOpen(false)
                }}>Zurück</Button>
                <Button color={"red"} onClick={archiveThis}> Fahrzeug archivieren</Button>
            </Modal.Actions>
        </Modal>
        <Button
          size={size}
          type={"button"}
          onClick={() => {
                setOpen(true)
          }}
          basic color={"red"}>
            Fahrzeug archivieren
        </Button>
    </div>
}


export default VehicleForm