

export default class Location {

  name: string
  id: string
  street: string
  postcode: string
  city: string
  country: string
  company: string
  person: string
  meetings: string // what kind of meetings usually take place there
  usage: number // total (recent) usage
  isFavorite: boolean

  constructor({id, name, street, postcode, city, country, usage, meetings, isFavorite, person}) {
    Object.assign(this, {id, name, street, postcode, city, country, usage, meetings, isFavorite, person})
  }
}
