import { action, observable } from "mobx"
import { Device } from "./model/Device"
import http from "../service/http"
import store from "./store";
import {toast} from "react-toastify"

export default class DeviceStore {
	@observable byId: Map<string, Device>
	@observable list: Array<Device>

	@observable searchOptions: Array<any> = []

	constructor() {
		this.byId = new Map()
		this.list = []
		window["deviceStore"] = this
	}

	@action
	loadDevices() {
		return http.get("/api/user/devices")
			.then(res => {
				if(res.data.company.active === false){
					console.log("COMPANY OFF ")
					if (store.account.user.keycard.companyManager) {
						// toast.info("Account: Zugriff nur über Administrationsaccount.")
					}
				}
				return res.data.devices
			})
			.then(devices => {
				this.byId = new Map()
				this.list = []
				let activeDevices = devices
					.filter(device => {
						return device.deviceId
					})
					.map(data => {
						let device = new Device(data)
						this.byId.set(`${device.deviceId}`, device)
						return device
					})

				this.list.push(...activeDevices)

				this.searchOptions = this.list.map(device => {
					const entry = {
						title: device.carId,
						description: `${device.carId} ${device.carName}`,
						searchString: `${device.carId} ${device.carName} ${device.deviceId}`,
						device
					}
					return entry
				})
				// console.log.log(`DEVICES LOADED`)
				return this.list
			})
			.catch(err => {
				console.error("ERROR while fetching devices", err)
				return []
			})
	}
}

