import * as React from "react"
import { Route } from "react-router-dom"


const RouteWithLayout = ( { layout, component, path , footer = null, exact = false } ) => {
  return (
    <Route exact={exact} path={path} render={( props ) =>
      React.createElement( layout, props
        , React.createElement( component, props )
        , footer ? React.createElement( footer, props ) : null
      )
    }/>
  );
}

export default RouteWithLayout
