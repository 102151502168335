import {Container, Menu} from "semantic-ui-react";
import * as React from "react";


const FooterLegal = () =>     <div className={"order-footer fixed left-0 bottom-0 w-100"}>
    <div className="flex flex-row pa2">
        <a target={"_blank"} className={"ph2 pv1 black-50 hover-black-80 f6"} href={"https://flottr.com/agb.html"}>AGB</a>
        <a target={"_blank"} className={"ph2 pv1 black-50 hover-black-80 f6"} href={"https://flottr.com/impressum.html"}>Impressum</a>
        <a target={"_blank"} className={"ph2 pv1 black-50 hover-black-80 f6"} href={"https://flottr.com/datenschutz.html"}>Datenschutz</a>
        {/*<a target={"_blank"} className="ph2 pv1 black-50 hover-black-80 f6" href={"https://flottr.com/preise"}>Preise</a>*/}
        <a target={"_blank"} className={"ph2 pv1 black-50 hover-black-80 f6"} href={"https://flottr.com/agb.html"}>Widerruf</a>
    </div>

</div>


export default FooterLegal