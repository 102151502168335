//eslint-disable import/first
import * as React from "react"
import * as ReactDOM from "react-dom"
import App from "./App"

// import reportWebVitals from './reportWebVitals';
import "semantic-ui-css/semantic.min.css"

import "./tachyons.css"
import "./index.css"
import "./animate.css"
import "./print.css"

import moment from "moment"
import "moment/locale/de"
import { unregister } from "./registerServiceWorker"


moment.locale("de")


window.setTimeout(()=>{
	const $title = document.querySelector("#titlediv") as HTMLElement
	if ( $title ) {
		$title.style.display = "none"
	}
}, 12000)

// console.log("Version", process.env.REACT_APP_VERSION)

ReactDOM.render(
	<App />,
	document.getElementById("root")
)

unregister()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

