import { observer } from "mobx-react"
import "page/Map.css"
import "page/MapPage.css"
import React from "react"
import { withRouter } from "react-router-dom"
import { MapDashboardLayout } from "./MapDashboardLayout"

export default withRouter( observer( MapDashboardLayout ) )

const Place = ( {children, style, lat, lng} ) => (
	<div style={style} lat={lat} lng={lng}>{children}</div>
)
