import * as React from "react"
import moment from "moment"
import "moment/locale/de"
import http from "../service/http";
import {Button, Form, Input, Modal} from "semantic-ui-react"
import Icon from "component/Icon"
import store from "../store/store";
import {toast} from "react-toastify"
import {Redirect} from "react-router"
import {faEyeSlash, faEye} from "@fortawesome/free-solid-svg-icons";

window["moment"] = moment

export default class ActivationPage extends React.Component<any, any> {

    state = {
        user: null,
        company: null,
        showPassword: null,
        loading: false,
        success: false,
    }

    async componentDidMount() {
        const {activationToken} = this.props.match.params
        let res = await http.get(`/activate-user/${activationToken}/context`)
        let context = res.data
        let {user, company} = context
        this.setState({user, company})
    }

    async activate({password}) {
        const {activationToken} = this.props.match.params
        let activation = await http.post(`/activate-user/${activationToken}`, {password})
        // console.log({activation})
    }


    handleSubmit = async (event) => {
        event.preventDefault();


        const user: any = this.state.user
        const data = new FormData(event.target);
        let username = user.email
        let password = String(data.get("password"))
        let passwordConfirm = String(data.get("passwordConfirm"))
        if (password !== passwordConfirm) {
            return toast.warn("Ihre Passwörter stimmen nicht überein")
        }

        if (password.length < 5) {
            return toast.warn("Ihr Passwort ist zu kurz. ")
        }
        this.setState({loading: true})

        await this.activate({password})
        let permanent = !!data.get("permanent")
        let session
        try {
            session = await store.account.login({username, password, permanent})
            this.setState({loading: false, success: true})

        } catch (err) {
            console.warn(err);
            this.setState({loading: false})

        }
        // console.log(session);
        this.setState({loading: false})

        return true
    }


    render() {
        const {showPassword, success, loading} = this.state
        const user: any = this.state.user

        if (success) return <Redirect to={"/"}/>
        if (!user) return <div>
            <div className="pa3 tc vh-100 center">
                <div className={"pt4 b f4 pb2"}>Alles erledigt!</div>
                <div>Sie können die Seite nun schließen.</div>
            </div>
        </div>

        return <div>
            {/*<pre>{JSON.stringify({user, company}, null, 2)}</pre>*/}
            <Modal open>
                <Modal.Header>Willkommen bei flottr.com
                    <div className="f5 black-70">{user.email}</div>
                </Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>

                        <Form.Field>
                            <label htmlFor="password"><span className="ttu">Passwort</span></label>
                            <div className="flex flex-row">
                                <Input name="password"
                                       type={showPassword ? "text" : "password"}
                                       placeholder='Passwort'/>
                                <div className="ml1">
                                    <Button tabIndex={99} type="button"
                                            onClick={() => {
                                                this.setState({showPassword: !this.state.showPassword})
                                            }}
                                            basic icon={<Icon className={"ui icon"} icon={showPassword ? faEyeSlash : faEye}
                                    />}/>
                                </div>
                            </div>

                        </Form.Field>

                        <Form.Field>
                            <label htmlFor="password"><span className="ttu">Passwort bestätigen</span></label>
                            <Input name="passwordConfirm"
                                   type={showPassword ? "text" : "password"}
                                   icon={<Icon name={showPassword ? "eye slash" : "eye"} inverted circular link
                                               onClick={() => {
                                                   this.setState({showPassword: !this.state.showPassword})
                                               }}/>}
                                   placeholder='Passwort'/>
                        </Form.Field>

                        <div className="pt4 cf">
                            <Button floated={"right"} color={"yellow"} type='submit' loading={loading}>
                                <div className="b">Weiter</div>
                            </Button>
                        </div>
                    </Form>
                </Modal.Content>
            </Modal>

        </div>
    }
}