import React, { Component } from "react"
import { Link } from "react-router-dom"
import serialize from "form-serialize"
import "./OnboardingPage.css"
import { Button, Checkbox, Form } from 'semantic-ui-react'
import http from "service/http.ts"

export default class Onboarding extends Component {

  state = {
    loading: false
  }

  render() {
    return (
      <div className="Onboarding">
        <div className="flex flex-row items-center h-100 min-h-bug justify-center ">
          <div className="container pt-8 white">
            <div className=" mw6 drop-shadow rounded-md center overflow-hidden">
              <div className="p-8">
                <div className="Car">
                  <span className="text-2xl font-bold">Ihr Auto</span>
                </div>
                <FormOnboarding className="py-8 min-h-full"
                                onSuccess={( data ) => {
                                  this.setState( { loading: true } )
                                  http.post( `/api/account`, data )
                                    .then( res => {
                                      this.setState( { loading: false } )
                                      this.props.history.push( "/home" )
                                    } )
                                    .catch( err => {
                                      this.setState( { loading: false } )
                                    } )
                                  // console.log.log( "data", data, this.props )
                                  //
                                }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class FormOnboarding extends Component {

  handleSubmit = ( e ) => {
    let formData = serialize( e.target, { hash: true } )
    this.props.onSuccess( formData )
  }

  render() {
    return (<Form
      onSubmit={this.handleSubmit}
      inverted className={this.props.className}>

      <div className="mw6">
        <Form.Field>
          <label>Auto benennen</label>
          <input name="carName" placeholder='Name des Autos'/>
        </Form.Field>
        <Form.Field>
          <label>Kennzeichen</label>
          <input name="carId" placeholder='L-AM-313'/>
        </Form.Field>
        <Form.Field>
          <label>KM Stand</label>
          <input name="mileage" placeholder='50100'/>
        </Form.Field>
        <Form.Field>
          <label>Fahrer</label>
          <input name="driver" placeholder='Max Muster'/>
        </Form.Field>
        <p>
          <Link to="/cms/Datenschutz">Datenschutzbestimmungen</Link>
        </p>

        <Form.Field>
          <Checkbox name="okPrivacy" label='Ich habe die Datenschutzbestimmungen gelesen und stimme zu.'/>
        </Form.Field>

        <Form.Field>
          <Checkbox name="okAGB" label='Ich habe die AGB gelesen und stimme zu.'/>
        </Form.Field>

      </div>

      <div className="absolute pin-r pb3 pt2">
        <Button inverted basic type="button" as={Link} to={"/home"}>Zurück</Button>
        <Button inverted type='submit'>Weiter</Button>
      </div>
    </Form>)
  }
}
