import * as React from "react"
import "./Spinner.css"
import { Image } from "semantic-ui-react"

class Spinner extends React.Component {
  render() {
    const { inverted } = this.props
    return (
      <div className={`Spinner SpinnerDots ${inverted && "inverted"}`}>
        <div className="spinner-dot"/>
        <div className="spinner-dot"/>
        <div className="spinner-dot"/>
      </div>
    );
  }
}

class SpinnerDotsScale extends React.Component {
  render() {
    const { inverted } = this.props
    return (
      <div className={`Spinner SpinnerDotsScale ${inverted && "invertedw"}`}>
        <div className="spinner-dot"/>
        <div className="spinner-dot"/>
        <div className="spinner-dot"/>
      </div>
    );
  }
}

class SpinnerCircle extends React.Component {
  render() {
    const { inverted } = this.props
    return (
      <React.Fragment>
          <div className={`Spinner SpinnerCircle ${inverted && "invertedw"}`}>
            <div className="spinner-dot"/>
            <div className="spinner-dot"/>
            <div className="spinner-dot"/>
          </div>


      </React.Fragment>

    );
  }
}


export { Spinner, SpinnerDotsScale, SpinnerCircle }