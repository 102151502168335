import * as React from "react"
import {Card, Container, Dropdown, Grid, Image, Table} from "semantic-ui-react"
import http from "../service/http";
import Icon from "component/Icon";
import {faCheckCircle} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package";
import SimpleMap from "component/Map/SimpleMap.js";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {observer} from "mobx-react";
import "./BuyPage.css"

import CheckoutStore from "store/CheckoutStore";
import store from "../store/store";
import FooterLegal from "../component/Footer/FooterLegal";

const checkoutStore = new CheckoutStore()

const Place = ({children, style}: any) => (
    <div style={style}>{children}</div>
)

@observer
class OrderPage extends React.Component<any,
    {
        order: any,
        lat: string,
        lng: string
    }> {

    state = {
        lat: "",
        lng: "",
        order: false
    }

    handleLocation = async () => {
        // console.log("location")
        let order: any = this.state.order
        if (!order || !order.shipping) return
        let place: any = await store.geocodeAddress({address: `${order.shipping.street}, ${order.shipping.postalCode} ${order.shipping.city}`})
        if (place && place.geometry && place.geometry.location) {
            try {
                const lat = place.geometry.location.lat()
                const lng = place.geometry.location.lng()
                this.setState({lat, lng})
            } catch (err) {

            }
        }
    }

    componentDidMount() {
        const {orderId} = this.props.match.params
        if (orderId) {
            http.get(`/api/order/${orderId}`)
                .then(res => res.data)
                .then(data => {
                    console.log({data})
                    checkoutStore.products.easyDrive.count = data.order.products.easyDrive
                    checkoutStore.products.book.count = data.order.products.book
                    checkoutStore.products.singleBook.count = data.order.products.singleBook
                    checkoutStore.products.map.count = data.order.products.map
                    checkoutStore.fixPricePerUnit = data.order.fixPricePerUnit || 0
                    checkoutStore.calculate()
                    const order = data.order
                    if(!order.shipping.name) order.shipping = order.customer
                    if(!order.billing.name) order.billing = order.customer
                    this.setState({order: order})
                })
        }
    }

    render() {
        const {lat, lng} = this.state
        const order: any = this.state.order
        if (!order) return <div>Keine Bestellung hinterlegt.</div>
        return (
            <Container className="">

                <Grid doubling columns={2}>
                    <Grid.Column>
                        {this.props.showLogo && <div className="pv4"><Image width={150} src={"/brand/flottr-brand-dark@600w.png"}/></div>}

                        {/*HEADER THANKS NOTICE*/}
                        <div>
                            <div className="fl pr3 animate fadeInDown delay-500">
                                <Icon icon={faCheckCircle} size={"3x"}/>
                            </div>
                            <div className="f5 black-70">Bestellung #<span className="ttu">{order.orderNr}</span></div>
                            <div className="f4">Vielen Dank {order.billing.name}</div>
                        </div>


                        {/*HEADER MAP DELIVERY NOTICE*/}
                        <div className={"pt4"}>
                            <Card style={{width: "100%"}}>
                                <div className={``}>
                                    <SimpleMap
                                        onReady={this.handleLocation}
                                        style={{height: '180px', width: '100%'}}
                                        zoom={14}
                                        center={{
                                            lat: lat || 0,
                                            lng: lng || 0
                                        }}>
                                        {lat && <Place
                                          style={{
                                              width: "30px",
                                              height: "42px",
                                              transform: "translate(-15px, -42px)"
                                          }}
                                          lat={lat}
                                          lng={lng}
                                        ><Icon
                                          color="#ef3232"
                                          style={{
                                              width: "30px",
                                              height: "42px",
                                          }} icon={faMapMarkerAlt}/></Place>}
                                    </SimpleMap>
                                </div>
                                <Card.Content>
                                    <h3 className={"fw2 "}>Ihre Bestellung ist bestätigt</h3>
                                    <div className="black-70 f6">Sie erhalten eine E-Mail sobald ihre Bestellung
                                        versendet wurde.
                                    </div>
                                </Card.Content>
                            </Card>
                        </div>

                        <Card style={{width: "100%"}}>
                            <Card.Content>
                                <h3 className={"fw2"}>Kundendaten</h3>

                                <div className="black-70">
                                    <Grid columns={2}>
                                        <Grid.Column>

                                            <h5 className={"black-70"}>Kontakt</h5>
                                            <div >{order.customer.company}</div>
                                            <div>{order.customer.name}</div>
                                            <div>{order.email}</div>

                                            <h5 className={"black-70"}>Versand</h5>
                                            <div>{order.shipping.name}</div>
                                            <div>{order.shipping.street}</div>
                                            <div className={"pb2"}> {order.shipping.postalCode} {order.shipping.ity}</div>
                                            <div> Deutschland</div>
                                            <div>{order.shipping.phone}</div>

                                        </Grid.Column>
                                        <Grid.Column>

                                            <h5 className={"black-70"}>Bezahlung</h5>
                                            <div>Auf Rechnung</div>

                                            <h5 className={"black-70"}>Rechnung</h5>
                                            <div>{order.billing.name}</div>
                                            <div>{order.billing.street}</div>
                                            <div className={"pb2"}> {order.billing.postalCode} {order.billing.ity}</div>
                                            <div> Deutschland</div>
                                            <div>{order.billing.phone}</div>
                                        </Grid.Column>
                                    </Grid>
                                </div>

                            </Card.Content>
                        </Card>

                        <div className={"black-70 f6"}>Hilfe benötigt? <a target={"_blank"}
                                                                          href="mailto:info@flottr.com"><span
                            className={"black"}>Kontaktieren Sie uns</span></a></div>


                    </Grid.Column>
                    <Grid.Column className={"sidebar"}>


                        <div className="ph4 pt4">

                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Artikel</Table.HeaderCell>
                                        <Table.HeaderCell>Menge</Table.HeaderCell>
                                        <Table.HeaderCell>Einzelpreis</Table.HeaderCell>
                                        <Table.HeaderCell>Zwischensumme</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>


                                    {!!checkoutStore.products.easyDrive.count && <Table.Row>
                                        <Table.Cell>
                                            <div className={"f5 b"}>{checkoutStore.products.easyDrive.count} x flottr Easy Drive
                                            </div>
                                            <div className={"f6 black-70"}>GPS Tracker inklusive SIM Karte. OBD2
                                                Anschluss.
                                                Sofort einsatzbereit.
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div style={{width: "75px"}}>
                                                {checkoutStore.products.easyDrive.count}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="b tr">{currency(checkoutStore.products.easyDrive.price * 12)}</div>
                                            <div className="tr f6 black-70">12
                                                x {currency(checkoutStore.price, 0)}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="b tr">{currency(checkoutStore.products.easyDrive.price * checkoutStore.products.easyDrive.count * 12)}</div>
                                        </Table.Cell>
                                    </Table.Row>}

                                    {!!checkoutStore.products.book.count &&
                                    <Table.Row>
                                        <Table.Cell>
                                            <div className={"f5 b"}>Digitales Fahrtenbuch</div>
                                            <div className={"f6 black-70"}></div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div style={{width: "75px"}}>
                                                {checkoutStore.products.book.count}

                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="b tr">{currency(checkoutStore.products.book.price * 12)}</div>
                                            <div className="tr f6 black-70">12
                                                x {currency(checkoutStore.products.book.price, 0)}</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="b tr">{currency(checkoutStore.products.book.price * checkoutStore.products.book.count * 12)}</div>
                                        </Table.Cell>
                                    </Table.Row>}

                                    {/* GEOMAPS*/}
                                    {!!checkoutStore.products.map.count && <Table.Row>
                                      <Table.Cell>
                                        <div className={"f5 b"}>Geo</div>
                                        <div className={"f6 black-70"}>Weltweites Echtzeit-Tracking für Ihr Fahrzeug.</div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div style={{width: "75px"}}>
                                            {checkoutStore.products.map.count}
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div className="b tr">{currency(checkoutStore.products.map.price * 12)}</div>
                                        <div className="tr f6 black-70">12
                                          x {currency(checkoutStore.products.map.price, 0)}</div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div className="b tr">{currency(checkoutStore.products.map.price * checkoutStore.products.map.count * 12)}</div>
                                      </Table.Cell>
                                    </Table.Row>}

                                    {/* SINGLE FAHRTENBUCH*/}
                                    {checkoutStore.products.singleBook.count && <Table.Row>
                                      <Table.Cell>
                                        <div className={"f5 b"}>flottr Fahrtenbuch Jahreslizenz</div>
                                        <div className={"f6 black-70"}>Fahrtenbuch inkl. Hardware</div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div style={{width: "75px"}}>
                                            {checkoutStore.products.singleBook.count}
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div className="b tr">{currency(checkoutStore.products.singleBook.price * 12)}</div>
                                        <div className="tr f6 black-70">12
                                          x {currency(checkoutStore.products.singleBook.price, 2)}</div>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div className="b tr">{currency(checkoutStore.products.singleBook.price * checkoutStore.products.singleBook.count * 12)}</div>
                                      </Table.Cell>
                                    </Table.Row>}

                                </Table.Body>
                            </Table>

                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <div className="f5 black-70 pb2">Versandkosten</div>
                                            <div className="f5 black-70 pb2">Zwischensumme</div>
                                            <div className="f5 black-70 pb2">Hardware (Einmalig)</div>
                                        </Table.Cell>

                                        <Table.Cell>
                                            <div className="black-70 tr pb2">kostenlos</div>
                                            <div className="b tr pb2">{currency(checkoutStore.sumPerYear)}</div>
                                            <div className="black-70 tr pb2">{currency(checkoutStore.setupPrice, 2, true )}</div>
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>
                                            <div className="f5 black-70 ">zzgl. USt. (19%)</div>

                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="black-70 tr ">{currency(checkoutStore.sumTax)}</div>

                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>
                                            <div className="f5 b">Summe (inkl. USt.)</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="f4 b tr">{currency(checkoutStore.total)}</div>
                                        </Table.Cell>
                                    </Table.Row>

                                    {/*<Table.Row>*/}
                                    {/*    <Table.Cell>*/}
                                    {/*        <div className="f5 black-70">Im Folgejahr (Netto)</div>*/}
                                    {/*    </Table.Cell>*/}
                                    {/*    <Table.Cell>*/}
                                    {/*        <div className="f5 black-70 tr">{currency(checkoutStore.sumPerYear)}</div>*/}
                                    {/*    </Table.Cell>*/}
                                    {/*</Table.Row>*/}


                                </Table.Body>
                            </Table>

                        </div>

                    </Grid.Column>
                </Grid>

                <FooterLegal/>
            </Container>
        )
    }
}

export default OrderPage

const currency = (value, n = 2, allowFree = false) => {
    if (allowFree && value === 0) return "kostenlos"
    return `${Number(value).toFixed(n).replace(".", ",")} €`
}