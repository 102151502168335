import * as React from "react"
import {Link} from "react-router-dom"
import {Container, Form, Segment, Header, Input, Checkbox, Button} from "semantic-ui-react"
import store from "store/store"
import {toast} from "react-toastify"
import moment from "moment"

export default class RegistrationPage extends React.Component<any, any> {

    handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        let email = String(data.get("email"))
        let name = String(data.get("name"))
        let company = String(data.get("company"))
        let password = String(data.get("password"))
        let passwordConfirm = String(data.get("passwordConfirm"))
        let termsOk = String(data.get("terms_ok"))
        let privacyOk = String(data.get("privacy_ok"))
        // console.log({termsOk, privacyOk})
        if (!termsOk || !privacyOk) {
            toast.warn("Bitte bestätigen sie AGB und Datenschutzerklärung")
            return
        }

        if (password !== passwordConfirm) {
            toast.warn("Passwörter stimmen nicht überein")
            return
        }

        if (password.length < 7) {
            toast.warn("Ihr Passwort ist zu kurz")
            return
        }

        let session
        try {
            session = await store.account.register({email, name, password, passwordConfirm, company, termsOk, privacyOk})
            if(!email) return toast.warn("Bitte geben Sie eine E-Mail an.")
            if(!name) return toast.warn("Bitte geben Sie einen Namen an.")
            if(!company) return toast.warn("Bitte geben Sie einen Firmennamen an.")
            if(!termsOk) return toast.warn("Bitte bestätigen Sie die AGB.")
            if(!privacyOk) return toast.warn("Bitte lesen Sie unsere Datenschutzerklärung.")

            if (session) {
                await store.account.login({username: email, password})
                    .then(res => {
                        this.props.history.replace("/")
                    })
            }
        } catch (response) {
            const error = response.error
            toast.warn(`Firma oder E-Mail existiert bereits.`)
        }
        // console.log(session);
    }

    render() {
        return (
            <div className="LoginPage bg-silver h-100 min-h-bug">

                <div className="mw6 center pt5-ns pt3 ">
                    <Container>
                        <Header>Neuen Account anlegen</Header>
                        <Segment>
                            <div className="pa3-ns">
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <label htmlFor="form-company">Firma</label>
                                        <input id="form-company"
                                               type="text"
                                               name="company" />
                                    </Form.Field>

                                    <Form.Field>
                                        <label htmlFor="form-email">E-Mail</label>
                                        <input
                                            required
                                            id="form-email"
                                               type="email"
                                               name="email" placeholder='E-Mail'/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label htmlFor="form-name">Vollständiger Name</label>
                                        <input   id="form-name" name="name" required placeholder='Name'/>
                                    </Form.Field>

                                    <Form.Field>
                                        <label htmlFor="form-password">Passwort</label>
                                        <Input id="form-password"
                                               required
                                               type="password"
                                               name="password"
                                               placeholder='Passwort'/>
                                    </Form.Field>

                                    <Form.Field>
                                        <Input
                                            name="passwordConfirm"
                                            required
                                            type="password"
                                            placeholder='Passwort bestätigen'/>
                                    </Form.Field>

                                    <div className="pt2 pb3">
                                        <p><a href={"https://flottr.com/agb/"} target={"_blank"}> AGB </a> und unsere <a
                                            href={"https://flottr.com/datenschutz/"}
                                            target={"_blank"}> Datenschutzerklärung </a></p>
                                    </div>

                                    <Form.Field>
                                        <div className="pt2">
                                            <Checkbox className="f6 black-70" name="terms_ok"
                                                      value={moment.utc().toISOString()} required
                                                      label="Ich habe die AGB gelesen und akzeptiert."/>
                                        </div>
                                        <div className="pt2">
                                            <Checkbox className="f6 black-70" name="privacy_ok"
                                                      value={moment.utc().toISOString()} required
                                                      label="Ich habe die Datenschutzerklärung gelesen und akzeptiert."/>
                                        </div>
                                    </Form.Field>

                                    <div className="pt2">
                                        <Button fluid primary type='submit'>KONTO ERSTELLEN</Button>
                                    </div>

                                </Form>
                            </div>

                        </Segment>

                    </Container>
                </div>
            </div>
        )
    }
}
