import {Message, Modal, Image, Divider, Button} from "semantic-ui-react";
import * as React from "react";
import {Link, useParams} from "react-router-dom";
import store from "./store/store";
import http from "./service/http";
import QRCode from "react-qr-code";
import Icon from "./component/Icon";
import {faTimes} from "@fortawesome/free-solid-svg-icons";


export function VehicleConnectionPage() {
    const params = useParams<{ vehicleId: string }>()
    const vehicleId = params.vehicleId
    const vehicle = store.account.vehicle.byId.get(vehicleId)
    console.log({vehicle})

    const [token, setToken] = React.useState(false)

    React.useEffect(() => {
        http.get(`/api/v3/vehicle/${vehicleId}/connectionToken`)
            .then(res => {
                console.log({res})
                setToken(res.data.connectionToken)
            })
    })

    const url = `https://flottr-connect.netlify.app/#${token}`
    return <Modal open>
        <Modal.Header>
            <div className="flex flex-row justify-between">
                <div>
                    App verknüpfen
                </div>
                <div>
                    <Button as={Link} to={`/map/${vehicleId}/`} basic circular icon={<Icon icon={faTimes}/>}/>
                </div>
            </div>
        </Modal.Header>
        <Modal.Content>
            <div className="flex  flex-column">
                <div className="pr3-ns">
                    <div>Sie können von Ihrem Smartphone Positionsupdates an flottr.com senden.</div>
                    {!vehicle && <Message color="red">Fahrzeug nicht verfügbar.</Message>}
                    <p className="tc">Ihr Verbindungstoken ist eine Stunde gültig</p>
                    {token && <div className="f4">
											<div className="tc pv4">
												<QRCode value={url}/>

												<p className="f7 pt3">{url}</p>
											</div>
										</div>}
                </div>

                {/*<div className="pv3">*/}
                {/*    <Divider/>*/}
                {/*</div>*/}
                {/*<div className={"flex flex-row items-center"}>*/}
                {/*    <Image width={174} src={"/img/appstore_logo.png"}/>*/}
                {/*    <Image width={201} src={"/img/googleplay_logo.png"}/>*/}
                {/*</div>*/}

            </div>
        </Modal.Content>
    </Modal>
}