import * as React from "react"
import { Button, Container, Input, Select } from "semantic-ui-react"
import { Link, withRouter } from "react-router-dom"
import store from "store/store"
import moment from "moment"
import "moment/locale/de"
import TimeNavigation from "component/TimeNavigation"
import * as queryString from "querystring"
import parseQuery from "util/parseQuery"
import { observer } from "mobx-react"
import { PageHeaderSimple } from "component/PageHeader"
import Helmet from "react-helmet"
import { SidebarContext } from "component/Vehicle/VehicleSidebar"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import Icon from "component/Icon"
import { useEffect } from "react"

window["moment"] = moment

// @ts-ignore
@withRouter
@observer
class ExportPage extends React.Component<any, any> {

	last: any = false

	state = {
		loading: false,
		trips: null,
		error: null
	}

	handleTimeUpdate = ({ startDate, endDate, scale }) => {
		const query = parseQuery(this.props)
		const { deviceId } = query
		const search = queryString.stringify({
			deviceId,
			startDate: startDate.format("YYYY-MM-DD"),
			endDate: endDate.format("YYYY-MM-DD"),
			scale
		})
		console.log("HANDLE TIME UPDATE", { startDate, endDate, scale, search })

		if (!moment(startDate).isValid()) return
		this.props.history.replace({
			search
		})
	}

	render() {
		const query = parseQuery(this.props)
		console.log("QUERY", query)
		console.log(this.props.history)
		let { vehicleId, startDate, endDate } = query
		let vehicle = store.account.vehicle.byId.get(`${vehicleId}`)
		if (!vehicle) return null

		const start = moment(startDate)
		const end = moment(endDate)
		return (
			<div className="ExportPage Page w-100">
				<Helmet>
					<title>{`Bericht ${vehicle.vehiclePlate} ${startDate}`} </title>
				</Helmet>

				<PageHeaderSimple title={`Bericht ${vehicle.vehiclePlate} ${startDate}`}>
					<Button as={Link} to={`${vehicle.basePath}/route?${queryString.stringify(query)}`}>Als Karte</Button>
					<TimeNavigation
						onChange={this.handleTimeUpdate}
						showWeek={false}
						showYear={false}
						showMonth={false}
					/>
				</PageHeaderSimple>
				<SidebarContext optional forceHide />

				<div className="pt4-ns pb4-ns">

					<div className="mw8 center">
						<DateRangePicker startDate={start.toDate()}
														 endDate={end.toDate()}
														 onChange={({ start, end, unit }) => {
															 console.log("UPDATE", { start, end, unit })
															 this.handleTimeUpdate({ startDate: moment(start), endDate: moment(end), scale: unit })
														 }} />
					</div>

					<div className=" mw8 center box-shadow">
						<Container>

							<ReportIFrame id="report-frame" vehicleId={vehicleId}
														date={start.format("YYYY-MM-DD")}
														endDate={end.format("YYYY-MM-DD")}
														height={window.innerHeight - 160 + "px"} width={"100%"} />
						</Container>
					</div>
				</div>
			</div>
		)
	}
}


const DateRangePicker = ({ startDate = moment().toDate(), endDate = moment().toDate(), onChange }) => {
	const [start, setStart] = React.useState(startDate)
	const [unit, setUnit] = React.useState<"day" | "week" | "month" | "year">("day")
	const [end, setEnd] = React.useState(endDate)
	const [flag, setFlag] = React.useState("")
	const [focusedInput, setFocusedInput] = React.useState(false)

	const nextDay = moment().startOf("day")
	const isDayBlocked = (date) => {
		return nextDay.isBefore(date)
	}

	useEffect(() => {
		setStart(startDate)
		setEnd(endDate)
	}, [startDate, endDate])

	const update = () => {
		setTimeout(() => {
			// @ts-ignore
			setFlag(Math.random())
		}, 250)
	}

	useEffect(() => {
		if (flag) onChange({ start, end, unit })
	}, [flag])


	const prevCycle = () => {
		setStart(moment(start).subtract(1, unit).toDate())
		setEnd(moment(end).subtract(1, unit).endOf(unit).toDate())
		update()
	}

	const nextCycle = () => {
		setStart(moment(start).add(1, unit).toDate())
		setEnd(moment(end).add(1, unit).endOf(unit).toDate())
		update()
	}

	const setToday = () => {
		setStart(moment().toDate())
		setEnd(moment().add(1, "day").toDate())
		setUnit("day")
		update()
	}

	const setYesterday = () => {
		setStart(moment().subtract(1, "day").toDate())
		setEnd(moment().subtract(0, "day").toDate())
		setUnit("day")
		update()
	}

	const setThisWeek = () => {
		setStart(moment().startOf("week").toDate())
		setEnd(moment().endOf("week").toDate())
		setUnit("week")
		update()
	}

	const setLastWeek = () => {
		setStart(moment().startOf("week").subtract(1, "week").toDate())
		setEnd(moment().startOf("week").toDate())
		setUnit("week")
		update()
	}

	const setThisMonth = () => {
		setStart(moment().startOf("month").toDate())
		setEnd(moment().endOf("month").toDate())
		setUnit("month")
		update()
	}

	const setLastMonth = () => {
		setStart(moment().startOf("month").subtract(1, "month").toDate())
		setEnd(moment().startOf("month").toDate())
		setUnit("month")
		update()
	}

	const setThisYear = () => {
		setStart(moment().startOf("year").toDate())
		setEnd(moment().toDate())
		setUnit("year")
		update()
	}

	const setLastYear = () => {
		setStart(moment().startOf("year").subtract(1, "year").toDate())
		setEnd(moment().startOf("year").toDate())
		setUnit("year")
		update()
	}


	return <div className="DateRangePicker flex flex-column flex-row-ns pa3 items-center bg-white bb b--light-gray mb2 br3">
		<div className={""}>
			<button onClick={prevCycle} className={"mr1 ui basic button"}><Icon icon={faChevronLeft} /></button>
			<Input type="date"
						 className={"mr1 mb1 mb0-ns "}
						 onChange={(e) => {
							 setStart(moment(e.currentTarget.value).toDate())
						 }}
						 value={moment(start).format("YYYY-MM-DD")} />
			<Input type="date"
						 className={"mr1 mb1 mb0-ns "}
						 onChange={(e) => {
							 setEnd(moment(e.currentTarget.value).toDate())
						 }}
						 value={moment(end).format("YYYY-MM-DD")} />
			<button onClick={nextCycle} className={"mr1 ui basic button"}><Icon icon={faChevronRight} /></button>
		</div>

		<div className="flex flex-column mb1 mb0-ns w-100 w-auto-ns ">
			<Select
				fluid
				defaultValue={"any"}
				options={
					[
						{ key: "any", text: "Zeitraum wählen", value: "any" },
						{ key: "setToday", text: "Heute", value: "setToday" },
						{ key: "setYesterday", text: "Gestern", value: "setYesterday" },
						{ key: "setThisWeek", text: "Diese Woche", value: "setThisWeek" },
						{ key: "setLastWeek", text: "Letzte Woche", value: "setLastWeek" },
						// { key: "setThisMonth", text: "Diesen Monat", value: "setThisMonth" },
						// { key: "setLastMonth", text: "Letzten Monat", value: "setLastMonth" }
					]
				}
				className={"pv2 ph3 mr1"} name="" id="" onChange={(e, data) => {
				const value = data.value
				switch (value) {
					case "setToday":
						setToday()
						break
					case "setYesterday":
						setYesterday()
						break
					case "setThisWeek":
						setThisWeek()
						break
					case "setLastWeek":
						setLastWeek()
						break
					case "setThisMonth":
						setThisMonth()
						break
					case "setLastMonth":
						setLastMonth()
						break
				}
			}}>

			</Select>

		</div>


	</div>
}


export default ExportPage

function ReportIFrame(props: any) {
	const { id = "report-frame", width, height, date, endDate, vehicleId } = props
	const [loading, setLoading] = React.useState(true)
	const url = `${process.env.REACT_APP_API_URL}/report/${vehicleId}/pdf/${date}/${endDate}`

	useEffect(() => {
		setLoading(true)
	}, [url])

	return <div className="ReportIFrame">
		{loading && <div className={"pa4 flex flex-row items-center justify-center"}>
			<Button loading></Button>
		</div>}
		<iframe
			className={`ReportIFrame ${loading ? "o-0" : ""}`}
			onLoad={() => {
				setLoading(false)
			}}
			id={id} name={id} height={height} width={width}
			src={url} frameBorder="0" />
	</div>
}