import moment from "moment"
import {action, observable} from "mobx"
import http from "../service/http"
import TripType from "store/TripType"
import ServerState from "store/ServerState"
import {PrivacyMode} from "./model/Device"

type LatLng = [number, number]

type TGeoLocation = {
	administrative_area_level_1: string,
	administrative_area_level_3: string,
	street?: string,
	locality?: string,
	postal_code?: string,
	route?: string,
	street_number?: string
	country?: string
}

export default class Trip {

	@observable status: ServerState = ServerState.ready

	@observable trip_contact: string = ""
	@observable trip_company: string = ""
	@observable trip_cause: string = ""
	@observable trip_driver: string = ""
	@observable trip_comment: string = ""
	@observable trip_type: TripType
	vehicleId: string
	index: number
	contactId: any
	day: any
	month: any
	privacyMode: PrivacyMode = PrivacyMode.TRACKING
	deviceId: any
	distance: any
	distance_humanized: string
	duration: any
	start: any
	end: any
	startDate: any
	endDate: any
	id: any
	lat: any
	lng: any
	@observable locationEnd: any
	@observable locationStart: any
	next: any
	nextTripId: any
	prev: any
	prevTripId: any
	first_save: string
	open: any
	type: any
	voltage: any
	km: number
	mileageStart: number
	mileageEnd: number
	startDateFriendly: any
	endDateFriendly: any
	posStart: { lat: any, lng: any }
	posEnd: { lat: any, lng: any }
	pos_start: { lat: any, lng: any }
	pos_end: { lat: any, lng: any }
	geoStart: TGeoLocation
	geoEnd: TGeoLocation
	geo: TGeoLocation // ?
	records?: Array<LatLng>
	indicator: 'first' | 'last' | number

	constructor( data ) {
		// TODO: clean up

		Object.assign( this, data )

		this.privacyMode = data.privacyMode
		this.id = data.id
		this.contactId = data.contactId
		this.vehicleId = data.vehicleId
		this.day = data.day
		this.month = moment( data.day ).month()
		this.deviceId = data.deviceId
		this.distance = data.distance
		this.distance_humanized = data.distance >= 1 ? this.distance : "<1"
		this.duration = data.duration
		this.start = data.start
		this.end = data.end
		this.startDate = data.startDate
		this.endDate = data.endDate
		this.lat = data.lat
		this.lng = data.lng
		this.geo = data.geo
		this.locationEnd = data.locationEnd
		this.locationStart = data.locationStart

		this.geoStart = data.geoStart || {}
		this.geoEnd = data.geoEnd || {}

		this.next = data.next
		this.prev = data.prev
		this.open = data.open
		this.first_save = data.first_save
		this.type = data.type
		this.mileageStart = data.mileageStart
		this.mileageEnd = data.mileageEnd
		this.trip_type = data.trip_type
		this.trip_cause = data.trip_cause || ""
		this.trip_contact = data.trip_contact || ""
		this.trip_comment = data.trip_comment || ""
		this.trip_company = data.trip_company || ""
		this.trip_driver = data.trip_driver || ""
		this.voltage = data.voltage
		this.records = data.records
		this.indicator = data.indicator

		this.km = 0

		this.posEnd = {
			lat: data.lat,
			lng: data.lng
		}

		this.startDate = moment.utc( this.start )
		this.startDateFriendly = this.startDate.local().format( "HH:mm" ) + " Uhr"
		this.endDate = moment.utc( this.end )
		this.endDateFriendly = this.endDate.local().format( "HH:mm" ) + " Uhr"
	}

	toJSON() {
		const {start, end, lat, lng, trip_type, trip_cause, trip_comment, trip_company, trip_driver, first_save} = this
		return {start, end, lat, lng, trip_type, trip_cause, trip_comment, trip_company, trip_driver, first_save}
	}

	@action
	setTripType( type ) {
		this.trip_type = type
	}

	@action
	put( data = this.toJSON() ) {
		data.start = this.start
		data.first_save = data.first_save || moment().toISOString()

		this.status = ServerState.loading
		return http.put( `/api/trip/${this.vehicleId}/${this.id}`, data )
			.then( res => {
				this.status = ServerState.ready
				console.log({data})
				Object.assign( this, data, res.trip )
				return res
			} )
			.catch( err => {
				this.status = ServerState.error
				return err
			} )
	}
}

const formatAddress = ( component ) => {
	return `${component.route} ${component.street_number}`
}


const parseLocation = ( location ) => location && location.split( "," ) || []
