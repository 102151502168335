import moment from "moment"
import * as queryString from "querystring"
import store from "store/store"

export default function parseQuery( props, fallbacks: any = {} ) {
	const query = queryString.parse( props.location.search.substr( 1 ) )
	query.startDate = query.startDate || props.match.params.startDate || fallbacks.startDate || moment().subtract(1, "day").format("YYYY-MM-DD")
	query.endDate = query.endDate || props.match.params.endDate || fallbacks.endDate
	query.deviceId = query.deviceId || props.match.params.deviceId
	query.vehicleId = query.vehicleId || props.match.params.vehicleId
	query.year = query.year || `${moment().year()}`

	if ( query.vehicleId && !query.deviceId ) {
		const vehicle = store.account.vehicle.byId.get( `${query.vehicleId}` )
		// set deviceId from current vehicle
		if ( vehicle ) query.deviceId = vehicle.deviceId
	}

	return query
}