import * as React from "react"
import {Container, Image} from "semantic-ui-react"
import LoginForm from "component/LoginForm"
import {observer} from "mobx-react"
import store from "store/store"
import {Redirect} from "react-router";
import * as queryString from "querystring"
import "./LoginPage.css"

@observer class LoginPage extends React.Component<any, any> {
	render() {
		const {mfr = false} = this.props
		const query = queryString.parse(document.location.search.slice(1))
		let refUrl =  query.referer || window["REF_URL"] || "/"
		if ( refUrl === "/login/" || refUrl === "/logout/" || refUrl === "/login" || refUrl === "/logout" ) refUrl = "/"
		const {ready, user} = store.account
		console.log( {ready,query, user, refUrl} )
		if ( user && ready ) return <Redirect to={refUrl} />
		return (
			<div className="LoginPage  min-vh-100 h-100 min-h-bug bg-white ">
				<div className="top-wrapper absolute animate fadeInDown duration-800 ">
					<div className="absolute bottom-0 left-0 right-0 top-0 h-100 pa0 bg-gradient-silver ">
					</div>
					<div className="absolute bottom-0 left-0 right-0 pa0">
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1903 556" className="white ">
							<path fill="white" className="svg-banner-shape" d="M753.1,434.2c110.6,63.7,277.7,70.6,373.4,15.4L1905,0v555.9H0V0.2L753.1,434.2z"></path>
						</svg>
					</div>
				</div>

				<div className="h-100 relative flex flex-column justify-center">

					<Container className="">
						<div className="flex h-100 items-center justify-center ">
							<div className="w-100">
								<div className="logo pb4 animate pulse">
									<Image src={"/brand/flottr-brand-dark@600w.png"} width={200} centered
										   alt={"flottr logo"} />
								</div>
								<LoginForm mfr={mfr} onSuccess={() => {
									this.props.history.replace( refUrl )
								}} />
							</div>
						</div>
					</Container>
				</div>

			</div>
		)
	}
}
export default LoginPage
