import * as React from "react"
import store from "store/store"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import moment from "moment"
import "moment-duration-format"
import "moment/locale/de"

import {Link} from "react-router-dom"
import "component/Mobile/RoutePage.css"
import Icon from "component/Icon"
import {Button, Loader, Modal} from "semantic-ui-react"
import SimpleMap from "component/Map/SimpleMap.js"
// import Trips from "component/Trips";
import {ITrips, Vehicle} from "store/model/Vehicle"
import {observer} from "mobx-react"
import Trip from "store/Trip"
import TimeNavigation from "../component/TimeNavigation"
import parseQuery from "util/parseQuery";
import {PageHeaderSimple} from "component/PageHeader"
import * as queryString from "querystring"
import Helmet from "react-helmet"
import {SidebarContext} from "component/Vehicle/VehicleSidebar"
import {Device} from "store/model/Device"
import {faHome} from "@fortawesome/free-solid-svg-icons"

moment.locale("de")

enum tripHighlightState {
    default = "default", //= "#00D6E6FF",
    highlight = "highlight", //= "#ff3eb3FF",
    muted = "muted" //= "#00D6E6888"
}

const pathSelectionColor = {
    default: "#00D6E6FF",
    highlight: "#ff3eb3FF",
    muted: "#aaaaaa88"
}

type EntryParams = {
    trip: Trip
    selectionState: tripHighlightState
    onSelect(): void
}

function RouteEntry({trip, selectionState, onSelect}: EntryParams) {
    if (trip.privacyMode === "ePrivate") {

        if (trip.type === "eDriving") {
            return <div className={"route-entry"}>
                <div className={"b f3"}>Private Nutzung</div>
                <div className="">
                    <div className="">
                        {moment.duration(trip.duration).format("h [h] m [m]")}
                    </div>
                </div>
            </div>
        }
        return null
    }

    if (trip.type === "eDriving") {
        return (
            <div
                onClick={onSelect}
                className={`route-entry route-entry-driving flex flex-row route-entry-driving-${selectionState}`}
            >
                <div className="route-entry-index flex"/>

                <div className="route-entry-times flex flex-column">
                    <div className="route-entry-drive-duration">
                        {moment.duration(trip.duration).format("h [h] m [m]")}
                    </div>
                </div>

                <div className="route-entry-dots flex flex-column">
                    <div
                        className="route-entry-driveline route-entry-driveline"
                        onClick={onSelect}
                    />
                </div>

                <div className="route-entry-titles flex flex-column">
                    <div className="route-entry-drive-distance">{trip.distance} km</div>
                </div>
            </div>
        )
    }

    // this trip is a PARKING type
    else if (trip.indicator === "first") {
        return (
            <div
                onClick={onSelect}
                className={`route-entry flex flex-row route-entry-first route-entry-parking-${selectionState}`}
            >
                <div className="route-entry-index flex">
                    <div className="ui circle label">
                        <Icon icon={faHome}/>
                    </div>
                </div>

                <div className="route-entry-times flex flex-column">
                    <div className="route-entry-time">{trip.endDate.format("H:mm")}</div>
                </div>
                <div className="route-entry-dots flex flex-column">
                    <div className="route-entry-dot"/>
                </div>
                <div className="route-entry-titles flex flex-column">
                    {trip.geoStart && <React.Fragment>
                      <div className="route-entry-suptitle">
                          {trip.geoStart.route} {trip.geoStart.street_number}
                      </div>
                      <div className="route-entry-subtitle">{trip.geoStart.locality}</div>
                    </React.Fragment>}
                </div>
            </div>
        )
    } else if (trip.indicator === "last") {
        return (
            <div
                onClick={onSelect}
                className={`route-entry flex flex-row route-entry-last route-entry-parking-${selectionState}`}
            >
                <div className="route-entry-index flex">
                    <div className="ui circle label">
                        <span>{trip.index}</span>
                    </div>
                </div>
                <div className="route-entry-times flex flex-column">
                    <div className="route-entry-time">
                        {trip.startDate.format("H:mm")}
                    </div>
                </div>
                <div className="route-entry-dots flex flex-column">
                    <div className="route-entry-dot"/>
                </div>
                {trip.geoEnd && <div className="route-entry-titles flex flex-column">
                  <div className="route-entry-suptitle">
                      {trip.geoEnd.route} {trip.geoEnd.street_number}
                  </div>
                  <div className="route-entry-subtitle">{trip.geoEnd.locality}</div>
                </div>}
            </div>
        )
    } else {
        return (
            <div
                onClick={onSelect}
                className={`route-entry flex flex-row route-entry-parking-${selectionState}`}
            >
                <div className="route-entry-index flex">
                    <div className="ui circle label">
                        <span>{trip.index}</span>
                    </div>
                </div>

                <div className="route-entry-times flex flex-column">
                    <div className="route-entry-time">
                        {trip.startDate.format("H:mm")}
                    </div>
                    <div className="route-entry-duration">
                        {moment
                            .duration(trip.endDate.diff(trip.startDate))
                            .format("h [h] m [m]")}
                    </div>
                    <div className="route-entry-time">{trip.endDate.format("H:mm")}</div>
                </div>

                <div className="route-entry-dots flex flex-column">
                    <div className="route-entry-dot"/>
                    <div className="route-entry-haltline"/>
                    <div className="route-entry-dot"/>
                </div>

                <div className="route-entry-titles flex flex-column">
                    <div className="route-entry-suptitle">
                        {trip.geoStart.route} {trip.geoStart.street_number}
                    </div>
                    <div className="route-entry-subtitle">{trip.geoStart.locality}</div>
                </div>
            </div>
        )
    }
}

interface ReportBarProps {
    trips?: ITrips
    vehicle: Vehicle
    startDate: string
    loading: boolean
    selectedTrip: Trip | null

    onSelect(trip: Trip): void
}

const sameTrip = (a, b) => a && b && a.type === b.type && a.index === b.index

class ReportBar extends React.Component<ReportBarProps, any> {
    currentTrip: Trip | null = null

    componentDidUpdate() {
        // if (this.props.selectedTrip !== this.currentStopIndex) {
        const {selectedTrip} = this.props
        if (!selectedTrip || sameTrip(selectedTrip, this.currentTrip))
            return

        this.currentTrip = selectedTrip
        const elementId = `route-entry-${selectedTrip.type}-${selectedTrip.index}`
        const stopEntry = document.getElementById(elementId)
        if (stopEntry)
            stopEntry.scrollIntoView({behavior: "smooth", block: "center"})
    }

    selectTrip = trip => {
        return () => {
            this.currentTrip = trip
            this.props.onSelect(trip)
        }
    }

    render() {
        const {vehicle, trips, loading, startDate, selectedTrip} = this.props

        if (!trips) return null

        const {all, totalTime, totalDistance_km} = trips

        const tripState = trip => {
            if (selectedTrip === null)
                return tripHighlightState.default
            else if (sameTrip(trip, selectedTrip))
                return tripHighlightState.highlight
            else
                return tripHighlightState.muted
        }

        return (
            <div className="ReportBar flex flex-column  ">
                <Helmet>
                    <title>Tagesbericht {vehicle.vehiclePlate} {startDate}</title>
                </Helmet>

                {!loading && (
                    <div className=" pb5">
                        <table className="route-details mb3">
                            <tbody>
                            <tr>
                                <th>Datum</th>
                                <td>{startDate}</td>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <td>{vehicle?.title}</td>
                            </tr>
                            <tr>
                                <th>Kennzeichen</th>
                                <td>{vehicle?.vehiclePlate}</td>
                            </tr>
                            {vehicle?.vehicleManufacturer && <tr>
                              <th>Marke</th>
                              <td>{vehicle?.vehicleManufacturer}</td>
                            </tr>
                            }
                            <tr>
                                <td>
                                    <div className="h1"/>
                                </td>
                            </tr>
                            <tr>
                                <th>Gesamtstrecke</th>
                                <td>
                                    {totalDistance_km} <em>km</em>
                                </td>
                            </tr>
                            <tr>
                                <th>Gesamtzeit</th>
                                <td>
                                    {totalTime.hours() > 0 && (
                                        <span>
												{totalTime.hours()}&nbsp;<em>h</em>&nbsp;
											</span>
                                    )}
                                    <span>
											{totalTime.minutes()}&nbsp;<em>min</em>
										</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="route-entries">
                            {all.map((trip, i) => (
                                <div id={`route-entry-${trip.type}-${trip.index}`} key={i}>
                                    <RouteEntry
                                        trip={trip}
                                        selectionState={tripState(trip)}
                                        onSelect={this.selectTrip(trip)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

            </div>
        )
    }
}

type ReportPageState = {
    vehicle?: Vehicle
    trips?: ITrips
    loading: boolean
    selectedTrip: Trip | null
}

@observer
class RoutePage extends React.Component<any, ReportPageState> {
    _currentVehicle: string = ""
    _currentDay: string | undefined = ""

    state: ReportPageState = {
        vehicle: undefined,
        trips: undefined,
        loading: false,
        selectedTrip: null
    }

    selectTrip = (trip: Trip | null) => {
        if (trip === null || sameTrip(trip, this.state.selectedTrip))
            this.setState({selectedTrip: null})
        else
            this.setState({selectedTrip: trip})
    }

    async componentDidMount() {
        await store.account.vehicle.get()
        let query = parseQuery(this.props)
        const {vehicleId, startDate} = query
        this.initData({vehicleId, startDate})
    }

    componentDidUpdate(prevProps) {
        let query = parseQuery(this.props)
        let prevQuery = parseQuery(prevProps)

        const sameDay = query.startDate === prevQuery.startDate
        const sameVehicleId = query.vehicleId === prevQuery.vehicleId
        // console.info( "UPDATE", query )
        if (!sameDay || !sameVehicleId) this.initData({vehicleId: query.vehicleId, startDate: query.startDate})
    }

    async initData({vehicleId, startDate}) {
        this.setState({loading: true})

        this.state.trips = undefined
        this.state.vehicle = undefined
        this.state.selectedTrip = null

        // console.log( "fetching start", startDate )

        const vehicle = store.account.vehicle.byId.get(vehicleId)
        if (!vehicle) return

        // console.log( `fetching ${vehicleId}/${startDate}` )
        const trips = await vehicle.fetchTripsForDay({day: startDate})
        if (!trips) return

        console.log({trips})
        this.setState({trips, vehicle, loading: false})
    }

    render() {
        const query = parseQuery(this.props)
        console.log({query})
        const {startDate, deviceId, vehicleId} = query
        const {trips, loading, selectedTrip} = this.state
        const vehicle = store.account.vehicle.byId.get(`${vehicleId}`)
        const device = store.account.device.byId.get(`${deviceId}`)
        console.log({vehicle, device})
        if (!device) return null
        if (!vehicle) return null
        return (
            <div className="RoutePage w-100">
                <PageHeaderSimple title={"Tagesbericht"}>
                    <Button basic size={"small"} as={Link} to={`${vehicle.basePath}/export?${queryString.stringify(query)}`}>PDF</Button>
                    <TimeNavigation
                        maxDate={moment()}
                        fluid
                        showDay={false}
                        showWeek={false}
                        showYear={false}
                        showMonth={false}
                    />
                </PageHeaderSimple>

                <SidebarContext forceHide/>

                {!vehicle.featureByKey["FEATURE_BASE"] && <div>
                  <Modal open size={"small"}>
                    <Modal.Header>Tracker kaufen</Modal.Header>
                    <Modal.Content>
                      <div className="flex flex-row items-center">
                        <div className={"pa3 animate fadeInDown"}>
                          <img width={376 / 2} src="https://www.flottr.com/files/flottr/img/Flottr-GPS-Tracker.png" alt=""/>
                        </div>
                        <div className={"f3 b pl3-ns "}>
                          <p>Erwerben Sie einen Tracker und erhalten Sie Tagesberichte als PDF & Karte.</p>

                        </div>
                      </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic as={Link} to={`/map/${vehicle._id}/`}>Zurück</Button>
                      <Button primary as={Link} to={`/buy?vehicleId=${vehicle._id}`}>Tracker kaufen</Button>
                    </Modal.Actions>
                  </Modal>
                </div>}

                {/*<div className="absolute pa3 top-0 right-0 z-5">*/}
                {/*	<Button as={Link} to={`/map/${vehicle._id}/`} primary circular className={"icon"}><IconFa icon={faTimes} /></Button>*/}
                {/*</div>*/}

                <div className="flex flex-column flex-row-ns items-stretch vh-100">
                    <div className="route-map order-1-ns h-40 h-100-ns">
                        <ReportMap
                            device={device}
                            trips={trips}
                            loading={loading}
                            selectedTrip={selectedTrip}
                            onSelect={this.selectTrip}
                        />
                    </div>

                    <div
                        style={{
                            maxWidth: "520px"
                        }}
                        className="w-100 w-auto-ns order-0-ns pa3 pt4-ns pb2-ns pb5 ph3-ns ph5-ns bg-white w-40-l overflow-x-hidden overflow-y-auto">
                        <Loader active={loading}/>
                        {vehicle && trips && (
                            <ReportBar
                                loading={loading}
                                vehicle={vehicle}
                                startDate={`${startDate}`}
                                trips={trips}
                                selectedTrip={selectedTrip}
                                onSelect={this.selectTrip}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }

}

type ReportMapProps = {
    trips?: ITrips
    device: Device,
    loading: boolean
    selectedTrip: Trip | null

    onSelect(trip: Trip | null): void
}

type ReportMapState =
    {
        google?: any
        mapReady: boolean
    }

class ReportMap extends React.Component
    <ReportMapProps, ReportMapState> {
    tripStops: Array<any> = []
    tripPaths: Array<any> = []
    currentTrip: Trip | null = null

    state: ReportMapState = {
        mapReady: false
    }

    onError() {
    }

    mapReady = google => {
        // console.log( "-------------------- map ready" )
        this.setState({mapReady: true, google})
    }

    componentDidUpdate(prevProps) {

        const {google, mapReady} = this.state
        const {trips, device, loading, selectedTrip} = this.props
        const map = google && google.map

        // props.loading === true means we are going to get completely new data
        // so remove everything we drew on the map
        if (loading) {
            console.debug("---------- reset map data -------------------------")
            this.tripPaths.forEach(tripPath => tripPath.setMap(null))
            this.tripStops.forEach(tripMarker => tripMarker.setMap(null))

            this.tripPaths = []
            this.tripStops = []
            return
        }

        if (!mapReady || !trips || !google || !map)
            return

        if (!prevProps.trips) {

            // console.log( "-------------------- draw new trips" )
            const bounds = new google.maps.LatLngBounds()

            bounds.extend(device)

            this.tripStops = trips.all
                .filter(trip => trip.privacyMode !== "ePrivate")
                .filter(trip => trip.type === "eParking")
                .map(stop => {
                    const {lat, lng} = stop
                    // console.log( "OK", {lat, lng} )
                    const stopMarker = createHTMLMapMarker(google, {
                        lat,
                        lng,
                        map,
                        zIndex: 100,
                        trip: stop,
                        indicator: stop.indicator
                    })
                    lat && bounds.extend({lat, lng})

                    stopMarker.addListener("click", ev => {
                        ev.stopPropagation()
                        this.currentTrip = stop
                        this.props.onSelect(stop)
                    })
                    return stopMarker
                })

            this.tripPaths = trips.all
                .filter(trip => trip.type === "eDriving")
                .map((trip, i) => {

                    const tripCoordinates = (trip.records || []).map(record => {
                        const [lat, lng] = record
                        bounds.extend({lat, lng})
                        return {lat, lng}
                    })

                    const tripPath = new google.maps.Polyline({
                        path: tripCoordinates,
                        geodesic: true,
                        strokeColor: tripHighlightState.default,
                        strokeOpacity: 1.0,
                        strokeWeight: 5,
                        map,
                        zIndex: 50,
                        trip
                    })
                    tripPath.addListener("click", ev => {
                        // console.log( `ev`, ev )
                        // console.log( "clicked Drivingtrip", trip, tripPath )
                        console.log({tripCoordinates})
                        this.currentTrip = trip
                        this.props.onSelect(trip)
                    })
                    // console.log(`tripPath`, tripPath)
                    return tripPath
                })

            map.fitBounds(bounds)
            map.addListener("click", ev => {
                // console.log( "clicked map" )
                this.props.onSelect(null)
            })
            // enforce min Zoom
            const MIN_ZOOM = 15
            map.setZoom(Math.min(MIN_ZOOM, map.getZoom()))
        }

        // check if the currently selected Path has been changed from outside
        if (!sameTrip(selectedTrip, this.currentTrip)) {
            this.currentTrip = selectedTrip
            const tripPath = this.tripPaths.find(tripPath =>
                sameTrip(tripPath.trip, selectedTrip)
            )
            const tripStop = this.tripStops.find(tripStop =>
                sameTrip(tripStop.trip, selectedTrip)
            )
            if (tripPath) {
                const bounds = new google.maps.LatLngBounds()
                tripPath.getPath().forEach(latlng => bounds.extend(latlng))
                map.fitBounds(bounds)
            }
            if (tripStop) {
                // console.log( `tripStop`, tripStop )
                // const bounds = new google.maps.LatLngBounds()
                // bounds.extend(tripStop.getPosition())
                // map.fitBounds(bounds, 80)
                map.panTo(tripStop.getPosition())
            }
        }

        this.tripPaths.forEach(tripPath => {
            let state: tripHighlightState
            let zIndex: number = 100
            if (selectedTrip === null) {
                state = tripHighlightState.default
            } else if (sameTrip(selectedTrip, tripPath.trip)) {
                state = tripHighlightState.highlight
                zIndex += 50
            } else {
                state = tripHighlightState.muted
                zIndex -= 50
            }
            tripPath.setOptions({strokeColor: pathSelectionColor[state], zIndex})
        })
        this.tripStops.forEach(tripMarker => {
            let state: tripHighlightState
            if (selectedTrip === null)
                state = tripHighlightState.default
            else if (sameTrip(selectedTrip, tripMarker.trip))
                state = tripHighlightState.highlight
            else
                state = tripHighlightState.muted
            tripMarker.setHighlightState(state)
        })
    }

    drawTrips() {
    }

    render() {
        return (
            <SimpleMap
                style={{height: "100%", width: "100%"}}
                onReady={this.mapReady}
            />
        )
    }
}

const createHTMLMapMarker = (
    google,
    {
        lat, lng, map, zIndex = 100, trip, indicator
    }
) => {
    class HTMLMapMarker extends google.maps.OverlayView {
        constructor() {
            super()
            this.latlng = new google.maps.LatLng(lat, lng)

            let index = indicator
            if (indicator === "last") index = "P"

            const html =
                indicator === "first"
                    ? `<div class="circle-marker cursor-pointer">
<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" class="svg-inline--fa fa-home fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>
</div>`
                    : `<div class="circle-marker cursor-pointer">${index}</div>`

            this.html = html
            this.trip = trip
            this.indicator = indicator
            this.zIndex = zIndex
            this.setMap(map)
        }

        setHighlightState(state: tripHighlightState) {
            this.highlightState = state
            this.applyHighlightState()
        }

        applyHighlightState() {
            if (!this.div)
                return
            this.div.className = `map-marker-parking map-marker-parking-${this.highlightState}`
            if (this.highlightState === tripHighlightState.default)
                this.div.style.zIndex = this.zIndex
            if (this.highlightState === tripHighlightState.highlight)
                this.div.style.zIndex = this.zIndex + 50
            if (this.highlightState === tripHighlightState.muted)
                this.div.style.zIndex = this.zIndex - 50
        }

        createDiv() {
            this.div = document.createElement("div")
            this.div.style.position = "absolute"
            if (this.html) {
                this.div.innerHTML = this.html
            }
            this.setHighlightState(tripHighlightState.default)
            google.maps.event.addDomListener(this.div, "click", event => {
                google.maps.event.trigger(this, "click", event)
            })
        }

        appendDivToOverlay() {
            const panes = this.getPanes()
            panes.overlayMouseTarget.appendChild(this.div)
        }

        positionDiv() {
            const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
            if (point) {
                this.div.style.left = `${Math.round(point.x).toFixed(0)}px`
                this.div.style.top = `${Math.round(point.y).toFixed(0)}px`
            }
        }

        draw() {
            if (!this.div) {
                this.createDiv()
                this.appendDivToOverlay()
            }
            this.positionDiv()
            this.applyHighlightState()
        }

        remove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div)
                this.div = null
            }
        }

        getPosition() {
            return this.latlng
        }

        getDraggable() {
            return false
        }
    }

    return new HTMLMapMarker()
}

export default RoutePage
