import * as React from "react"
import {observer} from "mobx-react"
import store from "store/store"

import {SpinnerCircle} from "component/Spinner/Spinner.js"

@observer
class LoadingOverlay extends React.Component<any, any> {


    render() {
        const {preparing} = store.account
        if (!preparing) return null
        return (
            <div className="z-999 absolute bg-white top-0 left-0 right-0 bottom-0 flex">
                <SpinnerCircle inverted/>
            </div>
        )
    }
}

export default LoadingOverlay