import AccountStore from "store/AccountStore"
import TripStore from "store/TripStore"
import LocationStore from "store/LocationStore"
import io from "socket.io-client"
import { action, observable } from "mobx"

const GPS_SERVER_URL = process.env.REACT_APP_GPS_SERVER_URL || "https://gps.mobilefieldreport.com"
const REACT_APP_API_URL = process.env.REACT_APP_API_URL

interface IGoogleLocation {
    address_components?: Array<any>
}

class PositionStore {
    @observable lat: number = 0
    @observable lng: number = 0
    store: Store
    history: Array<any> = []

    constructor(store) {
        this.store = store
    }
}


class AppStore {
    store: Store
    @observable showSidebar: boolean = true
    @observable showLeftSidebar: boolean = false

    constructor(store) {
        this.store = store
    }

    @action
    toggleSidebar = ()=>{
        this.showSidebar = !this.showSidebar
    }

    //ONLY ON MOBILE
    @action
    closeLeftSidebar = ()=>{
        this.showLeftSidebar = false
    }

    //ONLY ON MOBILE
    @action
    toggleLeftSidebar = ()=>{
        this.showLeftSidebar = !this.showLeftSidebar
    }

}

export class Store {
    trips: TripStore
    account: AccountStore
    location: LocationStore
    position: PositionStore
    app: AppStore
    socket: any
    socketBackend: any
    google: any
    geocoder: any
    infoWindow: any

    constructor() {
        this.app = new AppStore(this)
        this.account = new AccountStore(this)
        this.trips = new TripStore(this)
        this.location = new LocationStore(this)
        this.position = new PositionStore(this)
        this.subscribe()
    }

    setGoogle(google) {
        if ( google && google.maps ) {
            this.google = google
            this.geocoder = new google.maps.Geocoder();
            this.infoWindow = new google.maps.InfoWindow();
        }
    }

    geocode(latlng): Promise<GoogleLocationResult | any> {
        return new Promise((resolve, reject)=>{
            this.geocoder.geocode({ 'location': latlng }, function(results, status) {
                if ( status === 'OK' ) {
                    resolve(new GoogleLocationResult(results));
                } else {
                    resolve({})
                }
            })
        })
    }

    geocodeAddress = (data)=>{
        return new Promise((resolve, reject)=>{
            this.geocoder.geocode(data, function(results, status) {

                console.log(`Received geocode`, results, status)
                if ( status === 'OK' ) {
                    resolve(results[ 0 ]);
                } else {
                    resolve({})
                }
            })
        })
    }

    subscribe() {
        this.socket = io(GPS_SERVER_URL, { transports: [ "websocket" ] })
        this.socketBackend = io(REACT_APP_API_URL)

        function tryReconnect(socket) {
            if ( socket.disconnected ) {
                console.log("TRY RECONNECT")
                socket.connect()
            }
        }

        // ADD a heartbeat ping-pong to keep the connection alive over longer durations
        this.socket.on('ping', (data)=>{
            this.socket.emit('pong', { beat: 1 });
        });

        this.socket.on("log", function(dat) {
            // console.log("gps:log:", dat)
        })

        this.socketBackend.on('disconnect', ()=>{
            console.warn("DISCONNECT WEBSOCKET AZURE")
            setInterval(()=>tryReconnect(this.socketBackend), 3000)
        });

        this.socket.on('disconnect', ()=>{
            console.warn("DISCONNECT WEBSOCKET GPS")
            setInterval(()=>tryReconnect(this.socket), 3000)
        });
    }

    init() {
        // this.trips.get({
        // 	account: this.account.active,
        // 	start: "2018-01-01",
        // 	end: "2019-01-01",
        // })
        // 	.catch(err => {
        // 		console.error("Error fetching trips", err)
        // 	})
    }
}


export class GoogleLocationResult {
    results
    result
    componentByType = {}
    nameByType = {}
    address_components = []
    formatted_address
    geometry
    place_id
    types

    constructor(results) {
        this.results = results
        this.result = results[ 0 ] // TODO: Filter for routes or establishments e.g
        this.address_components = this.result.address_components
        this.componentByType = {}
        this.nameByType = {}
        this.address_components.forEach(component=>{
            component.types.forEach(type=>{
                this.componentByType[ type ] = component
                this.nameByType[ type ] = component.long_name
            })
        })
        this.formatted_address = this.result.formatted_address
        this.geometry = this.result.geometry
        this.place_id = this.result.place_id
        this.types = this.result.types
    }
}

export default new Store()
