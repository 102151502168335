import * as React from "react"
import {SidebarContext} from "component/Vehicle/VehicleSidebar"
import {PageHeaderSimple} from "component/PageHeader"
import {Button, Form, Modal} from "semantic-ui-react"
import {Link} from "react-router-dom"
import moment from "moment"
import store from "store/store"
import {faInfoCircle} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import Icon from "component/Icon"
import {toast} from "react-toastify"
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default class OpenNewDrivelog extends React.Component<any, any> {

	state = {
		mileage     : "",
		notification: true,
		email       : store.account.user.userProfile.email
	}

	render() {
		const {mileage} = this.state
		const {vehicle} = this.props
		return <div className={"w-100"}>
			<SidebarContext forceHide optional />
			<PageHeaderSimple title={"Fahrtenbuch"} />

			{!vehicle.featureByKey["FEATURE_DRIVEBOOK"] && <div>
				<Modal open size={"small"}>
					<Modal.Header>Tracker kaufen</Modal.Header>
					<Modal.Content>
						<div className="flex flex-row items-center">
							<div className={"pa3 animate fadeInDown"}>
								<img width={376 / 2} src="https://www.flottr.com/files/flottr/img/Flottr-GPS-Tracker.png" alt=""/>
							</div>
							<div className={" b pl3-ns "}>
								<div className={"f3"}>Nutzen Sie das FLOTTR Fahrtenbuch</div>
								<div>Für diese Funktion benötigen Sie den flottr Easy Drive GPS Tracker.</div>
							</div>
						</div>
					</Modal.Content>
					<Modal.Actions>
						<div className="flex flex-row">
							<Button basic as={Link} to={`/map/${vehicle._id}/`}>Zurück</Button>
							<div className={"ml-auto"}>
								<Button
									onClick={() => {
										$zoho.salesiq.chat.start()
									}
									}
									basic as={Link}  to={`/map/${vehicle._id}/`}><Icon icon={faChat}/> Service-Chat</Button>
							</div>
							<Button primary as={Link} to={`/buy?vehicleId=${vehicle._id}`}>Tracker kaufen</Button>
						</div>

					</Modal.Actions>
				</Modal>
			</div>}


			<Modal open={vehicle.featureByKey["FEATURE_DRIVEBOOK"]} size="small">
				<Modal.Header>
					<div className="ph4-ns pv4-ns">
						<div className="flex flex-row items-center justify-between">
							<div>
								<div className={"f2 b"}>Fahrtenbuch eröffnen</div>
								<div className="pt2 f4 black-70">
									{vehicle.title}{" "}{vehicle.vehiclePlate}
								</div>
							</div>
							<Button basic as={Link} to={`/map/${vehicle._id}/`} circular icon={<Icon icon={faTimes}/>} />
						</div>
					</div>
				</Modal.Header>

				<Modal.Content>
					<div className="ph4-ns pb2-ns">
						<Form
							onSubmit={() => {
								if ( !mileage ) {
									toast.warn( "Kilometerstand ist nicht ausgefüllt." )
									return
								}
								// console.log.log( "SUBMIT TO VEHICLE API" )
								vehicle.put( {mileage, drivebookEmail: this.state.email, drivebookNotificationMode: !!this.state.email} )
							}
							}
						>

							<h4>Kilometerstand</h4>
							<Form.Field>
								<label>Kilometerstand (km) {moment().format( "HH:mm [Uhr] DD.MM.YYYY" )}</label>
								<input placeholder="30000" min={1} type={"number"} value={mileage} onChange={e => {
									this.setState( {mileage: e.currentTarget.value} )
								}} />
							</Form.Field>

							<h4>Benachrichtigungen</h4>
							<Form.Input fluid label="E-Mail" type={"email"} value={this.state.email} placeholder="kim.muster@example.com" onChange={e => {
								this.setState( {email: e.currentTarget.value} )
							}} />
							<div className="f6 black-70"><Icon icon={faInfoCircle} /> Tägliche Benachrichtigung bei neuen Fahrten. Warnungen nach Ablauf von 7 Tagen bei ungeprüften Fahrten.</div>

							{/* TODO: Notification settings */}
							{/*<div className="pb3">*/}

							{/*	<div className="flex flex-row items-center pv2">*/}
							{/*		<Checkbox toggle*/}
							{/*				  checked={this.state.notification}*/}
							{/*				  onChange={e => {*/}
							{/*			this.setState( {notification: !this.state.notification} )*/}
							{/*		}} />*/}
							{/*		<div className="pl3">Tägliche Updates</div>*/}
							{/*	</div>*/}

							{/*</div>*/}

							{/*<Form.Field>*/}
							{/*	<Checkbox label="Ich habe die AGB gelesen und akzeptiert" />*/}
							{/*</Form.Field>*/}

							<div className="flex flex-row justify-end items-center pt3">
								<Button size="large" primary type="submit">Fahrtenbuch eröffnen</Button>
							</div>
						</Form>
					</div>
				</Modal.Content>
			</Modal>


		</div>
	}
}


