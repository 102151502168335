import {withRouter} from "react-router"
import {observer} from "mobx-react"
import * as React from "react"
import parseQuery from "util/parseQuery"
import store from "store/store"
import {SearchInput} from "component/Search/SearchAll"
import {Link} from "react-router-dom"
import {Button} from "semantic-ui-react"
import {faTimes} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import Icon from "component/Icon"

// @ts-ignore
@withRouter
@observer
class GroupSidebar extends React.Component<any, any> {

	render() {
		const query = parseQuery( this.props )
		const {vehicleId} = query

		let {vehicleFilter} = store.account.vehicle

		let groupKeys = vehicleFilter ? [...store.account.vehicle.vehiclesFilteredByGroup.keys()].sort() : [...store.account.vehicle.byGroup.keys()].sort()
		let byGroup = vehicleFilter ? store.account.vehicle.vehiclesFilteredByGroup : store.account.vehicle.byGroup

		let parts = this.props.location.pathname.split( "/" )
		let isMapLink = parts[0] === "map"

		let makeReplacementLink = value => {
			if ( this.props.location.pathname.includes( value ) ) {
				if ( isMapLink ) return this.props.location.pathname.replace( vehicleId, value )
				return `/map/${value}`
			}
			return `/map/${value}`
		}

		const onClick = this.props.onClick || null
		return <div className="GroupSidebar bg-dark-gray h-100 w-100 overflow-y-auto flex-shrink-0">
			<div className="pa3 pv3">
				<div className="pb4"><SearchInput /></div>

				{groupKeys.length <= 0 && <div>
					<div className="pa3 white tc">
						Keine Fahrzeuge
						{vehicleFilter && <div className={"pt3"}>
							<Button onClick={() => {
								store.account.vehicle.setVehiclesFiltered( false, [] )
							}} inverted size={"small"}><Icon icon={faTimes} /> Filter </Button>

							<p className={"f6 white-50 pt3 lh-copy"}>Suchen sie nach Fahrzeugname, Kennzeichen, Gruppe oder Fahrernamen</p>
						</div>}
					</div>
				</div>}

				{groupKeys.map( key => {
					let group = byGroup.get( key )
					return <div key={key} className="pb4-ns pb3">
						<div className="pb2 white-30 f6 ttu">{key}</div>
						{group && group.map( vehicle => {
							const displayName = vehicle.vehiclePlate ? `${vehicle.vehiclePlate} ${vehicle.title}` : `${vehicle.deviceId}`
							return <Link onClick={onClick}
										 to={{pathname: makeReplacementLink( vehicle._id ), search: `?vehicleId=${vehicle._id}`}}
										 key={vehicle._id}
										 className={`white-60 f5 db b  pv1 mb1 ${vehicleId === vehicle._id ? "active yellow" : null}`}>{displayName}</Link>
						} )
						}
					</div>
				} )}
			</div>
		</div>
	}
}

export default GroupSidebar