import * as React from "react"
import {observer} from "mobx-react"
import store from "store/store"
import {Link, withRouter} from "react-router-dom"
import {Input, Search} from "semantic-ui-react";
import _ from "lodash"
import {faSearch, faTimes, faUser} from "assets/fortawesome-pro-light-svg-icons-5.14.0/package"
import Icon from "component/Icon"

import "./SearchAll.css"
//@ts-ignore
@withRouter @observer class SearchAll extends React.Component<any, any> {

	state = {
		value    : "",
		isLoading: false,
		results  : []
	}

	componentWillMount() {
		this.resetComponent()
	}

	resetComponent = () => this.setState( {isLoading: false, results: [], value: ''} )

	handleResultSelect = ( e, {result} ) => {
		// console.log.log( "RESULT", {result} )
		this.props.history.push( {pathname: "/map", search: `?vehicleId=${result.vehicle._id}`} )
		this.setState( {value: ""} )
	}

	handleSearchChange = ( e, {value} ) => {
		this.setState( {isLoading: true, value} )
		const {searchOptions} = store.account.vehicle

		setTimeout( () => {
			if ( value.length < 1 ) return this.resetComponent()
			const re = new RegExp( _.escapeRegExp( value ), 'i' )
			const isMatch = result => re.test( result.searchString )

			this.setState( {
				isLoading: false,
				results  : _.filter( searchOptions, isMatch ),
			} )
		}, 0 )
	}

	render() {
		const {isLoading, value, results} = this.state
		const {staticContext, ...rest} = this.props
		return (
			<Search
				className={"SearchAll"}
				style={{maxWidth: "100%"}}
				fluid
				tabIndex={0}
				loading={isLoading}
				placeholder={"Kennzeichen, Fahrzeug"}
				noResultsMessage={"Kein Fahrzeug gefunden."}
				onResultSelect={this.handleResultSelect}
				onSearchChange={_.debounce( this.handleSearchChange, 500, {leading: true} )}
				resultRenderer={( entry ) => {
					return <Link tabIndex={0} key={entry.vehicle._id}
								 to={{pathname: "/map", search: `?vehicleId=${entry.vehicle._id}`}}>
						<div className={"black b f6"}>{entry.vehicle.vehiclePlate}</div>
						<div className="f6 black-70">{entry.vehicle.vehicleGroup} </div>
						{entry.vehicle.driverName && <div className="f6 black-70"><Icon icon={faUser} /> {entry.vehicle.driverName} </div>}
					</Link>
				}}
				results={results}
				value={value}
			/>
		)
	}
}

export default SearchAll

//@ts-ignore
@withRouter @observer class SearchInput extends React.Component<any, any> {

	state = {
		value    : "",
		isLoading: false,
		results  : []
	}

	componentWillMount() {
		this.resetComponent()
	}

	resetComponent = () => {
		store.account.vehicle.setVehiclesFiltered( false, [] )
		this.setState( {isLoading: false, results: [], value: ''} )
	}

	handleSearchChange = ( e, {value} ) => {
		this.setState( {isLoading: true, value} )
		const {searchOptions} = store.account.vehicle

		if ( value.length < 1 ) return this.resetComponent()
		const re = new RegExp( _.escapeRegExp( value ), 'i' )
		const isMatch = result => re.test( result.searchString )

		const results = _.filter( searchOptions, isMatch )
		store.account.vehicle.setVehiclesFiltered( value, results.map( r => r.vehicle ) )

		this.setState( {
			isLoading: false,
			results,
		} )
	}

	render() {
		const {isLoading} = this.state
		const {staticContext, ...rest} = this.props
		const {vehicleFilter} = store.account.vehicle
		return (
			<Input
				{...rest}
				inverted
				icon={vehicleFilter ?
					<i onClick={() => {
						store.account.vehicle.setVehiclesFiltered( false, [] )
					}
					} className={"icon"}><Icon className={"white"} icon={faTimes} /></i> :
					<i className={"icon"}><Icon className={"white"} icon={faSearch} /></i>
				}
				className={"SearchAll"}
				style={{maxWidth: "100%"}}
				fluid
				tabIndex={0}
				loading={isLoading}
				placeholder={"Kennzeichen, Fahrzeug"}
				onChange={_.debounce( this.handleSearchChange, 500, {leading: true} )}
				value={vehicleFilter ? vehicleFilter : ""}
			/>
		)
	}
}

export {SearchInput}