import {action, observable} from "mobx";


export class Product {
    price = 12
    count = 0

    constructor({price, count = 0}) {
        this.price = price
        this.count = count
    }
}

export default class CheckoutStore {

    @observable products = {
        easyDrive: new Product({price: 12, count: 1}),
        book: new Product({price: 3}),
        map: new Product({price: 3}),
        singleBook: new Product({price: 17.9})
    }

    @observable count = 1
    @observable total = 15
    @observable price = 15
    @observable pricePerYear = 15
    @observable tax = 15
    @observable taxPerYear = 15

    @observable fixPricePerUnit = 150
    @observable setupPrice = 0

    @observable email = ""
    @observable subTotal
    @observable sumTax
    @observable sumTaxPerYear
    @observable sumPerYear

    @observable billingAddress = {}
    @observable shippingAddress = {}

    constructor() {
        this.calculate()
        window["checkoutStore"] = this
    }

    @action
    setEmail = (email) => {
        this.email = email
    }

    @action
    calculate() {
        const TAX_RATE = 0.19
        this.tax = this.price * TAX_RATE

        this.setupPrice = this.fixPricePerUnit * this.products.easyDrive.count
        this.subTotal = this.pricePerYear + this.setupPrice
        this.pricePerYear = 12 * (this.products.easyDrive.count * this.products.easyDrive.price
            + this.products.map.count * this.products.map.price
            + this.products.singleBook.count * this.products.singleBook.price
            + this.products.book.count * this.products.book.price)

        this.sumPerYear = this.pricePerYear
        this.sumTaxPerYear = this.taxPerYear
        this.sumTax = this.subTotal * TAX_RATE

        this.total = (this.sumPerYear + this.setupPrice) + this.sumTax
    }

}



