import http from "../../service/http";

export class Account {
    id: string
    carId: string
    carName: string
    contactId: string
    driver: string
    mileage: number
    isBusiness: boolean
    accountURL: string
    active: boolean
    contactName: string
    deviceId: string
    companyId: string
    lat: number
    lng: number
    speed: number
    time: string

    constructor(data) {
        Object.assign(this, data)
        if (this.accountURL) {
            this.isBusiness = true
            this.id = `${this.accountURL}-${this.contactId}`
        }
    }


    toJSON() {
        let {carId, carName, contactId, driver, mileage} = this
        return {carId, carName, contactId, driver, mileage}
    }

    post() {
        return http.post("api/account/", this.toJSON())
    }

    put() {
        return http.put(`api/account/${this.id}`, this.toJSON())
    }
}